import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { shade100, shade20 } from "@themes/colors";
import { wrapperClass } from "./control-button.styles";
import { ControlButtonProps } from "./control-button.types";

export const ControlButton = ({ iconName, disabled = false, onClick }: ControlButtonProps) => {
	const iconColor = disabled ? shade20 : shade100;
	const onClickHandler = !disabled ? onClick : undefined;

	return (
		<button type="button" className={wrapperClass} disabled={disabled} onClick={onClickHandler}>
			<Icon icon={iconName} color={iconColor} sizeRem={0.7} />
		</button>
	);
};
