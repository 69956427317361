import { css, cva } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	justifyContent: "center",
	alignItems: "center",
	gap: "24",
	overflow: "hidden",
});

export const iconWrapperClass = cva({
	base: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minWidth: "[6rem]",
		minHeight: "[6rem]",
		borderRadius: "[3rem]",
	},
	variants: {
		iconBackgroundColor: {
			error20: {
				backgroundColor: "error.20",
			},
			error0: {
				backgroundColor: "error.0",
			},
			success20: {
				backgroundColor: "success.20",
			},
			success0: {
				backgroundColor: "success.0",
			},
			warning20: {
				backgroundColor: "warning.20",
			},
			warning0: {
				backgroundColor: "warning.0",
			},
			onPrimary: {
				backgroundColor: "onPrimary",
			},
			shade5: {
				backgroundColor: "shade.5",
			},
		},
	},
	defaultVariants: {
		iconBackgroundColor: "shade5",
	},
});

export const textWrapperClass = stack({
	textAlign: "center",
	gap: "8",
	maxWidth: "[20rem]",
	whiteSpace: "pre-wrap",
});

export const iconBottomNodeWrapperClass = css({
	position: "absolute",
	bottom: "0",
	transform: "translateY(50%)",
});
