import { useEffect, useRef, useState } from "react";
import { HubspotGlobalStyle } from "@components/hubspot-style/hubspot-style.styles";
import { SideMenu } from "@containers/side-menu";
import { SideMenuContent } from "@containers/side-menu-content";
import { SideMenuLayoutContext } from "./sidemenu-layout.context";
import {
	bottomContentWrapperClass,
	childrenWrapperClass,
	sideMenuClass,
	sideMenuContentClass,
	wrapperClass,
} from "./sidemenu-layout.styles";
import { SidemenuLayoutProps } from "./sidemenu-layout.types";

export const SidemenuLayout = ({
	noPadding = false,
	noSideMenuPadding = false,
	noContentPadding = false,
	contentContainerId,
	sideMenu,
	bottomContent,
	keepSidemenuContentOnMobile = false,
	children,
}: SidemenuLayoutProps) => {
	const bottomContentRef = useRef<HTMLDivElement>(null);
	const [bottomPadding, setBottomPadding] = useState<number | undefined>(undefined);
	const hasBottomContent = !!bottomContent;

	useEffect(() => {
		if (!hasBottomContent || !bottomContentRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setBottomPadding((padding) => {
				const clientHeight = bottomContentRef.current?.clientHeight;
				if (clientHeight !== padding) {
					if (clientHeight === 0) {
						const firstChild = bottomContentRef.current?.firstElementChild;
						if (firstChild) {
							return firstChild.clientHeight;
						}
					}

					return clientHeight;
				}
				return padding;
			});
		});
		resizeObserver.observe(bottomContentRef.current);
		return () => resizeObserver.disconnect();
	}, [hasBottomContent]);

	const hubspotBottomMargin = (bottomPadding ?? 0) / 16;

	return (
		<SideMenuLayoutContext.Provider value={{ bottomContentHeightPx: bottomPadding ?? 0 }}>
			<div className={wrapperClass}>
				<SideMenu
					className={sideMenuClass({
						noPadding: noPadding || noContentPadding,
					})}
				>
					{sideMenu && (
						<SideMenuContent
							className={sideMenuContentClass({
								noPadding: noPadding || noSideMenuPadding,
								hasBottomContent,
							})}
							showInSideMenu
							sideMenuOnly={!keepSidemenuContentOnMobile}
						>
							{sideMenu}
						</SideMenuContent>
					)}
					<SideMenuContent
						id={contentContainerId}
						className={childrenWrapperClass({
							noPadding: noPadding || noContentPadding,
							hasBottomContent,
						})}
					>
						{children}
					</SideMenuContent>
					{bottomContent && (
						<div ref={bottomContentRef} className={bottomContentWrapperClass}>
							{bottomContent}
						</div>
					)}
					{bottomContent && <HubspotGlobalStyle bottomMarginRem={hubspotBottomMargin} />}
				</SideMenu>
			</div>
		</SideMenuLayoutContext.Provider>
	);
};
