import { graphql } from "react-relay";

export const QUERY = graphql`
	query shoppingCartProducts_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartProducts_OrderFragment
			}
		}
		Viewer {
			Auth {
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartProducts_OrderFragment on Order {
		id
		cart {
			selection {
				selectedProducts {
					product {
						id
						title
						isTaxFree
					}
					amount
				}
			}
			items {
				kind
				... on LimitedDiscountItem {
					title
					value
				}
				... on DiscountItem {
					validUntil
					code
					title
					value
				}
				... on GlobalDiscountItem {
					title
					value
				}
				... on ProductItem {
					amount
					product {
						id
						title
						netPrice
						isTaxFree
						data {
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			totals {
				withoutDiscounts {
					netPrice
					grossPrice
					taxRatePercentage
				}
				appliedDiscount {
					grossPrice
				}
				includingAllDiscounts {
					grossPrice
					netPrice
				}
				appliedDiscountPercentage
				monthlyOptions {
					monthlyRate {
						grossPrice
					}
				}
			}
			otherAvailableProducts {
				edges {
					node {
						id
						title
						data {
							kind
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		...shoppingCartTemplate_OrderFragment
	}
`;

export const UPDATE_CART_MUTATION = graphql`
	mutation shoppingCartProducts_UpdateCartMutation($input: UpdateCartInput!) {
		Billing {
			updateCart(input: $input) {
				order {
					cart {
						selection {
							selectedProducts {
								product {
									id
								}
								amount
							}
						}
						items {
							kind
							... on ProductItem {
								amount
								product {
									id
									title
								}
							}
							... on DiscountItem {
								validUntil
								code
							}
						}
						totals {
							includingAllDiscounts {
								grossPrice
								netPrice
							}
						}
					}
					...shoppingCartProducts_OrderFragment
				}
			}
		}
	}
`;
