import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment rootProgress_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		image {
			url
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				progressPercentage
				viewerTreeState {
					kind
				}
				extension {
					... on PublishedRootExtensionImpl {
						receivedGamificationPoints
						reducedData {
							receivableGamificationPoints
						}
						licenseAvailability {
							kind
							... on LicenseAvailabilityFreeLicensesAvailable {
								numAvailable
							}
						}
						unlockInfo {
							kind
						}
						product {
							netPrice
							isTaxFree
						}
					}
				}
			}
		}
	}
`;
