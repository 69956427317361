import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { Path } from "@router/paths";
import { error100 } from "@themes/colors";
import { Wrapper } from "./error-fallback.styles";

export const ErrorFallbackComponent = () => {
	const handleOnClick = () => {
		window.location.reload();
	};
	const handleSecondaryOnClick = () => {
		window.location.href = Path.dashboard.path;
	};
	return (
		<Wrapper>
			<IconHeadlineButtonTemplate
				iconName="warning"
				iconColor={error100}
				iconBackgroundColor="error20"
				title="Ups, da ist etwas schief gelaufen"
				subtitle="Bitte versuche es später noch einmal."
				buttonLabel="Seite neu laden"
				onClick={handleOnClick}
				secondaryButtonLabel={"Zum Dashboard"}
				secondaryButtonColorVersion={"tertiary"}
				secondaryOnClick={handleSecondaryOnClick}
			/>
		</Wrapper>
	);
};
