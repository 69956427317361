import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const listWrapperClass = flex({
	direction: "column",
	gap: "12",
});

export const linkClass = css({
	padding: "16",
	border: "1px solid",
	borderColor: "shade.10",
	borderRadius: "24",

	"&[aria-current='page']": {
		color: "primary.80",
		borderColor: "shade.0",
		backgroundColor: "shade.0",
		boxShadow: "card",

		"& + div": {
			display: "block",
		},
	},
});

export const linkWrapperClass = flex({
	direction: "column",
});

export const linkContentClass = css({
	display: "none",
	width: "full",
});
