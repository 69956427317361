import { isValid } from "iban";
import * as Yup from "yup";

export const directDebitFormSchema = Yup.object().shape({
	iban: Yup.string()
		.test("test-card-number", "Bitte gib eine gültige IBAN ein.", (value) =>
			isValid(value ?? ""),
		)
		.required(),
});
