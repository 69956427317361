import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const screenWrapperClass = css({
	height: "full",
	bg: "shade.5",
	overflowY: "hidden",
});

export const scrollWrapperClass = flex({
	direction: "column",
	height: "full",
	overflowY: "auto",
	xl: {
		overflowY: "hidden",
	},
});
