import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment notesOverview_QueryFragment on Query
	@argumentDefinitions(
		includeNoteSourceKinds: { type: "[NoteSourceKind!]", defaultValue: null }
		includeNoteColors: { type: "[NoteColor!]", defaultValue: null }
		noteColorToSortBy: { type: "NoteColor" }
		filterByText: { type: "String" }
	)
	@refetchable(queryName: "notesOverview_RefetchQuery") {
		Note {
			Note(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
			) {
				edges {
					node {
						id
						reminder {
							__typename
						}
						...noteCard_NoteFragment
					}
				}
			}
		}
	}
`;
