import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { InputTextType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { colorSecondary120Class } from "@themes/color-classes";
import { H1Span, P1Span, P2Span, P3Span } from "@themes/font-tags";
import {
	FormWrapper,
	InputsWrapper,
	InvitationTitleWrapper,
	TextCenter,
} from "./sign-up-password-email-step.styles";
import {
	type SignUpPasswordEmailStepFormState,
	type SignUpPasswordEmailStepProps,
} from "./sign-up-password-email-step.types";

export const SignUpPasswordEmailStep = ({
	currentStep,
	maxSteps,
	headerText,
	onGoToLogin,
	onNext,
}: SignUpPasswordEmailStepProps) => {
	const { t } = useTkaTranslation("registerForm");

	const form = useFormik<SignUpPasswordEmailStepFormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("register_form.section1ValidationInvalidEmail"))
				.required(t("register_form.section1ValidationRequiredEmail")),
			password: Yup.string()
				.min(8, t("register_form.section1ValidationInvalidPassword"))
				.required(t("register_form.section1ValidationRequiredPassword")),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	const showStepInfo = maxSteps !== undefined && currentStep !== undefined;

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				{showStepInfo && (
					<TextCenter>
						<P1Span>
							{currentStep}/{maxSteps}
						</P1Span>
					</TextCenter>
				)}
				<TextCenter>
					<InvitationTitleWrapper>
						{headerText && <P2Span>{headerText}</P2Span>}
						<H1Span>{t("register_form.section1Title")}</H1Span>
					</InvitationTitleWrapper>
					<H1Span>{t("register_form.section1Title2")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="email"
						icon="email"
						label={t("register_form.section1PlaceholderEmail")}
						placeholder={t("register_form.section1PlaceholderEmail")}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="password"
						type={InputTextType.Password}
						icon="lock"
						label={t("register_form.section1PlaceholderPassword")}
						placeholder={t("register_form.section1PlaceholderPassword")}
						bottomLabel={t("register_form.section1HelperTextPassword")}
					/>
				</InputsWrapper>
				<Button
					fillParent
					label={t("register_form.section1ButtonText")}
					iconName="arrowRight"
					colorVersion={"default"}
				/>
				<TextCenter>
					<P3Span>
						{t("register_form.section1AlreadyRegistered")}
						&nbsp;
						<P3Span className={colorSecondary120Class} onClick={onGoToLogin}>
							{t("register_form.section1ToLogin")}
						</P3Span>
					</P3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
