import { AuthTemplate } from "@components/auth-template";
import { LoginMarketing } from "./parts/login-marketing";
import { LoginSteps } from "./parts/login-steps";

export const LoginScreen = () => {
	return (
		<AuthTemplate rightSide={<LoginMarketing />}>
			<LoginSteps />
		</AuthTemplate>
	);
};
