import "react-circular-progressbar/dist/styles.css";
import {
	buildStyles,
	CircularProgressbar as ThirdPartyCircularProgressbar,
} from "react-circular-progressbar";
import { type CircularProgressbarProps } from "./circular-progressbar.types";

export const CircularProgressbar = ({
	strokeWidth,
	trailColor,
	pathColor,
	width,
	height,
	...props
}: CircularProgressbarProps) => {
	width = width ?? "2rem";
	height = height ?? "2rem";
	strokeWidth = strokeWidth ?? 10;
	trailColor = trailColor ?? "rgb(166, 174, 185, 0.4)";
	pathColor = pathColor ?? "rgb(39, 229, 155)";

	const customStyles = buildStyles({
		trailColor: trailColor,
		pathColor: pathColor,
	});

	return (
		<ThirdPartyCircularProgressbar
			strokeWidth={strokeWidth}
			styles={{
				...customStyles,
				root: {
					...customStyles.root,
					height,
					width,
				},
				path: {
					...customStyles.path,
				},
				trail: {
					...customStyles.trail,
				},
			}}
			{...props}
		/>
	);
};
