import { css, cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const screenWrapperClass = flex({
	direction: "column",
	height: "full",
	gap: "32",
	p: "24",
	overflowY: "auto",
	md: {
		p: "40",
	},
});

export const headerWrapperClass = flex({
	direction: "column",
	gap: "12",
});

export const clientTypeWrapperClass = flex({
	flexDirection: "row",
	gap: "32",
	alignItems: "center",
});

export const dividerClass = css({
	minHeight: "[1px]",
	alignSelf: "stretch",
	bg: "shade.10",
});

export const hideablePrivateWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		gap: "32",
	},
	variants: {
		hidden: {
			true: {
				display: "none",
			},
		},
	},
});

export const hideableWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		gap: "16",
	},
	variants: {
		hidden: {
			true: {
				display: "none",
			},
		},
	},
});

export const centerClass = flex({
	flexDirection: "column",
	flex: "1",
	align: "center",
});

export const minWidthClass = flex({
	flexDirection: "column",
	minWidth: "[min(20rem,100%)]",
	md: {
		minWidth: "20",
	},
});
