import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment businessClientForm_BaseDataFragment on baseData {
		street
		houseNumber
		postalCode
		city
		countryCode
		__typename
		... on BusinessBaseData {
			companyName
			billingOffice
			companyLegalForm
			invoiceEmail
			phoneNumber
			accountData {
				bic
				iban
			}
			taxData {
				taxIdentificationNumber
				taxNumber
			}
		}
	}
`;
