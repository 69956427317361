import styled from "styled-components";

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.spacing32};
`;

export const TextCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 0;
`;
export const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.spacing12};
`;

export const SwitchTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing.spacing12};
`;

export const SwitchWrapper = styled.div`
	flex: 1 1 0%;
	flex-grow: 1;
`;

export const ActionButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing.spacing16};
`;
