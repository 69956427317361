import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { useContext } from "react";
import { useMutation } from "react-relay";
import { removeMemberModal_RemoveUsersFromAccountInputMutation } from "@relay/removeMemberModal_RemoveUsersFromAccountInputMutation.graphql";
import { AccountContext } from "@screens/account";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { colorWarning100Class } from "@themes/color-classes";
import { H1Span, P2Span } from "@themes/font-tags";
import { REMOVE_USERS_FROM_ACCOUNT_MUTATION } from "./remove-member-modal.graphql";
import { wrapperClass } from "./remove-member-modal.styles";
import { RemoveMemberModalProps } from "./remove-member-modal.types";

// TODO: add-translations
export const RemoveMemberModal = ({
	isVisible,
	selectedMembers,
	onDismiss,
	onSuccess,
	triedToRemoveThemselves,
}: RemoveMemberModalProps) => {
	const [removeUsersFromAccount] =
		useMutation<removeMemberModal_RemoveUsersFromAccountInputMutation>(
			REMOVE_USERS_FROM_ACCOUNT_MUTATION,
		);

	const { membersConnectionIds } = useContext(AccountContext);
	const { refetch } = useContext(RefetchContext);

	if (!selectedMembers || selectedMembers.length === 0) return null;

	const handleRemoveUsersOnClick = () => {
		if (selectedMembers.length > 0) {
			removeUsersFromAccount({
				variables: {
					input: {
						userIds: selectedMembers.map((selectedMembers) => selectedMembers.id),
					},
					connections: membersConnectionIds,
				},
				onCompleted: () => {
					refetch();
					onSuccess?.();
				},
			});
		}
	};

	const title =
		selectedMembers.length > 1
			? `${selectedMembers.length} Mitglieder aus dem Konto entfernen?`
			: `Mitglied “${selectedMembers[0].name}” aus dem Konto entfernen?`;
	const subtitle =
		selectedMembers.length > 1
			? `Willst du wirklich ${selectedMembers.length} Mitglieder entfernen? Die Mitglieder können dann nicht mehr auf Lerninhalte und Lizenzen des Kontos zugreifen.`
			: `Willst du “${selectedMembers[0].name}” wirklich entfernen? Das Mitglied kann dann nicht mehr auf Lerninhalte und Lizenzen des Kontos zugreifen.`;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel={
					selectedMembers.length > 1 ? "Mitglieder entfernen" : "Mitglied entfernen"
				}
				primaryButtonIconName="trash"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handleRemoveUsersOnClick}
			>
				<div className={wrapperClass}>
					<H1Span>{title}</H1Span>
					<P2Span>{subtitle}</P2Span>
					{triedToRemoveThemselves && (
						<P2Span className={colorWarning100Class}>
							Du versuchts dich selbst aus dem Konto zu entfernen. Dies ist nicht
							möglich. Deshalb haben wir dich aus der Liste entfernt.
						</P2Span>
					)}
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
