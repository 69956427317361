import { graphql } from "react-relay";

export const ACCOUNT_MEMBERS_QUERY_FRAGMENT = graphql`
	fragment membersTab_AccountMembersQueryFragment on Query
	@refetchable(queryName: "membersTabAccountMembersRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 100 }
		after: { type: "String", defaultValue: null }
		filterByGroupIds: { type: "[ID!]", defaultValue: [] }
		filterByNameOrEmail: { type: "String", defaultValue: null }
		filterByAccountMemberKind: { type: "AccountMemberKind" }
	) {
		AccountMemberManagement {
			AccountMembers(
				filterByGroupIds: $filterByGroupIds
				filterByNameOrEmail: $filterByNameOrEmail
				filterByAccountMemberKind: $filterByAccountMemberKind
			) {
				__id
				userCount
				invitationCount
				accountMembers(first: $first, after: $after)
					@connection(key: "membersTab_accountMembers") {
					__id
					edges {
						node {
							...membersTab_AccountMemberFragment
						}
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;

export const USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT = graphql`
	fragment membersTab_UserInAccountGroupQueryFragment on Query
	@refetchable(queryName: "membersTab_UserInAccountGroupRefetchQuery") {
		...membersFilter_QueryFragment
		...inviteMemberModal_QueryFragment
	}
`;

export const ACCOUNT_MEMBER_FRAGMENT = graphql`
	fragment membersTab_AccountMemberFragment on AccountMember @inline {
		id
		kind
		... on UserInAccountWrapper {
			user {
				user {
					id
					...userContextMenu_UserFragment
				}
			}
		}
		... on InvitationWrapper {
			__id
			invitation {
				id
				email
			}
		}
		...userCard_UserInAccountWrapperFragment
		...invitationCard_InvitationFragment
	}
`;
