import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "4",
	width: "full",
});

export const boxClass = css({
	position: "relative",
	width: "full",
	padding: "24",
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	boxShadow: "card",
	overflow: "hidden",
});

export const boxContentClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
});

export const expandButtonWrapperClass = css({
	position: "absolute",
	right: "0",
	bottom: "0",
	left: "0",
	paddingY: "12",
	display: "flex",
	borderBottomRadius: "12",
	alignItems: "center",
	justifyContent: "center",
	background:
		"[linear-gradient(178.62deg, rgba(255, 255, 255, 0) 13.39%, rgba(255, 255, 255, 0.52) 50.27%, #FFFFFF 97.53%)]",
	backdropFilter: "auto",
	backdropBlur: "4",
});
