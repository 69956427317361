import { css } from "@styled-system/css";

export const inlineWrapperClass = css({
	flex: "[1 1 0%]",
	minWidth: "[min(20rem, 100vw)]",
	paddingInline: "24",
	md: {
		boxSizing: "content-box",
	},
});
