import { gtmTrackViewCourseDetail } from "@thekeytechnology/academies-lib-webapp/analytics";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { useCurrentPath } from "@hooks/use-current-path";
import { root_AddItemToUserCartMutation } from "@relay/root_AddItemToUserCartMutation.graphql";
import { root_BookTreeMutation } from "@relay/root_BookTreeMutation.graphql";
import { root_GetRootQuery } from "@relay/root_GetRootQuery.graphql";
import { BranchNodesPath, Path } from "@router/index";
import { colorShade80Class, colorWarning100Class } from "@themes/color-classes";
import { B1Span, H1Span } from "@themes/font-tags";
import { BranchNodeTab } from "./branch-nodes-tab";
import { RootContainerId } from "./root-overview/root-overview.const";
import { RootScreenContext } from "./root.context";
import { BOOK_TREE_MUTATION, ADD_ITEM_TO_USER_CART_MUTATION, QUERY } from "./root.graphql";
import { RootScreenSkeleton } from "./root.skeleton";
import {
	licenseWrapperClass,
	needsLicenseWrapperClass,
	paddingWrapperClass,
	receiverGamificationPointsWrapperClass,
	sideMenuWrapperClass,
	subpathBackgroundClass,
	wrapperClass,
} from "./root.styles";
import { RootLocationState, RootScreenProps } from "./root.types";
import { getTabIndexForPath, useRootNavigation } from "./root.util";
import { ReactComponent as KeyMedalSvg } from "../../assets/key-medal-points.svg";
import { CertificateCard } from "../../components/certificate-card";
import { RootProgress } from "../../components/root-progress";
import { RouterContentMenu } from "../../components/router-content-menu";
import { ScreenWithNavbarSidemenuLayout } from "../../layouts/screen-with-navbar-sidemenu-layout";

export const RootScreenWithRootId = ({ rootId }: RootScreenProps) => {
	const { t } = useTkaTranslation("courseScreen");

	const navigate = useNavigate();
	const { state }: RootLocationState = useLocation();
	const currentPath = useCurrentPath();
	const { navigateToTab } = useRootNavigation();
	const { isXLargeUp } = useWindowSize();

	const [activeIndex, setActiveIndex] = useState(getTabIndexForPath(currentPath?.route.path));

	const [bookTree] = useMutation<root_BookTreeMutation>(BOOK_TREE_MUTATION);

	const query = useLazyLoadQuery<root_GetRootQuery>(
		QUERY,
		{ id: rootId },
		{ fetchPolicy: "store-and-network" },
	);
	const [addItemToUserCart] = useMutation<root_AddItemToUserCartMutation>(
		ADD_ITEM_TO_USER_CART_MUTATION,
	);

	const isRootPath = currentPath?.route.path === Path.root.withIdPlaceholder().path;
	const isRootOverviewPath = currentPath?.route.path === "overview";

	useEffect(() => {
		if (isRootPath) {
			if (
				query?.node?.root?.structureDefinition?.extension?.product &&
				query?.node?.root?.structureDefinition.extension?.unlockInfo?.kind !== "FullAccess"
			) {
				const product = query?.node?.root?.structureDefinition?.extension?.product;
				gtmTrackViewCourseDetail(product.id, product?.title, state?.productIds ?? []);
			}
			navigate(Path.root.withId(rootId).overview.path, { replace: true });
		}
	}, [isRootPath]);

	const isContentBasePath = currentPath?.route.path === BranchNodesPath.pathName;

	useEffect(() => {
		if (isContentBasePath) {
			const nextContentId =
				query.node?.root?.structureDefinition.viewerTreeState?.headContentId ??
				query.node?.root?.typeDefinition?.children?.[0]?.typeDefinition.children?.[0]?.id;
			nextContentId &&
				navigate(Path.root.withId(rootId).branchNodes.withId(nextContentId).path, {
					replace: true,
				});
		}
	}, [isContentBasePath]);

	useEffect(() => {
		const pathIndex = getTabIndexForPath(currentPath?.route.path);
		if (pathIndex !== activeIndex) {
			setActiveIndex(pathIndex);
		}
	}, [currentPath?.route.path, activeIndex]);

	const handleOnTabChange = useCallback(
		(params: TabViewTabChangeEvent) => {
			navigateToTab(params.index);
		},
		[navigateToTab],
	);

	const handleOnCartClickout = useCallback(() => {
		const productId = query.node?.root?.structureDefinition?.extension?.product?.id;
		if (!productId) return;

		addItemToUserCart({
			variables: {
				input: {
					productId,
				},
			},
		});
	}, [query, navigate]);

	const handleOnUseLicense = useCallback(() => {
		if (!query.node?.root?.id) return;

		bookTree({
			variables: {
				input: {
					rootId: query.node?.root?.id,
				},
			},
			onCompleted: () => {
				navigate(Path.root.withId(rootId).overview.path);
			},
		});
	}, [bookTree, navigate, query.node?.root?.id, rootId]);

	const rootName = query.node?.root?.structureDefinition?.title;
	const isIHK = query?.node?.root?.structureDefinition?.extension?.reducedData?.isIHK;
	const needLicense =
		query?.node?.root?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const availableLicensesCount =
		query?.node?.root?.structureDefinition?.extension?.licenseAvailability?.numAvailable ?? 0;
	const receivableGamificationPoints =
		query?.node?.root?.structureDefinition?.extension?.reducedData
			?.receivableGamificationPoints;
	// const hasTrailer = !!query?.node?.root?.structureDefinition?.extension?.trailer?.url;
	// const hasGoals =
	// 	(query?.node?.root?.structureDefinition?.extension?.learnGoals?.length ?? 0) > 0;
	// const hasInstructors = (query?.node?.root?.instructorsV2.edges?.length ?? 0) > 0;
	const canOrderIHK =
		query?.node?.root?.structureDefinition?.extension?.ihkState === "ReadyToOrder";

	const tabBar = (
		<TabBar activeIndex={activeIndex} onTabChange={handleOnTabChange}>
			<TabPanel header={t("course_screen.tabOverview")} />
			<TabPanel header={t("course_screen.tabModule")} />
			<TabPanel header={t("course_screen.tabMaterial")} />
		</TabBar>
	);

	return (
		<ScreenWithNavbarSidemenuLayout
			noPadding
			noSideMenuPadding
			canGoBack={!isXLargeUp}
			contentContainerId={RootContainerId}
			sideMenu={
				<div className={sideMenuWrapperClass}>
					<RouterContentMenu
						items={[
							{
								id: "overview",
								label: t("course_screen.tabOverview"),
							},
							{
								id: "materials",
								label: t("course_screen.tabMaterial"),
							},
							{
								id: "modules",
								label: t("course_screen.tabModule"),
								children: query.node?.root && (
									<BranchNodeTab
										learnOpportunityV2FragmentRef={query.node.root}
									/>
								),
							},
						]}
					/>
					{((isIHK && rootName) || needLicense) && (
						<div className={licenseWrapperClass}>
							{((isIHK && rootName) || receivableGamificationPoints) && (
								<B1Span className={colorShade80Class}>
									<strong>Was du erhältst:</strong>
								</B1Span>
							)}
							{isIHK && rootName && (
								<CertificateCard
									rootName={rootName}
									canOrder={canOrderIHK}
									rootId={rootId}
								/>
							)}
							{receivableGamificationPoints && (
								<div className={receiverGamificationPointsWrapperClass}>
									<H1Span className={colorWarning100Class}>
										<strong>+{receivableGamificationPoints}</strong>
									</H1Span>
									<KeyMedalSvg width={22} height={22} />
								</div>
							)}
							{needLicense && availableLicensesCount === 0 && (
								<div className={needsLicenseWrapperClass}>
									<B1Span className={colorShade80Class}>
										<strong>Keine Lizenz im Besitz</strong>
									</B1Span>
									<Button
										label="Lizenz zum Warenkorb hinzufügen"
										colorVersion="outline"
										className="w-full"
										iconName="shoppingBasket"
										onClick={handleOnCartClickout}
									/>
								</div>
							)}
							{needLicense && availableLicensesCount > 0 && (
								<div className={needsLicenseWrapperClass}>
									<B1Span className={colorShade80Class}>
										<strong>{availableLicensesCount} Lizenzen verfügbar</strong>
									</B1Span>
									<Button
										label="Lizenz einlösen"
										colorVersion="outline"
										className="w-full"
										iconName="shoppingBasket"
										onClick={handleOnUseLicense}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			}
			bottomContent={<AdvanceTreeNavigation>{!isXLargeUp && tabBar}</AdvanceTreeNavigation>}
		>
			<div className={wrapperClass}>
				<div className={paddingWrapperClass}>
					{query.node?.root && (
						<RootProgress
							hideImage={!isRootOverviewPath}
							learnOpportunityV2FragmentRef={query.node.root}
						/>
					)}
					{/* {!isXLargeUp && <StickyWrapper>{tabBar}</StickyWrapper>} */}
				</div>
				{!isRootOverviewPath ? (
					<div className={subpathBackgroundClass}>
						<Outlet />
					</div>
				) : (
					<div className={paddingWrapperClass}>
						<Outlet />
					</div>
				)}
			</div>
		</ScreenWithNavbarSidemenuLayout>
	);
};

export const RootScreen = () => {
	const { rootId } = useParams();
	const [spyEnabled, setSpyEnabled] = useState(false);
	const [lastContentId, setLastContentId] = useState<string>();

	return rootId ? (
		<RootScreenContext.Provider
			value={{ spyEnabled, setSpyEnabled, lastContentId, setLastContentId }}
		>
			<Suspense fallback={<RootScreenSkeleton />}>
				<RootScreenWithRootId rootId={rootId} />
			</Suspense>
		</RootScreenContext.Provider>
	) : null;
};
