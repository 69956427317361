import { graphql } from "react-relay";

export const QUERY = graphql`
	query rootOverview_GetRootOverviewQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				id
				description
				instructorsV2 {
					edges {
						node {
							id
						}
					}
					...instructorsComponent_InstructorsConnectionFragment
				}
				structureDefinition {
					title
					... on LearnOpportunityRootStructureDefinition {
						extension {
							... on PublishedRootExtensionImpl {
								trailer {
									url
								}
								learnGoals
								reducedData {
									receivableGamificationPoints
									isIHK
								}
								unlockInfo {
									kind
								}
								licenseAvailability {
									... on LicenseAvailabilityFreeLicensesAvailable {
										numAvailable
									}
								}
								product {
									id
								}
								ihkState
							}
						}
					}
				}
			}
		}
	}
`;
