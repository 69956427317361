import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { motion } from "framer-motion";
import { ReactComponent as CheckBadgeIcon } from "@assets/icons/check-badge.svg";
import {
	controlsContainerClass,
	controlsWrapperClass,
	iconWrapperClass,
	indicatorButtonClass,
	indicatorsClass,
	lastPageButtonClass,
	springIndicatorAnimation,
	springIndicatorTransition,
} from "@components/text-with-pages-element-controls/text-with-pages-element-controls.styles";

import { TextWithPagesElementControlsProps } from "@components/text-with-pages-element-controls/text-with-pages-element-controls.types";
export const TextWithPagesElementControls = ({
	amountOfItems,
	onIndicatorClick,
	onPreviousPageClick,
	onNextPageClick,
	currentPageIndex,
}: TextWithPagesElementControlsProps) => {
	const handleOnIndicatorClick = (index: number) => {
		onIndicatorClick(index);
	};

	const isFirstPage = currentPageIndex === 0;
	const isLastPage = currentPageIndex === amountOfItems - 1;

	const indicators = Array.from({ length: amountOfItems });

	const lastPageButton = (
		<div className={lastPageButtonClass}>
			<div className={iconWrapperClass}>
				<CheckBadgeIcon />
			</div>
		</div>
	);

	const nextPageButton = (
		<Button
			disabled={isLastPage}
			onClick={onNextPageClick}
			colorVersion={"outline"}
			iconName={"arrowRight"}
		/>
	);

	return (
		<div className={controlsWrapperClass}>
			<div className={controlsContainerClass}>
				<Button
					disabled={isFirstPage}
					onClick={onPreviousPageClick}
					colorVersion={"outline"}
					iconName={"arrowLeft"}
				/>
				<div className={indicatorsClass}>
					{indicators.map((_, index) => (
						<div
							key={index}
							className={indicatorButtonClass({ isActive: false })}
							onClick={() => handleOnIndicatorClick(index)}
						/>
					))}

					<motion.div
						className={indicatorButtonClass({ isActive: true })}
						initial={false}
						animate={springIndicatorAnimation(currentPageIndex)}
						transition={springIndicatorTransition}
					/>
				</div>
				{isLastPage ? lastPageButton : nextPageButton}
			</div>
		</div>
	);
};
