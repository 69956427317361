import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const screenWrapperClass = flex({
	flexDirection: "column",
	height: "full",
	flex: "1",
	gap: "32",
	p: "24",
	overflowY: "auto",
	md: {
		p: "40",
	},
});

export const optionsWrapperClass = flex({
	flexDirection: "column",
	gap: "16",
});

export const radioHeaderWrapperClass = flex({
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	flex: "1",
});

export const textRightClass = css({
	textAlign: "right",
});

export const monthlyOptionsWrapperClass = flex({
	flexDirection: "column",
	gap: "16",
	bg: "shade.5",
	borderRadius: "8",
	px: "16",
	py: "24",
});
