import { flex } from "@styled-system/patterns";

export const contentClass = flex({
	direction: "column",
	flex: "1",
	padding: "24",
	margin: "auto",
	width: "[min(100vw,25rem)]",
	overflowY: "hidden",
	md: {
		width: "auto",
		margin: "0",
		paddingTop: "0",
	},
	"2xl": {
		paddingX: "80",
		paddingY: "40",
	},
});

export const childrenWidthClass = flex({
	direction: "column",
	flex: "1",
	overflowY: "hidden",
	md: {
		width: "[21rem]",
	},
});
