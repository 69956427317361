import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	direction: "column",
	height: "screen",
});

export const contentWrapperClass = css({
	flex: "1",
});
