import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Checkbox } from "@thekeytechnology/academies-lib-webapp/components/checkbox";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { usePermissions } from "@thekeytechnology/academies-lib-webapp/hooks/use-permissions";
import { selectCurrentUserId } from "@thekeytechnology/academies-lib-webapp/slices";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { UserContextMenuRef } from "@screens/account/parts/members-tab/parts/user-context-menu/user-context-menu.types";
import { shade40 } from "@themes/colors";
import { H3Span, P3Span } from "@themes/font-tags";
import { USER_FRAGMENT } from "./user-card.graphql";
import {
	wrapperClass,
	nameWrapperClass,
	nameAndGroupsWrapperClass,
	labelsWrapperClass,
	contextMenuWrapperClass,
} from "./user-card.styles";
import { UserCardProps } from "./user-card.types";

//TODO: add-translations
export const UserCard = ({
	isSelected = false,
	showContextMenu,
	onSelectToggeled,
	userFragmentRef,
	children,
}: UserCardProps) => {
	const ref = useRef<UserContextMenuRef>(null);
	const userInAccountWrapper = useFragment(USER_FRAGMENT, userFragmentRef ?? null);
	const {
		user: { user },
	} = userInAccountWrapper;

	const userId = useSelector(selectCurrentUserId);

	const { isAccountOwner } = usePermissions();

	if (!user) return null;

	const handleOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
		ref.current?.toggle(event, event?.currentTarget);
	};

	const isOwner = userInAccountWrapper.isManager;

	const handleOnCheckboxChanged = () => {
		onSelectToggeled?.(user);
	};

	const title = isOwner ? "Manager*In" : "Benutzer*In";
	const canBeEdited = (isAccountOwner && user?.id !== userId) || showContextMenu;

	return (
		<>
			<Card bodyPaddingInRem={0.75} borderColor={shade40}>
				<div className={wrapperClass}>
					{user && (
						<>
							<Checkbox checked={isSelected} onChange={handleOnCheckboxChanged} />
							<UserAvatar userFragmentRef={user} />
						</>
					)}
					<div className={nameWrapperClass}>
						<P3Span>{title}</P3Span>
						<div className={nameAndGroupsWrapperClass}>
							<H3Span>{user?.name}</H3Span>
							<div className={labelsWrapperClass}>
								{userInAccountWrapper.groups.map((group: any) => (
									<Label
										key={group.id}
										size="smal"
										label={group.name}
										severity="info"
									/>
								))}
							</div>
						</div>
					</div>
					{canBeEdited && (
						<div className={contextMenuWrapperClass}>
							<Icon icon="contextMenu" onClick={handleOpenContextMenu} />
						</div>
					)}
				</div>
			</Card>
			{children?.(ref)}
		</>
	);
};
