import { graphql } from "react-relay";

export const ACTIVE_ELEARNING_CONTENT_SUBMISSION_DEFINITION = graphql`
	fragment textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				id
				title
				...textWithPagesLearnElement_TextWithPagesLearnElementFragment
			}
		}
	}
`;
