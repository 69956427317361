import { grid } from "@styled-system/patterns";

export const wrapperClass = grid({
	position: "relative",
	gridTemplateColumns: "12",
	backgroundColor: "shade.5",
	minWidth: "[19rem]",
	md: {
		paddingTop: "40",
		paddingX: "40",
	},
	xl: {
		gridTemplateColumns: "[26rem 1fr]",
		paddingTop: "0",
		paddingX: "0",
		marginRight: "[5vw]",
	},
});
