import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { graphql, useFragment } from "react-relay";
import { TextLearnElement } from "@components/text-learn-element";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { TextElement_ElearningContentSubmissionDefinitionFragment$key } from "@relay/TextElement_ElearningContentSubmissionDefinitionFragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH1Span } from "@themes/font-tags";
import { TextElementProps } from "./text-element.interface";
import { Spacing32 } from "./text-element.styles";

const E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment TextElement_ElearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				title
				id
				... on EnhancedTextLearnElement {
					text
					readMore
					readMoreButtonText
				}
				...textLearnElement_EnhancedTextLearnElementFragment
			}
		}
	}
`;

export const TextElement = ({ contentSubmissionDefinitionFragmentRef }: TextElementProps) => {
	const contentSubmissionDefinition =
		useFragment<TextElement_ElearningContentSubmissionDefinitionFragment$key>(
			E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);

	const element = contentSubmissionDefinition?.currentElementState?.element;

	return (
		<SideMenuContentPadding data-selection-menu-element-id={element?.id}>
			{element?.title && (
				<>
					<TkaH1Span tkaColor={shade100}>{stripHtml(element.title)}</TkaH1Span>
					<Spacing32 />
				</>
			)}
			<TextLearnElement learnElementFragmentRef={element} />
		</SideMenuContentPadding>
	);
};
