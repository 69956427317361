import { type OverlayPanel as PROverlayPanel } from "primereact/overlaypanel";
import { OverlayPanel as OverlayPanelPrimitive } from "primereact/overlaypanel";
import { type ForwardedRef, forwardRef } from "react";
import { overlayPanelClass } from "./overlay-panel.styles";
import { OverlayPanelStyles, type OverlayPanelProps } from "./overlay-panel.types";

const OverlayPanelComponent = (
	{ padding, borderRadius, children }: OverlayPanelProps,
	ref: ForwardedRef<PROverlayPanel>,
) => (
	<OverlayPanelPrimitive
		ref={ref}
		className={overlayPanelClass}
		style={
			{
				"--padding": padding?.rem() ?? "24px",
				"--borderRadius": borderRadius?.rem() ?? "12px",
			} as OverlayPanelStyles
		}
	>
		{children}
	</OverlayPanelPrimitive>
);

export const OverlayPanel = forwardRef(OverlayPanelComponent);
export type OverlayPanel = PROverlayPanel;
