import { match } from "ts-pattern";
import type { Persolog_ClassType } from "@relay/persologEvaluationClassModal_PersologEvaluationLearnElementFragment.graphql";

export const getClassTypeName = (classType: Persolog_ClassType) => {
	return match(classType)
		.with("D", () => "Dominant")
		.with("I", () => "Initiativ")
		.with("G", () => "Gewissenhaft")
		.with("S", () => "Stetig")
		.exhaustive();
};
