import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { colorShade60Class } from "@themes/color-classes";
import { H2Span, P2Span } from "@themes/font-tags";
import {
	leftWrapperClass,
	textWrapperClass,
	wrapperClass,
} from "./payment-duration-radio-button-template.styles";
import { PaymentDurationRadioButtonTemplateProps } from "./payment-duration-radio-button-template.types";

export const PaymentDurationRadioButtonTemplate = ({
	durationMonths,
	grossPrice,
	monthlyRate,
	hasSurcharge,
}: PaymentDurationRadioButtonTemplateProps) => {
	const formattedGrossPrice = formatCurrency(grossPrice);
	const formattedMonthlyRate = formatCurrency(monthlyRate);
	const subtitle = hasSurcharge
		? `Mit dem Aufschlag für monatliche Zahlweise ergibt sich eine Gesamtzahlsumme von ${formattedGrossPrice}`
		: `Dadurch ergibt sich eine Gesamtzahlsumme von ${formattedGrossPrice}`;

	return (
		<div className={wrapperClass}>
			<div className={leftWrapperClass}>
				<P2Span>In {durationMonths} monatlichen Zahlungen</P2Span>
				<P2Span className={colorShade60Class}>{subtitle}</P2Span>
			</div>
			<div className={textWrapperClass}>
				<H2Span>{formattedMonthlyRate} / Monat</H2Span>
			</div>
		</div>
	);
};
