import { NavLink } from "react-router-dom";
import { H3Span } from "@themes/font-tags";
import {
	linkClass,
	linkContentClass,
	linkWrapperClass,
	listWrapperClass,
} from "./router-content-menu.styles";
import { RouterContentMenuProps } from "./router-content-menu.types";

export const RouterContentMenu = ({ items }: RouterContentMenuProps) => {
	const links = items.map((item) => (
		<div key={item.id} className={linkWrapperClass}>
			<NavLink to={item.id} className={linkClass}>
				<H3Span>{item.label}</H3Span>
			</NavLink>
			{item.children && <div className={linkContentClass}>{item.children}</div>}
		</div>
	));

	return <div className={listWrapperClass}>{links}</div>;
};
