import { css } from "@styled-system/css";

export const inputTextMockClass = css({
	display: "flex",
	gap: "4",
	alignItems: "center",
	rounded: "4",
	height: "40",
	padding: "8",
	cursor: "pointer",
	md: {
		border: "1px solid",
		borderColor: "shade.40",
	},
});

export const wrapperClass = css({
	position: "relative",
});

export const filterTextWrapperClass = css({
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
});

export const mobileBadgeWrapperClass = css({
	position: "absolute",
	top: "0",
	right: "0",
	translateY: "[40%]",
	translateX: "[10%]",
	transformOrigin: "10% 0",
	md: {
		position: "relative",
		translateX: "0",
		translateY: "0",
	},
});

export const desktopLabelWrapperClass = css({
	flexShrink: "0",
});
