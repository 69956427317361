import styled from "styled-components";
import tw from "twin.macro";
import { H3Span } from "@themes/font-tags";

export const CardWrapper = styled.div<{ maxWidth?: number }>`
	${tw`
		min-h-[4rem]
		flex
	`}
	max-width: ${({ maxWidth }) => maxWidth ?? 30}rem;
`;

export const Wrapper = tw.div`
	flex
	flex-row
	w-full
	relative
`;

export const Content = tw.div`
	flex
	flex-col
	flex-1
	ml-80
	gap-4
`;

export const TagsRow = tw.div`
	flex
	flex-row
	justify-between
	self-stretch
`;

export const Title = tw(H3Span)`
	line-clamp-2
`;
