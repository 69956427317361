/**
 * @generated SignedSource<<d66cbd2217fd7a97f7b1089262db78e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow";
export type IHKCertificateOrderStateKind = "ErrorOccurred" | "NotIHK" | "Ordered" | "ReadyToOrder" | "RewardNotAchievedYet";
export type StartContentConfigType = "StartContent_CanAfterStartingTree" | "StartContent_CanIfInTreeFlow" | "StartContent_CanIfUnlocked" | "StartContent_CanNot";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type advanceTreeNavigation_LearnOpportunityV2Fragment$data = {
  readonly nextContentNodeId: string | null;
  readonly root: {
    readonly structureDefinition: {
      readonly extension?: {
        readonly cartClickout?: {
          readonly relativeLink: string;
        } | null;
        readonly ihkState?: IHKCertificateOrderStateKind;
        readonly licenseAvailability?: {
          readonly numAvailable?: number;
        };
        readonly product?: {
          readonly id: string;
          readonly netPrice: number;
        } | null;
        readonly unlockInfo?: {
          readonly firstContentId?: string;
          readonly kind: UnlockInfoKind;
        };
      };
      readonly viewerTreeState?: {
        readonly headContentId?: string;
        readonly kind: ViewerTreeStateKind;
        readonly startedAt?: string;
      };
    };
  } | null;
  readonly typeDefinition: {
    readonly contentKind?: ContentKind;
    readonly contentNodeAdvancementResult?: {
      readonly reasonConfigTypes?: ReadonlyArray<StartContentConfigType>;
      readonly status: ContentNodeAdvancementStatus;
    };
  };
  readonly " $fragmentType": "advanceTreeNavigation_LearnOpportunityV2Fragment";
};
export type advanceTreeNavigation_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: advanceTreeNavigation_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"advanceTreeNavigation_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contentKind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "contentNodeAdvancementResult",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reasonConfigTypes",
            "storageKey": null
          }
        ],
        "type": "CanNotBeStartedContentNodeAdvancementResult",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "advanceTreeNavigation_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "viewerTreeState",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "headContentId",
                          "storageKey": null
                        },
                        (v1/*: any*/)
                      ],
                      "type": "IsStartedViewerTreeState",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "type": "IsFinishedViewerTreeState",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "unlockInfo",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "firstContentId",
                                  "storageKey": null
                                }
                              ],
                              "type": "UnlockInfoDemo",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "licenseAvailability",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "numAvailable",
                                  "storageKey": null
                                }
                              ],
                              "type": "LicenseAvailabilityFreeLicensesAvailable",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CartClickout",
                          "kind": "LinkedField",
                          "name": "cartClickout",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "relativeLink",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "product",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "netPrice",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ihkState",
                          "storageKey": null
                        }
                      ],
                      "type": "PublishedRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "LearnOpportunityContentTypeDefinition",
          "abstractKey": "__isLearnOpportunityContentTypeDefinition"
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "LearnAsyncContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nextContentNodeId",
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "476d70358a67500b88989db2c6df7f95";

export default node;
