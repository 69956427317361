import valid from "card-validator";
import * as Yup from "yup";

export const creditcardFormSchema = Yup.object().shape({
	cardNumber: Yup.bool().oneOf([true], "Bitte gib eine gültige Kartennummer ein."),
	name: Yup.string()
		.test(
			"test-card-number",
			"Bitte gib einen gültigen Namen ein.",
			(value) => valid.cardholderName(value).isValid,
		)
		.required(),
	expiration: Yup.bool().oneOf([true], "Bitte gib einen gültiges Ablaufdatum ein."),
	securityCode: Yup.bool().oneOf([true], "Bitte gib einen gültigen Sicherheitscode ein."),
});
