import { Badge } from "@thekeytechnology/academies-lib-webapp/components/badge";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { motion } from "framer-motion";
import { ReactComponent as SuccessModalIcon } from "@assets/modal-icon-success-new.svg";
import { colorSecondary120Class } from "@themes/color-classes";
import { P2Span, H1Span } from "@themes/font-tags";
import {
	badgeWrapperClass,
	contentWrapperClass,
	iconAndBadgeWrapperClass,
	messageContainerClass,
	wrapperClass,
} from "./content-submission-success-modal.styles";
import { ContentSubmissionSuccessModalProps } from "./content-submission-success-modal.types";

export const ContentSubmissionSuccessModal = ({
	isVisible = true,
}: ContentSubmissionSuccessModalProps) => {
	const { t } = useTkaTranslation("successEvaluationModal");

	const points = 0;

	if (!isVisible) return null;

	return (
		<motion.div
			initial={{ y: "100%", opacity: 0 }}
			animate={{ y: "calc(0% + 1.25rem)", opacity: 1 }}
			exit={{ y: "100%", opacity: 0 }}
			transition={{ type: "spring", stiffness: 100 }}
			className={wrapperClass}
		>
			<div className={contentWrapperClass}>
				<div className={iconAndBadgeWrapperClass}>
					<SuccessModalIcon />
					{points > 0 && (
						<div className={badgeWrapperClass}>
							<Badge text={"+" + points} />
						</div>
					)}
				</div>
				<div className={messageContainerClass}>
					<H1Span className={colorSecondary120Class}>
						{t("success_evaluation_modal.title")}
					</H1Span>
					<P2Span className={colorSecondary120Class}>
						{t("success_evaluation_modal.description")}
					</P2Span>
				</div>
			</div>
		</motion.div>
	);
};
