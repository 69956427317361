import { flex } from "@styled-system/patterns";

export const formWrapperClass = flex({
	direction: "column",
	gap: "32",
});

export const iniviationTitleWrapperClass = flex({
	direction: "column",
	alignItems: "center",
	textAlign: "center",
	gap: "12",
});

export const textCenterClass = flex({
	direction: "column",
	alignItems: "center",
	textAlign: "center",
	gap: "32",
});

export const inputsWrapperClass = flex({
	direction: "column",
	gap: "12",
});
