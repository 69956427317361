import { graphql } from "react-relay";

export const TEXT_WITH_PAGES_PAGE_FRAGMENT = graphql`
	fragment textWithPagesElementPage_TextWithPagesPageFragment on TextWithPagesPageData {
		kind
		... on TextWithPagesTextPageData {
			text
		}
		... on TextWithPagesImagePageData {
			image {
				url
			}
		}
	}
`;
