import { graphql } from "react-relay";

export const PERSOLOG_ELEMENT_FRAGMENT = graphql`
	fragment persologElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					element {
						id
						title
						elementType
						... on Persolog_PersologQuestionLearnElement {
							id
							title
							questions {
								id
								title
								questionClasses {
									classType
									text
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const SUBMIT_PERSOLOG_QUESTION_ELEMENT_MUTATION = graphql`
	mutation persologElement_SubmitPersologQuestionElementMutation(
		$input: SubmitPersologQuestionElementInput!
	) {
		LearnV2 {
			submitPersologQuestionElement(input: $input) {
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								kind
								... on InputAndIsCorrectElementState {
									isCorrect
									inputElementState {
										... on PersologInputElementState {
											order {
												classTypeOrder
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
