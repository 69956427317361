import { CardElement } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { creditcardFormSchema } from "./creditcard-form.consts";
import {
	CreditcardForm as CreditcardFormClass,
	CreditcardFormProps,
	CreditcardFormState,
} from "./creditcard-form.types";

//TODO: add-translations
const CreditcardFormComponent = ({ onSubmit }: CreditcardFormProps, ref: ForwardedRef<any>) => {
	const form = useFormik<CreditcardFormState>({
		initialValues: {
			cardNumber: false,
			name: "",
			expiration: false,
			securityCode: false,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: creditcardFormSchema,
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
	}));

	return (
		<form onSubmit={form.handleSubmit}>
			<CardElement id="card-element" />
		</form>
	);
};

export const CreditcardForm = forwardRef(CreditcardFormComponent);
export type CreditcardForm = CreditcardFormClass;
