import { graphql } from "react-relay";

export const INVITATION_QUERY = graphql`
	query loginSteps_InvitationByTokenQuery($token: String!, $skip: Boolean!) {
		Auth {
			InvitationByToken(token: $token) @skip(if: $skip) {
				invitingUserName
				accountName
			}
		}
	}
`;
