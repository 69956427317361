/**
 * @generated SignedSource<<bfcf26ce7aa20390b3a5523c989f97a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Billing_CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type CountryCode = "DE";
import { FragmentRefs } from "relay-runtime";
export type businessClientForm_BaseDataFragment$data = {
  readonly __typename: string;
  readonly accountData?: {
    readonly bic: string | null;
    readonly iban: string;
  } | null;
  readonly billingOffice?: string | null;
  readonly city: string | null;
  readonly companyLegalForm?: Billing_CompanyType | null;
  readonly companyName?: string | null;
  readonly countryCode: CountryCode | null;
  readonly houseNumber: string | null;
  readonly invoiceEmail?: string | null;
  readonly phoneNumber?: string | null;
  readonly postalCode: string | null;
  readonly street: string | null;
  readonly taxData?: {
    readonly taxIdentificationNumber: string;
    readonly taxNumber: string;
  } | null;
  readonly " $fragmentType": "businessClientForm_BaseDataFragment";
};
export type businessClientForm_BaseDataFragment$key = {
  readonly " $data"?: businessClientForm_BaseDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"businessClientForm_BaseDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "businessClientForm_BaseDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billingOffice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyLegalForm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountData",
          "kind": "LinkedField",
          "name": "accountData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bic",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "iban",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxData",
          "kind": "LinkedField",
          "name": "taxData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxIdentificationNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "BusinessBaseData",
      "abstractKey": null
    }
  ],
  "type": "baseData",
  "abstractKey": "__isbaseData"
};

(node as any).hash = "07a34b14328d6c3809173fea4debbdf9";

export default node;
