import { css } from "@styled-system/css";
import { flex, grid } from "@styled-system/patterns";

export const screenWrapperClass = flex({
	direction: "column",
	height: "full",
	gap: "32",
	padding: "24",
	overflowY: "auto",
	md: {
		padding: "40",
	},
});

export const headerWrapperClass = flex({
	direction: "column",
	gap: "12",
});

export const productWrapperClass = flex({});

export const productItemClass = flex({
	direction: "column",
	alignSelf: "stretch",
	alignItems: "stretch",
	rowGap: "16",
	md: {
		flexDirection: "row",
		alignItems: "center",
	},
});

export const productCardWrapperClass = css({
	md: {
		minWidth: "[22rem]",
	},
});

export const controlsPriceWrapperClass = flex({
	flex: "1",
	alignItems: "center",
	alignSelf: "stretch",
});

export const countControlsClass = flex({
	flex: "1",
	alignItems: "center",
	gap: "16",
	pr: "16",
	md: {
		justifyContent: "center",
		px: "16",
	},
});

export const itemPriceWrapperClass = flex({
	gap: "16",
	alignItems: "center",
	whiteSpace: "nowrap",
	md: {
		flexDirection: "column",
		alignItems: "end",
		minWidth: "[6rem]",
	},
});

export const dividerClass = css({
	minHeight: "[1px]",
	alignSelf: "stretch",
	backgroundColor: "shade.10",
});

export const discountWrapperClass = flex({
	direction: "column",
	gap: "16",
	md: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
});

export const discountTitleTagWrapperClass = flex({
	direction: "column",
	gap: "8",
});

export const discountTitleWrapperClass = flex({
	gap: "8",
	alignItems: "center",
});

export const discountPercentWrapperClass = flex({
	alignSelf: "end",
	alignItems: "end",
	gap: "16",
	md: {
		flexDirection: "column",
	},
});

export const priceWrapperClass = flex({
	direction: "column",
	gap: "8",
});

export const priceRowsWrapperClass = flex({
	direction: "row",
	justify: "space-between",
	whiteSpace: "nowrap",
});

export const priceLeftGroupWrapperClass = flex({
	direction: "column",
	gap: "8",
});

export const priceRightGroupWrapperClass = flex({
	direction: "column",
	alignItems: "flex-end",
	gap: "8",
	textAlign: "right",
});

export const priceMonthlyWrapperClass = flex({
	alignSelf: "end",
});

export const discountCodeWrapperClass = flex({
	gap: "16",
});

export const moreProductsWrapperClass = flex({
	direction: "column",
	gap: "24",
});

export const productsListClass = grid({
	gap: "16",
	justifyContent: "center",
	gridTemplateColumns: "1",
	md: {
		gridTemplateColumns: "2",
	},
	lg: {
		gridTemplateColumns: "3",
	},
	xl: {
		gridTemplateColumns: "2",
	},
	"2xl": {
		gridTemplateColumns: "3",
	},
});
