import { graphql } from "react-relay";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment accountContextMenu_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			accounts {
				name
			}
		}
		...deleteAccountModalsFlow_AuthViewerSchemaFragment
		...leaveAccountModal_authViewerSchemaFragmentRef
	}
`;
