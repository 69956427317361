import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { H3Span, P2Span } from "@themes/font-tags";
import { iconWrapperClass, itemWrapperClass, textWrapperClass } from "./basket-timeline.styles";
import { BasketEvent, TimelineSkeletonProps } from "./basket-timeline.types";
import { Timeline } from "../timeline";

export const BasketTimelineSkeleton = ({
	currentStep,
	orientation = "horizontal",
}: TimelineSkeletonProps) => {
	const { t } = useTkaTranslation("basketScreen");

	const events: BasketEvent[] = [
		{
			label: t("basket_screen.basketTimelineBasketLabel"),
			icon: "shoppingBasketThin",
		},
		{
			label: t("basket_screen.basketTimelineContractLabel"),
			icon: "paragraphTwoColumn",
		},
		{
			label: t("basket_screen.basketTimelinePaymentLabel"),
			icon: "creditCardMastercard1",
		},
		{
			label: t("basket_screen.basketTimelineOverviewLabel"),
			icon: "shoppingBasketCheck1",
		},
	];

	const renderContent = (event: BasketEvent, index: number) => {
		const finished = index < currentStep - 1;
		const icon = finished ? "checkCircle" : event.icon;
		return (
			<div className={itemWrapperClass}>
				<div className={iconWrapperClass({ finished })}>
					<Icon icon={icon} sizeRem={1.5} />
				</div>
				<div className={textWrapperClass}>
					<H3Span>{event.label}</H3Span>
					{event.subtitle && <P2Span>{event.subtitle}</P2Span>}
				</div>
			</div>
		);
	};

	return (
		<Timeline
			events={events}
			orientation={orientation}
			currentStep={currentStep}
			progressBarOffestRem={0.875}
			hideContent={orientation === "horizontal"}
			content={renderContent}
		/>
	);
};
