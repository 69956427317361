import { cva } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		border: "1px solid",
		borderColor: "shade.20",
		borderRadius: "12",
		bg: "shade.0",
		"& .radio-button-panel-content": {
			border: "none",
			bg: "transparent",
		},
	},
	variants: {
		disabled: {
			true: {
				opacity: "0.5",
			},
		},
		collapsed: {
			true: {
				borderColor: "shade.20",
			},
			false: {
				borderColor: "shade.100",
			},
		},
		hasChildren: {
			true: {
				"& .radio-button-panel-content": {
					pt: "8",
					pb: "32",
					px: "16",
				},
			},
			false: {
				"& .radio-button-panel-content": {
					pt: "0",
					pb: "0",
					px: "0",
				},
			},
		},
	},
});

export const headerWrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "row",
		flex: "1",
		gap: "24",
		textAlign: "left",
		alignItems: "center",
		justifyContent: "space-between",
		p: "16",
		_hover: {
			cursor: "pointer",
		},
	},
	variants: {
		disabled: {
			true: {
				_hover: {
					cursor: "default",
				},
			},
		},
	},
});

export const expandedContentWrapperClass = stack({});
