import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { MouseEventHandler, useRef } from "react";
import { useLazyLoadQuery } from "react-relay";
import { ProfileExpandableBox } from "@components/profile-expandable-box";
import { UserHeader } from "@components/user-header";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { profileScreen_Query } from "@relay/profileScreen_Query.graphql";
import { colorError100Class, colorShade80Class } from "@themes/color-classes";
import { error100 } from "@themes/colors";
import { H3Span, P3Span } from "@themes/font-tags";
import { AccountContextMenu } from "./parts/account-context-menu";
import { CertificateItem } from "./parts/certificate-item";
import { LevelItem } from "./parts/level-item";
import { QUERY } from "./profile.graphql";
import {
	boxesClass,
	buttonsWrapperClass,
	expandableBoxGridClass,
	levelProgressBarClass,
	levelProgressBarRunnerClass,
	levelProgressWrapperClass,
	permanentActionsButtonClass,
	seperatorClass,
	userHeaderClass,
	wrapperClass,
} from "./profile.styles";
import { LevelProgressBarRunnerStyle } from "./profile.types";

export const ProfileScreen = () => {
	const query = useLazyLoadQuery<profileScreen_Query>(
		QUERY,
		{},
		{
			fetchPolicy: "store-and-network",
		},
	);
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const academiesUserExtension = query?.Viewer?.Auth?.currentUser?.user?.extensions?.find(
		(e) => e.kind === "Academies",
	);

	const progressInPercent = academiesUserExtension?.progressInPercent ?? 0;
	const sumGamificationPoints = academiesUserExtension?.sumGamificationPoints ?? 0;

	const levelDefinitions =
		query?.Gamification?.LevelDefinitions?.edges
			?.map((e) => e?.node)
			.map((node) => {
				const isUnlocked = (node?.requiredPoints ?? 0) <= sumGamificationPoints;
				return [node, isUnlocked] as const;
			}) ?? [];

	const handleOnShowContextMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		overlayRef.current?.toggle(event, event.currentTarget);
	};

	return (
		<ScreenWithProfileMenuLayout>
			<div className={wrapperClass}>
				<UserHeader
					className={userHeaderClass}
					userFragmentRef={query?.Viewer?.Auth?.currentUser?.user}
				/>
				<div className={boxesClass}>
					<ProfileExpandableBox
						title="Level"
						header={
							<div className={levelProgressWrapperClass}>
								<div className={levelProgressBarClass}>
									<div
										className={levelProgressBarRunnerClass}
										style={
											{
												"--progress": `${progressInPercent}%`,
											} as LevelProgressBarRunnerStyle
										}
									/>
								</div>
								<P3Span className={colorShade80Class}>{progressInPercent}%</P3Span>
							</div>
						}
					>
						<div className={expandableBoxGridClass({ columnStyle: "5" })}>
							{levelDefinitions.map(([levelDefinition, isUnlocked]) => (
								<LevelItem
									key={levelDefinition?.id}
									isUnlocked={isUnlocked}
									levelDefinitionFragmentRef={levelDefinition}
								/>
							))}
						</div>
					</ProfileExpandableBox>
					{/* <ProfileExpandableBox title="Achievements">
						<div className={expandableBoxGridClass({ columnStyle: "5" })}>
							{Array.from({ length: 20 }).map((_, index) => (
								<AchievementItem key={"a" + index} />
							))}
						</div>
					</ProfileExpandableBox> */}
					<ProfileExpandableBox title="Zertifikate" defaultHeightRem={11.875}>
						<div className={expandableBoxGridClass({ columnStyle: "3" })}>
							{Array.from({ length: 15 }).map((_, index) => (
								<CertificateItem
									key={"c" + index}
									title="Fachkraft für Selbst- & Zeitmanagement (IHK)"
								/>
							))}
						</div>
					</ProfileExpandableBox>
				</div>
				<div className={buttonsWrapperClass}>
					<hr className={seperatorClass} />
					<div>
						<button
							type="button"
							onClick={handleOnShowContextMenu}
							className={permanentActionsButtonClass}
						>
							<Icon icon="contextMenu" color={error100} />
							<H3Span className={colorError100Class}>Permanente Aktionen</H3Span>
						</button>
						{query?.Viewer?.Auth && (
							<AccountContextMenu
								ref={overlayRef}
								authViewerSchemaFragmentRef={query.Viewer.Auth}
							/>
						)}
					</div>
				</div>
			</div>
		</ScreenWithProfileMenuLayout>
	);
};
