import { graphql } from "react-relay";

export const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment persologEvaluationElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					element {
						id
						title
						elementType
						... on Persolog_PersologEvaluationLearnElement {
							id
							title
							classes {
								classType
								title
								text
								image {
									id
									name
									url
								}
							}
							evaluation {
								classes {
									classType
									percentage
								}
							}
							...persologEvaluationClassModal_PersologEvaluationLearnElementFragment
						}
					}
				}
			}
		}
	}
`;
