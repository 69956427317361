import { LicensesGroupCardSkeleton } from "@components/licenses-group-card/licenses-group-card.skeleton";
import { SKELETON_COUNT } from "./licenses-tab.consts";
import { listWrapperClass } from "./licenses-tab.styles";

export const LicensesTabSkeleton = () => {
	return (
		<div className={listWrapperClass}>
			{Array.from({ length: SKELETON_COUNT }).map((_, index) => (
				<LicensesGroupCardSkeleton key={"licensesTabCardSkeleton" + index} />
			))}
		</div>
	);
};
