import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { HeaderFooterScreenTemplate } from "@components/header-footer-screen-template";
import { Path } from "@router/paths";
import { success100 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	iconBackgroundClass,
	textWrapperClass,
	wrapperClass,
} from "./shopping-cart-success.styles";

//TODO: add-translations
export const ShoppingCartSuccessScreenSkeleton = () => {
	const handleOnClick = () => {
		window.location.href = Path.progression.path;
	};

	return (
		<HeaderFooterScreenTemplate>
			<div className={wrapperClass}>
				<div className={iconBackgroundClass}>
					<Icon icon="shoppingBasketCheck1" color={success100} sizeRem={2.5} />
				</div>
				<div className={textWrapperClass}>
					<H1Span>Vielen Dank für Deine Bestellung</H1Span>
					<P2Span>
						Deine Bestellung ist bei uns eingegangen.&nbsp;
						<Skeleton width="100%" className="mt-4" />
					</P2Span>
				</div>
				<Button fillParent label="Zu deinen Lerninhalten" onClick={handleOnClick} />
			</div>
		</HeaderFooterScreenTemplate>
	);
};
