import { Skeleton, SkeletonShape } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";
import { centerWrapperClass, visibilityClass, wrapperClass } from "./navbar.styles";
import { NavbarItemSkeleton } from "../navbar-item/navbar-item.skeleton";

// TODO: remove-translations
export const NavbarSkeleton = () => {
	const theme = useTheme();

	return (
		<div className={wrapperClass}>
			<theme.LogoSquareComponent />
			<div className={centerWrapperClass}>
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<div className={visibilityClass({ hideXLUp: true })}>
					<Skeleton shape={SkeletonShape.Circle} size="2.5rem" />
				</div>
			</div>
			<div className={visibilityClass({ showXLUp: true })}>
				<Skeleton shape={SkeletonShape.Circle} size="2.5rem" />
			</div>
		</div>
	);
};
