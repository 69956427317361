import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment filterModal_QueryFragment on Query
	@refetchable(queryName: "filterModalRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 200 }
		after: { type: "String", defaultValue: null }
		filterByName: { type: "String", defaultValue: null }
		includeOwner: { type: "Boolean", defaultValue: true }
		includeUser: { type: "Boolean", defaultValue: true }
		doCalcMemberCount: { type: "Boolean", defaultValue: true }
	) {
		AccountMemberManagement {
			UserInAccountGroupWrappers(
				includeOwner: $includeOwner
				includeUser: $includeUser
				doCalcMemberCount: $doCalcMemberCount
				filterByName: $filterByName
			) {
				userInAccountGroupWrappers(first: $first, after: $after)
					@connection(key: "filterModal_userInAccountGroupWrappers") {
					__id
					edges {
						node {
							group {
								id
								name
							}
						}
					}
				}
			}
		}
	}
`;
