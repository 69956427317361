import { graphql } from "react-relay";

export const QUERY = graphql`
	query profileScreen_Query {
		Gamification {
			LevelDefinitions {
				edges {
					node {
						id
						requiredPoints
						...levelItem_LevelDefinitionFragment
					}
				}
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						extensions {
							kind
							... on AcademiesUserExtension {
								gamificationPoints
								progressInPercent
								sumGamificationPoints
							}
						}
						...userHeader_UserHeaderFragment
					}
				}
				...accountContextMenu_AuthViewerSchemaFragment
			}
		}
	}
`;
