/**
 * @generated SignedSource<<19f2658e539d66fdd3f0ad2c1869b6d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Billing_CustomerDetailsDataKind = "Business" | "Private";
export type SelectedPaymentMethodKind = "Monthly" | "OneTime";
import { FragmentRefs } from "relay-runtime";
export type basketTimeline_OrderFragment$data = {
  readonly cart: {
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
      };
    };
  } | null;
  readonly customerDetails: {
    readonly data: {
      readonly kind: Billing_CustomerDetailsDataKind;
    };
  } | null;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly rateCount: number;
    };
    readonly kind: SelectedPaymentMethodKind;
  } | null;
  readonly " $fragmentType": "basketTimeline_OrderFragment";
};
export type basketTimeline_OrderFragment$key = {
  readonly " $data"?: basketTimeline_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"basketTimeline_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "basketTimeline_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Billing_CustomerDetails",
      "kind": "LinkedField",
      "name": "customerDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rateCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlyPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "f3c88b6476b56d8223301ab50a71dea1";

export default node;
