/**
 * @generated SignedSource<<93fef0adba20072d8fef7774b226a309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "persolog" | "persologEvaluation" | "persologQuestion" | "podcast" | "podcastWithTimestamp" | "text" | "textWithPages" | "video";
export type NoteColor = "blue" | "default" | "green" | "red" | "yellow";
export type NoteSourceKind = "Course" | "Element" | "Module";
export type StructureType = "child" | "root";
import { FragmentRefs } from "relay-runtime";
export type upsertNoteModal_NoteFragment$data = {
  readonly color: NoteColor | null;
  readonly content: string | null;
  readonly id: string;
  readonly markedText: string | null;
  readonly source: {
    readonly __typename: string;
    readonly element?: {
      readonly elementType: ElementTypeV2;
    } | null;
    readonly kind: NoteSourceKind;
    readonly publishedRootNode?: {
      readonly id: string;
      readonly image: {
        readonly url: string | null;
      } | null;
      readonly structureDefinition: {
        readonly definitionType: StructureType;
        readonly title: string;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "upsertNoteModal_NoteFragment";
};
export type upsertNoteModal_NoteFragment$key = {
  readonly " $data"?: upsertNoteModal_NoteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_NoteFragment">;
};

import upsertNoteModal_NoteFragmentRefetch_graphql from './upsertNoteModal_NoteFragmentRefetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnOpportunityV2",
  "kind": "LinkedField",
  "name": "publishedRootNode",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": upsertNoteModal_NoteFragmentRefetch_graphql,
      "identifierField": "id"
    }
  },
  "name": "upsertNoteModal_NoteFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "markedText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "element",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "ElementNoteSourceType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "ModuleNoteSource",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "CourseNoteSource",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Note",
  "abstractKey": null
};
})();

(node as any).hash = "2af13a775b34b1e4213caa93a42a001a";

export default node;
