import { graphql } from "react-relay";

export const INVITE_MUTATION = graphql`
	mutation inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation(
		$input: InviteOrAddUsersToUserInAccountGroupsInput!
		$connections: [ID!]!
	) {
		AccountMemberManagement {
			inviteOrAddUsersToUserInAccountGroups(input: $input) {
				updatedAccountMembers {
					id
				}
				newAccountMembers @appendEdge(connections: $connections) {
					node {
						... on InvitationWrapper {
							invitation {
								id
								email
							}
						}
					}
				}
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment inviteMemberModal_QueryFragment on Query
	@refetchable(queryName: "inviteMemberModalRefetchQuery") {
		...standardInviteForm_QueryFragment
	}
`;
