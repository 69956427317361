import { IconContainer, StyledRadioButton } from "./radio-button.styles";
import { type RadioButtonProps, RadioButtonStatus } from "./radio-button.types";

export const RadioButton = ({
	checked,
	value,
	onChange,
	status,
	icon,
	iconBackgroundColor,
}: RadioButtonProps) => {
	const className = status === RadioButtonStatus.Error ? "p-invalid" : "";
	const disabled = status === RadioButtonStatus.Disabled;

	return icon ? (
		<IconContainer iconBackgroundColor={iconBackgroundColor}>{icon}</IconContainer>
	) : (
		<StyledRadioButton
			className={className}
			disabled={disabled}
			checked={checked}
			value={value}
			onChange={onChange}
		/>
	);
};
