import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { FC, useRef } from "react";
import { useFragment } from "react-relay";
import { BadgeV2 } from "@components/badge-v2";
import { shade100, shade40 } from "@themes/colors";
import { useMembersFilterContext } from "./members-filter.context";
import { QUERY_FRAGMENT } from "./members-filter.graphql";
import {
	wrapperClass,
	inputTextMockClass,
	mobileBadgeWrapperClass,
	desktopLabelWrapperClass,
} from "./members-filter.styles";
import { MembersFilterProps } from "./members-filter.types";
import { FilterModal } from "../filter-modal/filter-modal.component";

export const MembersFilter: FC<MembersFilterProps> = ({ queryFragmentRef }) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const { numberOfAppliedFilters } = useMembersFilterContext();
	const { isMediumUp } = useWindowSize();

	const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
		overlayRef.current?.show(event, event.currentTarget);
	};

	const handleCloseClick = () => {
		overlayRef.current?.hide();
	};

	const badgeCount = numberOfAppliedFilters ?? 0;
	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;

	return (
		<>
			<div className={wrapperClass}>
				<div className={inputTextMockClass} onClick={handleOpenClick}>
					<Icon icon={"filter"} sizeRem={iconSize} color={iconColor} />
					{isMediumUp && badgeCount > 0 && (
						<div className={desktopLabelWrapperClass}>
							<Label label={`+${badgeCount}`} />
						</div>
					)}
				</div>
				{!isMediumUp && badgeCount > 0 && (
					<div className={mobileBadgeWrapperClass}>
						<BadgeV2 value={badgeCount} severity={"brandStrong"} />
					</div>
				)}
			</div>
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef}>
				<FilterModal
					overlayRef={overlayRef}
					onClose={handleCloseClick}
					queryFragmentRef={queryFragment}
				/>
			</ResponsiveBottomSheetOverlayPanel>
		</>
	);
};
