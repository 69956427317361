import {
	RadioButton,
	RadioButtonStatus,
} from "@thekeytechnology/academies-lib-webapp/components/radio-button";
import { motion, AnimatePresence } from "framer-motion";
import { cx } from "@styled-system/css";
import {
	expandedContentWrapperClass,
	headerWrapperClass,
	wrapperClass,
} from "./radio-button-panel.styles";
import { RadioButtonPanelProps } from "./radio-button-panel.types";

export const RadioButtonPanel = ({
	header,
	checked,
	disabled,
	onChange,
	children,
}: RadioButtonPanelProps) => {
	const handleOnToggle = () => {
		!disabled && onChange?.(!checked);
	};
	const hasChildren = Boolean(children);

	return (
		<div
			className={wrapperClass({
				disabled,
				collapsed: !checked,
				hasChildren,
			})}
		>
			<button
				type="button"
				className={headerWrapperClass({
					disabled,
				})}
				onClick={handleOnToggle}
				disabled={disabled}
			>
				{header}
				<RadioButton
					status={disabled ? RadioButtonStatus.Disabled : undefined}
					checked={checked}
				/>
			</button>
			<AnimatePresence>
				{hasChildren && checked && (
					<motion.div
						initial={{ height: 0 }}
						animate={{ height: "auto" }}
						exit={{ height: 0 }}
						transition={{ type: "spring", duration: 0.4, bounce: 0 }}
						className={cx(expandedContentWrapperClass, "radio-button-panel-content")}
					>
						{children}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
