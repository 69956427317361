import { graphql } from "react-relay";

export const INVITATION_FRAGMENT = graphql`
	fragment invitationCard_InvitationFragment on AccountMember {
		... on InvitationWrapper {
			invitation {
				id
				email
			}
			groups {
				id
				name
			}
		}
	}
`;

export const DELETE_INVITATION_MUTATION = graphql`
	mutation invitationCard_DeleteInvitationMutation(
		$input: DeleteInvitationInput!
		$connections: [ID!]!
	) {
		AccountMemberManagement {
			deleteInvitation(input: $input) {
				deletedId @deleteEdge(connections: $connections)
			}
		}
	}
`;
