import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { EpicIcons, Icon } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { invoicesTab_InvoicesTabQueryFragmentRefetch } from "@relay/invoicesTab_InvoicesTabQueryFragmentRefetch.graphql";
import { invoicesTab_OrdersQuery } from "@relay/invoicesTab_OrdersQuery.graphql";
import { invoicesTab_QueryFragment$key } from "@relay/invoicesTab_QueryFragment.graphql";
import { shade0 } from "@themes/colors";
import { INVOICES_TAB_QUERY_FRAGMENT, ORDERS_QUERY } from "./invoices-tab.graphql";
import { InvoicesTabSkeleton } from "./invoices-tab.skeleton";
import { listClass, wrapperClass } from "./invoices-tab.styles";
import { InvoiceCard } from "./parts/invoice-card";

const InvoicesTabComponent = () => {
	const [paginationCount, setPaginationCount] = useState(10);

	const query = useLazyLoadQuery<invoicesTab_OrdersQuery>(
		ORDERS_QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const { data, refetch, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
		invoicesTab_InvoicesTabQueryFragmentRefetch,
		invoicesTab_QueryFragment$key
	>(INVOICES_TAB_QUERY_FRAGMENT, query);

	const handleRefetch = () => {
		refetch(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	const handleShowMore = () => {
		if (hasNext && !isLoadingNext) {
			loadNext(10, {
				onComplete: (error) => {
					if (!error) {
						setPaginationCount((prevCount) => prevCount + 10);
					}
				},
			});
		}
		setPaginationCount(paginationCount + 10);
	};

	const orders =
		data?.Viewer?.Billing?.ViewerOrders.edges?.map((edge) => edge?.node).filter(Boolean) ?? [];

	return (
		<div className={wrapperClass}>
			<EmptyPlaceholder
				isVisible={orders.length === 0}
				iconName="paragraphTwoColumn"
				title="Keine Rechnungen vorhanden"
				buttonIcon={<Icon icon={EpicIcons.REFRESH} color={shade0} />}
				buttonLabel="Neu laden"
				onClick={handleRefetch}
			>
				<ul className={listClass}>
					{orders.map((order) => (
						<InvoiceCard key={order.id} orderFragmentRef={order} />
					))}
				</ul>
				{hasNext && (
					<Button
						label="Mehr anzeigen"
						colorVersion="tertiary"
						onClick={handleShowMore}
					/>
				)}
			</EmptyPlaceholder>
		</div>
	);
};

export const InvoicesTab = withSuspense(InvoicesTabComponent, InvoicesTabSkeleton);
