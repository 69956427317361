import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { colorShade80Class } from "@themes/color-classes";
import { error100 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import { USER_FRAGMENT } from "./user-context-menu.graphql";
import {
	itemLabelSpanClass,
	itemWrapperClass,
	optionsWrapperClass,
	titleWrapperClass,
	wrapperClass,
} from "./user-context-menu.styles";
import { UserContextMenuProps, UserContextMenuRef } from "./user-context-menu.types";
import { RemoveMemberModal } from "../remove-member-modal";

// TODO: add-translations
export const UserContextMenu = forwardRef<UserContextMenuRef, UserContextMenuProps>(
	function UserContextMenu({ userFragmentRef }, ref) {
		const user = useFragment(USER_FRAGMENT, userFragmentRef ?? null);

		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

		const { isMediumUp } = useWindowSize();

		const [isVisibleRemoveMemberModal, setIsVisibleRemoveMemberModal] = useState(false);

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));

		const userName = user?.name ?? "";

		const handleRemoveUserOnClick = () => {
			overlayRef?.current?.hide();
			setIsVisibleRemoveMemberModal(true);
		};

		const hanldeRemoveMemberModalOnDismiss = () => {
			setIsVisibleRemoveMemberModal(false);
		};

		const handleCloseMenueOnClick = () => {
			overlayRef?.current?.hide();
		};

		const iconSizeRem = isMediumUp ? 1.5 : 1;

		return (
			<>
				<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
					<div className={wrapperClass}>
						{!isMediumUp && (
							<div className={titleWrapperClass}>
								<H1Span>Aktion wählen</H1Span>
								<P2Span className={colorShade80Class}>{userName}</P2Span>
							</div>
						)}
						<div className={optionsWrapperClass}>
							{!isMediumUp && <Divider />}
							<div className={itemWrapperClass} onClick={handleRemoveUserOnClick}>
								<Icon color={error100} icon={"trash"} sizeRem={iconSizeRem} />
								<span
									className={itemLabelSpanClass({
										color: "error",
									})}
								>
									Benutzer entfernen
								</span>
							</div>
						</div>
						{!isMediumUp && (
							<Button
								label="Schließen"
								fillParent
								onClick={handleCloseMenueOnClick}
							/>
						)}
					</div>
				</ResponsiveBottomSheetOverlayPanel>
				{user && (
					<RemoveMemberModal
						isVisible={isVisibleRemoveMemberModal}
						selectedMembers={[user]}
						onDismiss={hanldeRemoveMemberModalOnDismiss}
					/>
				)}
			</>
		);
	},
);
