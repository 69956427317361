import { Skeleton } from "@thekeytechnology/epic-ui";
import { wrapperClass } from "./navbar-item.styles";

export const NavbarItemSkeleton = () => {
	return (
		<div className={wrapperClass}>
			<Skeleton width="1rem" height="1rem" />
			<Skeleton width="3rem" />
		</div>
	);
};
