import * as Yup from "yup";

export const privateClientFormSchema = Yup.object().shape({
	salutation: Yup.string()
		.test("undefined", "Bitte wähle eine Anrede aus.", (value) => {
			return value != undefined;
		})
		.required("Bitte wähle eine Anrede aus."),
	title: Yup.string(),
	firstName: Yup.string().required("Bitte gib einen Vornamen ein."),
	lastName: Yup.string().required("Bitte gib einen Nachnamen ein."),
	street: Yup.string().required("Bitte gib eine Straße ein."),
	houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
	city: Yup.string().required("Bitte gib einen Ort ein."),
	postCode: Yup.string()
		.length(5, "Bitte gib eine Gültige Postleitzahl ein")
		.matches(/^[0-9]{5}/, "Bitte gib eine Gültige Postleitzahl ein")
		.required("Bitte gib eine Postleitzahl ein."),
	dateOfBirth: Yup.date()
		.required("Bitte gib ein Geburtsdatum ein.")
		.max(new Date(), "Bitte gib ein gültiges Geburtsdatum ein."),
	phoneNumber: Yup.string()
		.phone("DE", "Bitte gib eine gültige Telefonnummer ein.")
		.required("Bitte gib eine Telefonnummer ein."),
});
