import { graphql } from "react-relay";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment inlineAuthGuard_AuthViewerSchemaFragment on AuthViewerSchema {
		...accountSwitchMenu_AuthViewerSchemaFragment
		currentUser {
			accounts {
				id
			}
		}
	}
`;
