import { DialogTemplateProps } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { error100, success100 } from "@themes/colors";
import { IconHeadlineButtonTemplateProps } from "../icon-headline-button-template/icon-headline-button-template.types";

type LicenseModalAttributes = DialogTemplateProps & IconHeadlineButtonTemplateProps;

// TODO: add-translations

export const hasLicenseAttributes: LicenseModalAttributes = {
	secondaryButtonLabel: "Abbrechen",
	primaryButtonLabel: "Lizenz einlösen",
	primaryButtonIconName: "arrowRight",
	iconName: "certifiedCertificate",
	iconColor: success100,
	iconBackgroundColor: "success0",
	title: "Lizenz einlösen",
	subtitle:
		"Der Demo-Teil ist ab hier beendet, willst du deine Lizenz einlösen, um weiter zu machen?",
};

export const needsLicenseAttributes: LicenseModalAttributes = {
	secondaryButtonLabel: "Zurück zur Übersicht",
	primaryButtonLabel: "Lizenz zum Warenkorb hinzufügen",
	primaryButtonIconName: "shoppingBasket",
	iconName: "removeBadge",
	iconColor: error100,
	iconBackgroundColor: "error0",
	title: "Lizenz benötigt",
	subtitle:
		"Der Demo-Teil ist ab hier beendet, du benötigtst eine Lizenz, um weiter machen zu können.",
};
