import { selectIsLoggedIn } from "@thekeytechnology/academies-lib-webapp/slices";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { inlineAuthGuard_AuthViewerSchemaFragment$key } from "@relay/inlineAuthGuard_AuthViewerSchemaFragment.graphql";
import { LoginSteps } from "@screens/login/parts/login-steps";
import { SignUpSteps } from "@screens/sign-up/parts/sign-up-steps";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./inline-auth-guard.graphql";
import { centerClass, minWidthClass } from "./inline-auth-guard.styles";
import { AuthViews, InlineAuthControllerProps } from "./inline-auth-guard.types";
import { AccountSwitchMenu } from "../account-switch-menu/account-switch-menu.component";
import { ForgotPasswordSteps } from "../forgot-password-steps";

export const InlineAuthGuard = ({
	children,
	authViewerSchemaFragmentRef,
}: InlineAuthControllerProps) => {
	const auth = useFragment<inlineAuthGuard_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const [authView, setAuthView] = useState<AuthViews>("login");
	const [accountSelected, setAccountSelected] = useState(true);

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const handleOnGoToLogin = () => {
		setAuthView("login");
	};

	const handleOnGoToSignUp = () => {
		setAuthView("signup");
	};

	const handleOnGoToForgotPassword = () => {
		setAuthView("forgot-password");
	};

	const handleOnAccountSelected = () => {
		setAccountSelected(true);
	};

	useEffect(() => {
		if (!isLoggedIn) setAccountSelected(false);
	}, [isLoggedIn]);

	const accountsLoaded = auth.currentUser?.accounts !== undefined;
	const hasMoreThenOneAccount = accountsLoaded && auth.currentUser.accounts.length > 1;
	const authCompleted =
		isLoggedIn && (accountSelected || !hasMoreThenOneAccount) && accountsLoaded;
	const needToSelectAccount = isLoggedIn && !accountSelected && hasMoreThenOneAccount;
	const showLoading = !accountsLoaded && isLoggedIn;

	const renderAuthStep = needToSelectAccount ? (
		<AccountSwitchMenu
			hideSelectedAccount
			authViewerSchemaFragmentRef={auth}
			onAccountSelected={handleOnAccountSelected}
		/>
	) : showLoading ? null : authView === "login" ? (
		<LoginSteps
			inline
			onGoToSignUp={handleOnGoToSignUp}
			onGoToForgotPassword={handleOnGoToForgotPassword}
		/>
	) : authView === "signup" ? (
		<SignUpSteps inline onGoToLogin={handleOnGoToLogin} />
	) : (
		<ForgotPasswordSteps inline onGoToLogin={handleOnGoToLogin} />
	);

	const renderContent = authCompleted ? (
		children
	) : (
		<div className={centerClass}>
			<div className={minWidthClass}>{renderAuthStep}</div>
		</div>
	);

	return <>{renderContent}</>;
};
