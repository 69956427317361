import { css } from "@styled-system/css";
import { grid } from "@styled-system/patterns";

export const inputsWrapperClass = grid({
	gridTemplateColumns: "12",
	columnGap: "16",
	rowGap: "24",
	lg: {
		columnGap: "32",
	},
});

export const rowSpan6WrapperClass = grid({
	gridTemplateColumns: "12",
	gridColumn: "12",
	rowGap: "24",
	md: {
		gap: "16",
	},
	lg: {
		gridTemplateColumns: "6",
		gridColumn: "6",
	},
});

export const rowSpan6Class = css({
	gridColumn: "12",
	md: {
		gridColumn: "6",
	},
});

export const rowSpan4Class = css({
	gridColumn: "12",
	md: {
		gridColumn: "6",
	},
	lg: {
		gridColumn: "4",
	},
});

export const rowSpan2Class = css({
	gridColumn: "12",
	md: {
		gridColumn: "6",
	},
	lg: {
		gridColumn: "2",
	},
});

export const rowSpan6PlaceholderClass = css({
	display: "none",
	lg: {
		display: "block",
		gridColumn: "6",
	},
});
