import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { selectHasPermission } from "@thekeytechnology/academies-lib-webapp/slices";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { accountContextMenu_AuthViewerSchemaFragment$key } from "@relay/accountContextMenu_AuthViewerSchemaFragment.graphql";
import { error100 } from "@themes/colors";
import { spacing8 } from "@themes/spacing";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./account-context-menu.graphql";
import { wrapperClass, optionsWrapperClass, itemWrapperClass } from "./account-context-menu.styles";
import {
	type AccountContextMenuProps,
	type AccountContextMenuRef,
} from "./account-context-menu.types";
import { DeleteAccountModalsFlow } from "../delete-account-modals-flow";
import { LeaveAccountModal } from "../leave-account-modal/leave-account-modal.component";

export const AccountContextMenu = forwardRef<AccountContextMenuRef, AccountContextMenuProps>(
	function AccountContextMenu({ authViewerSchemaFragmentRef }, ref) {
		const authFragment = useFragment<accountContextMenu_AuthViewerSchemaFragment$key>(
			AUTH_VIEWER_SCHEMA_FRAGMENT,
			authViewerSchemaFragmentRef,
		);
		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
		const [showLeaveAccountModal, setShowLeaveAccountModal] = useState(false);
		const [showDeleteAccountFlow, setShowDeleteAccountFlow] = useState(false);

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));

		const handleOnLeaveAccountClick = () => {
			overlayRef?.current?.hide();
			setShowLeaveAccountModal(true);
		};

		const handleOnDeleteAccountClick = () => {
			overlayRef?.current?.hide();
			setShowDeleteAccountFlow(true);
		};

		const isManager = useSelector((state) =>
			selectHasPermission(state, "UserInAccountPermission_System_Owner"),
		);

		const canLeaveAccount = true;
		const canDeleteAccount = isManager;

		return (
			<>
				<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing8}>
					<div className={wrapperClass}>
						<div className={optionsWrapperClass}>
							{canLeaveAccount && (
								<div
									onClick={handleOnLeaveAccountClick}
									className={itemWrapperClass()}
								>
									<Icon icon="logout" sizeInRem={1.5} />
									Konto verlassen
								</div>
							)}
							{canDeleteAccount && (
								<div
									onClick={handleOnDeleteAccountClick}
									className={itemWrapperClass({ dangerous: true })}
								>
									<Icon icon="trash" sizeInRem={1.5} color={error100} />
									Konto löschen
								</div>
							)}
						</div>
					</div>
				</ResponsiveBottomSheetOverlayPanel>
				{showLeaveAccountModal && (
					<LeaveAccountModal
						isVisible
						onDismiss={() => setShowLeaveAccountModal(false)}
						onSuccess={() => setShowLeaveAccountModal(false)}
						authViewerSchemaFragmentRef={authFragment}
					/>
				)}
				{showDeleteAccountFlow && (
					<DeleteAccountModalsFlow
						isVisible
						onDismiss={() => setShowDeleteAccountFlow(false)}
						authViewerSchemaFragmentRef={authFragment}
					/>
				)}
			</>
		);
	},
);
