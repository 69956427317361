import { type Color } from "@thekeytechnology/epic-ui";
import { type RadioButtonChangeEvent } from "primereact/radiobutton";

export type RadioButtonProps = {
	checked?: boolean;
	value?: any;
	onChange?: (event: RadioButtonChangeEvent) => void;
	status?: RadioButtonStatus;
	icon?: JSX.Element;
	iconBackgroundColor?: Color;
};

export enum RadioButtonStatus {
	Default = "default",
	Disabled = "disabled",
	Error = "error",
}
