import { graphql } from "react-relay";

export const LEVEL_DEFINITION_FRAGMENT = graphql`
	fragment levelItem_LevelDefinitionFragment on LevelDefinition {
		id
		title
		text
		requiredPoints
		shortDescription
		image {
			id
			name
			url
		}
	}
`;
