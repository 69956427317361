import { usePermissions } from "@thekeytechnology/academies-lib-webapp/hooks/use-permissions";
import lodash from "lodash";
import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { TabBar } from "@components/tab-bar";
import { useCurrentPath } from "@hooks/use-current-path";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { account_Query } from "@relay/account_Query.graphql";
import { Path } from "@router/paths";
import { AccountContext } from "./account.context";
import { QUERY } from "./account.graphql";
import { useNavigateToTab, useTabIndexFromPath } from "./account.hooks";
import { tabPanelClass, tabPanelContainerClass, wrapperClass } from "./account.styles";
import { AccountHeader } from "./parts/account-header";
import { InvoicesTab } from "./parts/invoices-tab";
import { LicensesTab } from "./parts/licenses-tab";
import { MembersTab } from "./parts/members-tab";
import { FilterContextProvider } from "./parts/members-tab/parts/members-filter/members-filter.context";

export const AccountScreen = () => {
	const query = useLazyLoadQuery<account_Query>(QUERY, {}, { fetchPolicy: "store-and-network" });

	const { isAccountOwner } = usePermissions();
	const currentPath = useCurrentPath();
	const tabIndex = useTabIndexFromPath();
	const navigate = useNavigateToTab();

	const [membersConnectionIds, setMembersConnectionIds] = useState<string[]>([]);
	const [groupsConnectionIds, setGroupsConnectionIds] = useState<string[]>([]);

	const isBasePath = currentPath?.route.path === Path.profileMenu.account.path;
	useEffect(() => {
		if (isBasePath) {
			navigate(0);
		}
	}, [isBasePath]);

	const handleAddGroupConnectionId = (id: string) => {
		setGroupsConnectionIds(lodash.uniq([...groupsConnectionIds, id]));
	};

	const handleAddMembersConnectionId = (id: string) => {
		setMembersConnectionIds(lodash.uniq([...membersConnectionIds, id]));
	};

	const handleTabOnChange = (event: TabViewTabChangeEvent) => {
		navigate(event.index);
	};

	return (
		<AccountContext.Provider
			value={{
				membersConnectionIds,
				addMembersConnectionId: handleAddMembersConnectionId,
				groupsConnectionIds,
				addGroupsConnectionId: handleAddGroupConnectionId,
			}}
		>
			<FilterContextProvider>
				<ScreenWithProfileMenuLayout>
					<div className={wrapperClass}>
						<AccountHeader queryFragmentRef={query} />
						<TabBar
							className={tabPanelContainerClass}
							panelContainerClassName={tabPanelContainerClass}
							activeIndex={tabIndex}
							onTabChange={handleTabOnChange}
						>
							<TabPanel
								header="Mitglieder"
								disabled={!isAccountOwner}
								className={tabPanelClass}
							>
								<MembersTab
									accountMembersQueryFragmentRef={query}
									userInAccountGroupQueryFragmentRef={query}
								/>
							</TabPanel>
							<TabPanel
								header="Lizenzen"
								disabled={!isAccountOwner}
								className={tabPanelClass}
							>
								<LicensesTab />
							</TabPanel>
							<TabPanel
								header="Rechnungen"
								disabled={!isAccountOwner}
								className={tabPanelClass}
							>
								<InvoicesTab />
							</TabPanel>
						</TabBar>
					</div>
				</ScreenWithProfileMenuLayout>
			</FilterContextProvider>
		</AccountContext.Provider>
	);
};
