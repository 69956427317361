import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment invoiceCard_OrderFragment on Order {
		id
		invoiceData {
			... on CreatedInvoiceData {
				invoiceId
				invoiceNumber
				invoiceFile {
					id
					uploadDateTime
					url
				}
			}
		}
	}
`;
