import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { logout } from "@thekeytechnology/academies-lib-webapp/slices";
import { type FC } from "react";
import { useDispatch } from "react-redux";
import { H1Span, P2Span } from "@themes/font-tags";
import { type SuccessDeleteAccountModalProps } from "./success-delete-account-modal.types";
import { wrapperClass } from "../confirm-delete-account-modal/confirm-delete-account-modal.styles";

export const SuccessDeleteAccountModal: FC<SuccessDeleteAccountModalProps> = ({
	isVisible,
	onDismiss,
	accountName,
}) => {
	const dispatch = useDispatch();
	const handleOnDismiss = () => {
		dispatch(logout());
		onDismiss();
		window.location.href = "/";
		window.location.reload();
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				primaryButtonLabel="Okay"
				onPrimaryButtonClick={handleOnDismiss}
				primaryButtonStretch={true}
			>
				<div className={wrapperClass}>
					<H1Span>Konto gelöscht</H1Span>
					<P2Span>
						Dein Konto “{accountName}” wurde erfolgreich gelöscht und kann nicht
						wiederhergestellt werden.
					</P2Span>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
