import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { Button, ButtonType } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { useToast } from "@hooks/useToast";
import { type signUpConfirmEmailStep_ResendActivationMutation } from "@relay/signUpConfirmEmailStep_ResendActivationMutation.graphql";
import { colorSecondary100Class } from "@themes/color-classes";
import { H1Span, P1Span, P2Span } from "@themes/font-tags";
import { RESEND_ACTIVATION_MUTATION } from "./sign-up-confirm-email-step.graphql";
import { Fill, TextCenter } from "./sign-up-confirm-email-step.styles";
import { type SignUpConfirmEmailStepProps } from "./sign-up-confirm-email-step.types";

export const SignUpConfirmEmailStep = ({
	email,
	showLoginButton,
	onGoToLogin,
}: SignUpConfirmEmailStepProps) => {
	const [resendActivation] = useMutation<signUpConfirmEmailStep_ResendActivationMutation>(
		RESEND_ACTIVATION_MUTATION,
	);

	const { t } = useTkaTranslation(["successfullyRegisteredScreen", "activateScreen"]);
	const { showSuccess } = useToast();

	const handleResentEmailOnClick = () => {
		resendActivation({
			variables: {
				input: {
					email,
				},
			},
			onCompleted: () => {
				showSuccess({
					summary: t(
						"successfullyRegisteredScreen:successfully_registered_screen.emailSuccessfullySentToast",
					),
				});
			},
		});
	};

	return (
		<TextCenter>
			<H1Span>
				{t("successfullyRegisteredScreen:successfully_registered_screen.title")}
			</H1Span>
			<P1Span>{t("successfullyRegisteredScreen:successfully_registered_screen.body")}</P1Span>
			<P2Span>
				{t(
					"successfullyRegisteredScreen:successfully_registered_screen.resendActivationTextPart1",
				)}
				<P2Span className={colorSecondary100Class} onClick={handleResentEmailOnClick}>
					{t(
						"successfullyRegisteredScreen:successfully_registered_screen.resendActivationLinkText",
					)}
				</P2Span>
				{t(
					"successfullyRegisteredScreen:successfully_registered_screen.resendActivationTextPart2",
				)}
			</P2Span>
			{showLoginButton && (
				<Fill>
					<Button
						type={ButtonType.Submit}
						label={t("activateScreen:activate_screen.successButtonText")}
						icon="arrowRight"
						onClick={onGoToLogin}
					/>
				</Fill>
			)}
		</TextCenter>
	);
};
