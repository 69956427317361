import styled from "styled-components";

export const TextCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: ${({ theme }) => theme.spacing.spacing32};
`;

export const Fill = styled.div`
	align-self: stretch;
`;
