import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Chips, ChipsChangeEvent } from "primereact/chips";
import { cx } from "@styled-system/css";
import { shade10, shade100, shade60 } from "@themes/colors";
import { L1Span, P3Label } from "@themes/font-tags";
import {
	chipsClass,
	chipsWrapperClass,
	closeChipWrapperClass,
	growClass,
	iconWrapperClass,
	labelClass,
	smallTextBelowClass,
} from "./input-chips.styles";
import { InputChipsProps } from "./input-chips.types";

export const InputChips = ({
	label,
	name,
	icon,
	iconSizeInRem = 1.5,
	value = [],
	placeholder,
	allowDuplicates,
	addOnBlur,
	onChange,
	smallTextBelow,
	hasError,
}: InputChipsProps) => {
	const handleOnChange = (event: ChipsChangeEvent) => {
		onChange?.(event.value ?? []);
	};

	const iconColor = value?.length ? shade100 : shade60;

	return (
		<div className={chipsWrapperClass}>
			<P3Label className={labelClass}>{label}</P3Label>
			<div className={growClass}>
				<div className={iconWrapperClass}>
					<Icon color={iconColor} icon={icon} sizeRem={iconSizeInRem} />
				</div>
				<Chips
					value={value}
					name={name}
					onChange={handleOnChange}
					placeholder={placeholder}
					itemTemplate={(label) => <L1Span>{label}</L1Span>}
					removeIcon={({ iconProps: { className, ...iconProps } }) => (
						<div
							className={cx(closeChipWrapperClass, className)}
							{...(iconProps as React.HTMLProps<HTMLDivElement>)}
						>
							<Icon icon="close" color={shade10} sizeRem={0.5} />
						</div>
					)}
					allowDuplicate={allowDuplicates}
					addOnBlur={addOnBlur}
					className={chipsClass}
				/>
			</div>
			{smallTextBelow && (
				<L1Span
					className={smallTextBelowClass({
						hasError,
					})}
				>
					{smallTextBelow}
				</L1Span>
			)}
		</div>
	);
};
