/**
 * @generated SignedSource<<ffa13fbcfb84296e3ac155772c13a6c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IHKCertificateOrderStateKind = "ErrorOccurred" | "NotIHK" | "Ordered" | "ReadyToOrder" | "RewardNotAchievedYet";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type rootOverview_GetRootOverviewQuery$variables = {
  id: string;
};
export type rootOverview_GetRootOverviewQuery$data = {
  readonly node: {
    readonly description?: string | null;
    readonly id?: string;
    readonly instructorsV2?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"instructorsComponent_InstructorsConnectionFragment">;
    };
    readonly structureDefinition?: {
      readonly extension?: {
        readonly ihkState?: IHKCertificateOrderStateKind;
        readonly learnGoals?: string | null;
        readonly licenseAvailability?: {
          readonly numAvailable?: number;
        };
        readonly product?: {
          readonly id: string;
        } | null;
        readonly reducedData?: {
          readonly isIHK: boolean;
          readonly receivableGamificationPoints: number;
        } | null;
        readonly trailer?: {
          readonly url: string | null;
        } | null;
        readonly unlockInfo?: {
          readonly kind: UnlockInfoKind;
        };
      };
      readonly title: string;
    };
  } | null;
};
export type rootOverview_GetRootOverviewQuery = {
  response: rootOverview_GetRootOverviewQuery$data;
  variables: rootOverview_GetRootOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "learnGoals",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ReducedData",
  "kind": "LinkedField",
  "name": "reducedData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivableGamificationPoints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isIHK",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numAvailable",
      "storageKey": null
    }
  ],
  "type": "LicenseAvailabilityFreeLicensesAvailable",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ihkState",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = [
  (v6/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "rootOverview_GetRootOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InstructorsConnection",
                "kind": "LinkedField",
                "name": "instructorsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "instructorsComponent_InstructorsConnectionFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "structureDefinition",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StreamFile",
                                "kind": "LinkedField",
                                "name": "trailer",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "unlockInfo",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "licenseAvailability",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "type": "PublishedRootExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "LearnOpportunityRootStructureDefinition",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LearnOpportunityV2",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "rootOverview_GetRootOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InstructorsConnection",
                "kind": "LinkedField",
                "name": "instructorsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v2/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isInstructor"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "shortDescription",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "position",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "InstructorImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "structureDefinition",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StreamFile",
                                "kind": "LinkedField",
                                "name": "trailer",
                                "plural": false,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "unlockInfo",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "licenseAvailability",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "type": "PublishedRootExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "LearnOpportunityRootStructureDefinition",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LearnOpportunityV2",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "258e5854972aa0963dcefcbf000f3aad",
    "id": null,
    "metadata": {},
    "name": "rootOverview_GetRootOverviewQuery",
    "operationKind": "query",
    "text": "query rootOverview_GetRootOverviewQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on LearnOpportunityV2 {\n      id\n      description\n      instructorsV2 {\n        edges {\n          node {\n            __typename\n            id\n          }\n        }\n        ...instructorsComponent_InstructorsConnectionFragment\n      }\n      structureDefinition {\n        __typename\n        title\n        ... on LearnOpportunityRootStructureDefinition {\n          extension {\n            __typename\n            ... on PublishedRootExtensionImpl {\n              trailer {\n                url\n                id\n              }\n              learnGoals\n              reducedData {\n                receivableGamificationPoints\n                isIHK\n              }\n              unlockInfo {\n                __typename\n                kind\n              }\n              licenseAvailability {\n                __typename\n                ... on LicenseAvailabilityFreeLicensesAvailable {\n                  numAvailable\n                }\n              }\n              product {\n                id\n              }\n              ihkState\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment instructorCard_InstructorFragment on Instructor {\n  __isInstructor: __typename\n  name\n  ... on InstructorImpl {\n    description\n    shortDescription\n    position\n    image {\n      url\n      id\n    }\n  }\n}\n\nfragment instructorsComponent_InstructorsConnectionFragment on InstructorsConnection {\n  edges {\n    node {\n      __typename\n      id\n      ...instructorCard_InstructorFragment\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "def2563610dfaf32be606d2c765f94b3";

export default node;
