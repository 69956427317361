import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { LabelSkeleton } from "@thekeytechnology/academies-lib-webapp/components/label";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { IMAGE_WIDTH } from "./licenses-group-card.consts";
import {
	cardImageClass,
	cardWrapperClass,
	contentClass,
	titleSubtitleWrapperClass,
	wrapperClass,
} from "./licenses-group-card.styles";

export const LicensesGroupCardSkeleton = () => {
	return (
		<div className={cardWrapperClass}>
			<Card fillParent bodyPaddingInRem={0.75} hasOverflowHidden>
				<div className={wrapperClass}>
					<Skeleton width={IMAGE_WIDTH} height="100%" className={cardImageClass} />
					<div className={contentClass}>
						<div className={titleSubtitleWrapperClass}>
							<Skeleton width="6rem" />
							<Skeleton width="10rem" />
						</div>
					</div>
					<LabelSkeleton />
				</div>
			</Card>
		</div>
	);
};
