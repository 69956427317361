import { LabelSkeleton } from "@thekeytechnology/academies-lib-webapp/components/label";
import { ProgressBar } from "@thekeytechnology/academies-lib-webapp/components/progress-bar";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { ImageWidth, ProgressUnit } from "./root-progress.const";
import { imageTitleClass, mainWrapperClass, taglineTitleClass } from "./root-progress.styles";

export const RootProgressSkeleton = () => {
	const progressValue = 0;

	return (
		<div className={mainWrapperClass}>
			<div className={imageTitleClass}>
				<div>
					<Skeleton width={ImageWidth} height="100%" />
				</div>
				<div className={taglineTitleClass}>
					<LabelSkeleton />
					<Skeleton width="10rem" />
				</div>
			</div>
			<ProgressBar
				value={progressValue}
				colorVersion={"profile"}
				labelRight={`${progressValue} ${ProgressUnit}`}
			/>
		</div>
	);
};
