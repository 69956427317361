import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const timelineHeaderWrapperClass = css({
	p: "24",
	pt: "12",
});

export const sideMenuContentWrapperClass = flex({
	direction: "column",
	height: "full",
	gap: "24",
});

export const accountWrapperClass = flex({
	direction: "column",
	flex: "1",
	justify: "flex-end",
	mb: "32",
});

export const skeletonCardsWrapperClass = flex({
	flexDirection: "column",
	mt: "24",
	gap: "16",
});
