import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment paypalButton_OrderFragment on Order {
		id
		cart {
			totals {
				includingAllDiscounts {
					grossPrice
				}
			}
		}
		purchaser {
			userId
		}
	}
`;

export const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
	mutation paypalButton_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
		Billing {
			changePaymentMethod(input: $input) {
				order {
					...shoppingCartOverview_OrderFragment
				}
			}
		}
	}
`;
