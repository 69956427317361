import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { InputText } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { NotesScreenContext } from "../../notes.context";

export const SearchBarMobile = () => {
	const { t } = useTkaTranslation("offers");
	const placeholder = t("Suchen");
	const {
		filters: { text: value },
		helpers: { setText },
	} = useContext(NotesScreenContext);

	const handleOnChange = (e?: string) => {
		setText(e);
	};

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
		/>
	);
};
