import { Footer } from "@thekeytechnology/academies-lib-webapp/components/footer";
import { ReportModalFlow } from "@thekeytechnology/academies-lib-webapp/components/report-modal-flow";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useState } from "react";
import { useTheme } from "styled-components";
import WebappTeaserPNG from "@assets/auth_image.png";
import {
	borderWrapperClass,
	headerClass,
	headerContentWrapperClass,
	leftContentClass,
	webappTeaserClass,
	wrapperClass,
} from "./auth-template.styles";
import { type FooterItem, type AuthTemplateProps } from "./auth-template.types";
import { AGB_URL, DATA_PROTECTION_URL, IMPRINT_URL } from "../../consts";

export const AuthTemplate = ({ hideImage, rightSide, children }: AuthTemplateProps) => {
	const theme = useTheme();
	const { t } = useTkaTranslation("authLayout");
	const [isShowingCreateReportFlow, setIsShowingCreateReportFlow] = useState(false);
	const handleReportOnClick = () => {
		setIsShowingCreateReportFlow(true);
	};
	const handleReportModalFlowDismissOnClick = () => {
		setIsShowingCreateReportFlow(false);
	};
	const footerItems: FooterItem[] = [
		{
			label: t("auth_layout.footerImprint"),
			link: IMPRINT_URL,
		},
		{ label: t("auth_layout.footerReport"), onClick: handleReportOnClick },
		{
			label: t("auth_layout.footerPrivacy"),
			link: DATA_PROTECTION_URL,
		},
		{
			label: t("auth_layout.footerAgb"),
			link: AGB_URL,
		},
	];

	return (
		<>
			<ReportModalFlow
				isVisible={isShowingCreateReportFlow}
				onDismiss={handleReportModalFlowDismissOnClick}
			/>
			<div
				className={wrapperClass({
					hideImage,
				})}
			>
				<div
					className={leftContentClass({
						hideImage,
					})}
				>
					<div className={headerContentWrapperClass({ hideImage })}>
						<div className={borderWrapperClass({ hideImage })}>
							<div className={headerClass}>
								<theme.LogoComponent />
							</div>
							{children}
						</div>
					</div>
					<Footer items={footerItems} />
				</div>
				{!hideImage && !rightSide && (
					<div
						className={webappTeaserClass}
						style={{ backgroundImage: `url(${WebappTeaserPNG})` }}
					/>
				)}
				{rightSide && rightSide}
			</div>
		</>
	);
};
