import {
	DialogTemplate,
	DialogButtonsDirection,
} from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { FC } from "react";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { success100 } from "@themes/colors";
import { P2Span } from "@themes/font-tags";
import { SuccessModalProps, SuccessfulInvite } from "./success-invite.types";
import { getSuccessContent } from "./success-invite.utils";

export const SuccessInviteComponent: FC<SuccessModalProps> = ({
	isVisible,
	inviteMembersResponse,
	onDismiss,
}) => {
	let numberOfNewAccountMembers = 0;
	let numberOfUpdatedAccountMembers = 0;

	if (
		inviteMembersResponse &&
		"newAccountMembers" in inviteMembersResponse &&
		"updatedAccountMembers" in inviteMembersResponse
	) {
		numberOfNewAccountMembers = inviteMembersResponse.newAccountMembers?.length || 0;
		numberOfUpdatedAccountMembers = inviteMembersResponse.updatedAccountMembers?.length || 0;
	} else if (
		inviteMembersResponse?.data &&
		("newInvitationCount" in inviteMembersResponse.data ||
			"groupUpdateUsersInAccountCount" in inviteMembersResponse.data)
	) {
		numberOfNewAccountMembers = inviteMembersResponse.data.newInvitationCount || 0;
		numberOfUpdatedAccountMembers =
			inviteMembersResponse.data.groupUpdateUsersInAccountCount || 0;
	}

	const successContentType =
		numberOfUpdatedAccountMembers > 0
			? SuccessfulInvite.INVITEUSERSWITHGROUP
			: SuccessfulInvite.INVITEUSERS;

	const successContent = getSuccessContent(
		successContentType,
		numberOfNewAccountMembers,
		numberOfUpdatedAccountMembers,
	);

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible}>
			<DialogTemplate
				buttonsDirection={DialogButtonsDirection.COLUMN}
				primaryButtonLabel="Okay"
				primaryButtonStretch
				onPrimaryButtonClick={onDismiss}
			>
				<IconHeadlineButtonTemplate
					iconName="checkCircle"
					iconColor={success100}
					iconBackgroundColor="success0"
					title="Einladungen versendet"
					subtitle={successContent.subtitle}
				/>

				<P2Span>{successContent.infotext}</P2Span>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
