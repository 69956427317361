import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useToast } from "@hooks/useToast";
import { colorSecondary100Class } from "@themes/color-classes";
import { H1Span, P1Span, P2Span } from "@themes/font-tags";
import { PasswordForgotEmailSendStepProps } from "./password-forgot-email-send-step.types";
import { textCenterClass } from "./password-forgot-email-send-step.styles";

export const PasswordForgotEmailSendStep = ({ email }: PasswordForgotEmailSendStepProps) => {
	const { t } = useTkaTranslation("forgotPasswordForm");
	const { showSuccess } = useToast();
	const { forgotPassword } = useAuthContext();

	const handleResentEmailOnClick = async () => {
		await forgotPassword(email);
		showSuccess({
			summary: t("forgot_password_form.emailSuccessfullySentToast"),
		});
	};

	return (
		<div className={textCenterClass}>
			<H1Span>{t("forgot_password_form.successTitle")}</H1Span>
			<P1Span>{t("forgot_password_form.successBody1")}</P1Span>
			<P2Span>
				{t("forgot_password_form.successBody2Part1")}
				<P2Span className={colorSecondary100Class} onClick={handleResentEmailOnClick}>
					{t("forgot_password_form.successBody2LinkText")}
				</P2Span>
				{t("forgot_password_form.successBody2Part2")}
			</P2Span>
		</div>
	);
};
