import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useDebounce } from "@thekeytechnology/academies-lib-webapp/hooks/use-debounce";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useRef, useState } from "react";
import { useFragment } from "react-relay";
import { MembersTabContext } from "./members-tab.context";
import { USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT } from "./members-tab.graphql";
import { MembersTabSkeleton } from "./members-tab.skeleton";
import { bottomBatchEditSpacerClass, wrapperClass } from "./members-tab.styles";
import { MembersTabProps, SelectedMember } from "./members-tab.types";
import { BatchEditMembers } from "./parts/batch-edit-members";
import { Header } from "./parts/header/header.component";
import { InviteMemberModal } from "./parts/invite-member-modal";
import { MembersList } from "./parts/members-list/members-list.component";
import { MembersListRef } from "./parts/members-list/members-list.types";
import { RefetchContext } from "../refetch-context/refetch.context";

export const MembersTabComponent = ({
	accountMembersQueryFragmentRef,
	userInAccountGroupQueryFragmentRef,
}: MembersTabProps) => {
	const userInAccountGroupQueryFragment = useFragment(
		USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT,
		userInAccountGroupQueryFragmentRef ?? null,
	);

	const [selectedMembers, setSelectedMembers] = useState<SelectedMember[]>([]);
	const [inviteMemberModalIsVisible, setInviteMemberModalIsVisible] = useState(false);
	const [searchIsVisibleOnMobile, setSearchIsVisibleOnMobile] = useState(false);
	const [searchByNameOrEmail, setSearchByNameOrEmail] = useState<string>("");

	const [numberOfConfirmedMembers, setNumberOfConfirmedMembers] = useState<number>(0);
	const [numberOfUnconfirmedMembers, setNumberOfUnconfirmedMembers] = useState<number>(0);

	const ref = useRef<MembersListRef>(null);
	const { isMediumUp } = useWindowSize();
	const debouncedSearchValue = useDebounce(searchByNameOrEmail, 500);

	const handleOnDismissInvite = () => {
		setInviteMemberModalIsVisible(false);
	};

	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		setSearchIsVisibleOnMobile(!searchIsVisibleOnMobile);
	};

	const handleToggleMember = (member: SelectedMember) => {
		const memberIndex = selectedMembers.findIndex(
			(selectedMember) => selectedMember.id === member.id,
		);
		if (memberIndex !== -1) {
			const copy = selectedMembers.slice();
			copy.splice(memberIndex, 1);
			setSelectedMembers(copy);
		} else {
			setSelectedMembers([...selectedMembers, member]);
		}
	};

	const handleClearSelectedMembers = () => {
		setSelectedMembers([]);
	};

	const handleSearchOnChange = (text: string) => {
		setSearchByNameOrEmail(text);
	};

	const handleInviteOnClick = () => {
		setInviteMemberModalIsVisible(true);
	};

	const handleRefetch = () => {
		ref.current?.refetch();
	};

	const isBatchEditVisible = selectedMembers.length > 1;

	return (
		<RefetchContext.Provider
			value={{
				refetch: handleRefetch,
			}}
		>
			<MembersTabContext.Provider
				value={{
					selectedMembers,
					toogleMember: handleToggleMember,
					clearSelectedMembers: handleClearSelectedMembers,
					numberOfConfirmedMembers,
					numberOfUnconfirmedMembers,
					setNumberOfConfirmedMembers,
					setNumberOfUnconfirmedMembers,
				}}
			>
				<div className={wrapperClass}>
					<Header
						searchIsVisibleOnMobile={searchIsVisibleOnMobile}
						handleToggleOnClick={handleToggleOnClick}
						handleSearchOnChange={handleSearchOnChange}
						handleInviteOnClick={handleInviteOnClick}
						searchByNameOrEmail={searchByNameOrEmail}
						userInAccountGroupQueryFragment={userInAccountGroupQueryFragment}
						accountMembersQueryFragment={accountMembersQueryFragmentRef}
					/>

					<BatchEditMembers />
					<MembersList
						selectedMembers={selectedMembers}
						handleToggleMember={handleToggleMember}
						debouncedSearchValue={debouncedSearchValue}
						ref={ref}
					/>
					<InviteMemberModal
						isVisible={inviteMemberModalIsVisible}
						onDismiss={handleOnDismissInvite}
						selectedUsers={selectedMembers}
						queryFragmentRef={userInAccountGroupQueryFragment}
					/>
					{isBatchEditVisible && <div className={bottomBatchEditSpacerClass} />}
				</div>
			</MembersTabContext.Provider>
		</RefetchContext.Provider>
	);
};

export const MembersTab = withSuspense(MembersTabComponent, MembersTabSkeleton);
