import { createContext } from "react";
import { NoteColor } from "@relay/notesTabbar_NotesQuery.graphql";

export type INotesScreenContext = {
	activeIndex: number;
	filtersAreVisible: boolean;
	searchIsVisibleOnMobile: boolean;
	refetchers: (() => void)[];
	filters: {
		first?: number;
		last?: number;
		after?: string;
		before?: string;
		colors: NoteColor[];
		text?: string;
	};
	helpers: {
		setActiveIndex: (index: number) => void;
		registerRefetch: (refetch: () => void) => number;
		unregisterRefetch: (id: number) => void;
		setText: (text?: string) => void;
		setColors: (colors: NoteColor[]) => void;
		toggleColor: (color: NoteColor) => void;
		toggleFiltersAreVisible: () => void;
		toggleSearchIsVisibleOnMobile: () => void;
	};
};
export const NotesScreenContext = createContext<INotesScreenContext>({
	activeIndex: 0,
	filtersAreVisible: false,
	searchIsVisibleOnMobile: false,
	refetchers: [],
	filters: {
		colors: ["default", "blue", "green", "red", "yellow"],
		text: "",
	},
	helpers: {
		setActiveIndex: () => {},
		registerRefetch: () => {
			return -1;
		},
		unregisterRefetch: () => {},
		setText: (_) => {},
		setColors: () => {},
		toggleColor: () => {},
		toggleFiltersAreVisible: () => {},
		toggleSearchIsVisibleOnMobile: () => {},
	},
});
