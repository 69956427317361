import { z } from "zod";

import { orderIhkModal_BaseDataFragment$key } from "@relay/orderIhkModal_BaseDataFragment.graphql";

export enum SalutationType {
	Divers = "Divers",
	Mr = "Mr",
	Mrs = "Mrs",
}

export type SalutationTypeOptionsRecord = Record<SalutationType, string>;

export enum TitleType {
	Dr = "Dr",
	Prof = "Prof",
}

export type TitleTypeOptionsRecord = Record<TitleType, string>;

export const OrderIHKModalFormSchema = z.object({
	salutation: z.nativeEnum(SalutationType, {
		required_error: "Bitte wähle eine Anrede aus.",
	}),
	title: z
		.nativeEnum(TitleType, {
			required_error: "Bitte wähle einen Titel aus.",
		})
		.optional(),
	firstName: z.string({
		required_error: "Bitte gib einen Vornamen ein.",
	}),
	lastName: z.string({
		required_error: "Bitte gib einen Nachnamen ein.",
	}),
	street: z.string({
		required_error: "Bitte gib eine Straße ein.",
	}),
	houseNumber: z.string({
		required_error: "Bitte gib eine Hausnummer ein.",
	}),
	postCode: z
		.string({
			required_error: "Bitte gib eine Postleitzahl ein.",
		})
		.length(5, {
			message: "Bitte gib eine Gültige Postleitzahl ein",
		})
		.regex(/^[0-9]{5}/, {
			message: "Bitte gib eine Gültige Postleitzahl ein",
		}),
	city: z.string({
		required_error: "Bitte gib einen Ort ein.",
	}),
	country: z.string({
		required_error: "Bitte gib ein Land ein.",
	}),
	dateOfBirth: z.date({
		required_error: "Bitte gib ein Geburtsdatum ein.",
	}),
});

export type OrderIHKModalProps = {
	rootId: string;
	isVisible?: boolean;
	onOrdered?: () => void;
	onDismiss?: () => void;
	baseDataFragmentRef?: orderIhkModal_BaseDataFragment$key | null;
};

export type OrderIHKModalFormState = {
	salutation?: string;
	title?: string;
	firstName: string;
	lastName: string;
	street: string;
	houseNumber: string;
	city: string;
	postCode: string;
	dateOfBirth?: Date;
	country: string;
};

export type OrderIHKModalFormValues = Override<
	OrderIHKModalFormState,
	{
		dateOfBirth: Date;
		salutation: string;
	}
>;
