import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment accountHeader_QueryFragment on Query
	@refetchable(queryName: "accountHeader_QueryFragmentRefetch") {
		Viewer {
			Auth {
				currentUser {
					accounts {
						groupAssociations {
							group {
								name
							}
						}
						name
						id
						extension(accountExtensionKind: Tka) {
							... on TkaAccountExtension {
								users {
									edges {
										node {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
