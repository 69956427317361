import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	pr: "12",
	md: {
		width: "[25rem]",
	},
});

export const inviteFormWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "12",
	paddingTop: "4",
	paddingBottom: "12",
	md: {
		minWidth: "[25rem]",
		flexWrap: "wrap",
	},
});
