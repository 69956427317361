import { graphql } from "react-relay";

export const ORDER_FRAGMENT = graphql`
	fragment basketTimeline_OrderFragment on Order {
		id
		cart {
			totals {
				includingAllDiscounts {
					grossPrice
				}
			}
		}
		customerDetails {
			data {
				kind
			}
		}
		selectedPaymentMethod {
			kind
			... on MonthlyPaymentMethod {
				chosenOption {
					rateCount
				}
			}
		}
	}
`;
