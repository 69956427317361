import { BranchNodesTreeSkeleton } from "@thekeytechnology/academies-lib-webapp/components/branch-nodes-tree";
import { tabWrapperClass } from "./branch-nodes-tab.styles";

export const BranchNodeTabSkeleton = () => {
	return (
		<div className={tabWrapperClass}>
			<BranchNodesTreeSkeleton />
		</div>
	);
};
