import { selectCurrentUserId } from "@thekeytechnology/academies-lib-webapp";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { FC, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { BatchEdit } from "@screens/account/parts/batch-edit";
import { colorError100Class, colorShade80Class } from "@themes/color-classes";
import { shade80, error100 } from "@themes/colors";
import { P2Span } from "@themes/font-tags";
import { BatchEditMembersProps } from "./batch-edit-members.types";
import { MembersTabContext } from "../../members-tab.context";
import { RemoveMemberModal } from "../remove-member-modal";

export const BatchEditMembers: FC<BatchEditMembersProps> = () => {
	const { isMediumUp } = useWindowSize();
	const { selectedMembers, clearSelectedMembers } = useContext(MembersTabContext);
	const currentUserId = useSelector(selectCurrentUserId);

	const [isVisibleRemoveMemberModal, setIsVisibleRemoveMemberModal] = useState(false);

	const isVisible = selectedMembers.length > 1;

	const handleOnClear = () => {
		clearSelectedMembers();
	};

	const handleDeleteOnClick = () => {
		setIsVisibleRemoveMemberModal(true);
	};

	const hanldeRemoveMemberModalOnDismiss = () => {
		setIsVisibleRemoveMemberModal(false);
	};

	const handleRemoveMemberOnSuccess = () => {
		handleOnClear();
		hanldeRemoveMemberModalOnDismiss();
	};

	const batchEditVisible =
		isVisible && ((!isMediumUp && !isVisibleRemoveMemberModal) || isMediumUp);

	const membersAllowedToRemove = selectedMembers.filter((member) => member.id !== currentUserId);
	const triedToRemoveThemselves = selectedMembers.length !== membersAllowedToRemove.length;

	const labelText =
		membersAllowedToRemove.length > 1
			? `${membersAllowedToRemove.length} Mitglieder entfernen`
			: "Mitglied entfernen";

	return (
		<>
			{isVisible && (
				<BatchEdit
					isVisible={batchEditVisible}
					selectedCount={selectedMembers.length}
					onClear={handleOnClear}
					optionNodes={(ItemWrapper) => [
						<ItemWrapper key="delete" onClick={handleDeleteOnClick}>
							<Icon icon="trash" color={isMediumUp ? shade80 : error100} />
							<P2Span className={isMediumUp ? colorShade80Class : colorError100Class}>
								{labelText}
							</P2Span>
						</ItemWrapper>,
					]}
				/>
			)}
			<RemoveMemberModal
				isVisible={isVisibleRemoveMemberModal}
				selectedMembers={membersAllowedToRemove}
				onDismiss={hanldeRemoveMemberModalOnDismiss}
				onSuccess={handleRemoveMemberOnSuccess}
				triedToRemoveThemselves={triedToRemoveThemselves}
			/>
		</>
	);
};
