export const greetingForTimeOfDay = () => {
	const timeOfDay = new Date().getHours();

	if (timeOfDay < 12) {
		return "Guten Morgen";
	}

	if (timeOfDay < 18) {
		return "Guten Tag";
	}

	return "Guten Abend";
};
