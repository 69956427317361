import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
	width: "full",
	padding: "16",
	borderRadius: "12",
	backgroundColor: "primary.20",
});

export const headerClass = css({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	gap: "4",
});

export const contentClass = css({
	display: "grid",
	gridTemplateColumns: "[24px minmax(0, 1fr)]",
	alignItems: "center",
	gap: "24",
});

export const titleClass = css({
	color: "primary.100",
	fontWeight: "bold",
	width: "full",
});
