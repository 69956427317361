import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const wrapperClass = stack();

export const paddingWrapperClass = css({
	paddingTop: "32",
	paddingX: "32",
	paddingBottom: "16",
});

export const headerWrapperClass = stack({
	gap: "24",
});

export const sideMenuWrapperClass = stack({
	gap: "32",
	height: "full",
	overflow: "hidden",
});

export const sideMenuHeaderWrapperClass = stack({
	gap: "24",
	paddingX: "24",
});

export const licenseWrapperClass = stack({
	gap: "8",
	paddingX: "24",
});

export const subpathBackgroundClass = css({
	backgroundColor: "shade.0",
	borderTopRadius: "24",
	height: "full",
	paddingTop: "40",
	paddingX: "40",
	boxShadow:
		"[0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px -85px 0px 0px rgba(41, 19, 113, 0.11), 0px -7px 0px 0px rgba(41, 19, 113, 0.11)]",
});

export const receiverGamificationPointsWrapperClass = flex({
	justifyContent: "center",
	alignItems: "center",
	gap: "8",
	width: "full",
	py: "12",
	bg: "shade.0",
	borderRadius: "12",
});

export const needsLicenseWrapperClass = stack({
	gap: "8",
	mt: "32",
});
