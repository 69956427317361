import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { ImageCardAtom } from "@thekeytechnology/academies-lib-webapp/components/image-card-atom";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { P3Span } from "@themes/font-tags";
import { CardWrapper, Content, TagsRow, Title, Wrapper } from "./root-card.styles";
import { NodeCardProps } from "./root-card.type";

export const RootCard = ({
	imageUrl,
	partnerName,
	partnerUrl,
	tag,
	topic,
	title,
	maxWidth,
	onClick: handleOnClick,
}: NodeCardProps) => {
	const imageWidth = "4.375rem";

	const handlePartnerOnClick = () => {
		window.open(partnerUrl, "_blank");
	};

	const onClickHandler = partnerUrl ? handlePartnerOnClick : undefined;

	return (
		<CardWrapper maxWidth={maxWidth}>
			<Card fillParent bodyPaddingInRem={0.75} onClick={handleOnClick} hasOverflowHidden>
				<Wrapper>
					{imageUrl && (
						<ImageCardAtom imageUrl={imageUrl} imageWidth={imageWidth} isAbsolute />
					)}
					<Content>
						<TagsRow>
							{partnerName && <Label label={partnerName} onClick={onClickHandler} />}
							{tag}
						</TagsRow>
						{topic && <P3Span>{topic}</P3Span>}
						<Title>{title}</Title>
					</Content>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
