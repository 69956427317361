import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { H3Span } from "@themes/font-tags";
import { EXPAND_BUTTON_WRAPPER_HEIGHT, WRAPPER_PADDING } from "./profile-expandable-box.consts";
import {
	boxClass,
	boxContentClass,
	expandButtonWrapperClass,
	wrapperClass,
} from "./profile-expandable-box.styles";
import { ProfileExpandableBoxProps } from "./profile-expandable-box.types";

export const ProfileExpandableBox = ({
	title,
	header,
	defaultHeightRem = 15,
	children,
}: ProfileExpandableBoxProps) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);

	const handleOnToggle = () => {
		setOpen((open) => !open);
	};

	const defaultHeight = `${defaultHeightRem}rem`;

	return (
		<div className={wrapperClass}>
			<H3Span>{title}</H3Span>
			<motion.div
				key={title}
				initial={{
					height: defaultHeight,
				}}
				animate={{
					height: open
						? (contentRef.current?.clientHeight ?? 0) +
						  EXPAND_BUTTON_WRAPPER_HEIGHT +
						  WRAPPER_PADDING +
						  "px"
						: defaultHeight,
				}}
				exit={{
					height: defaultHeight,
				}}
				className={boxClass}
			>
				<div ref={contentRef} className={boxContentClass}>
					{header && <div>{header}</div>}
					<div>{children}</div>
					<div className={expandButtonWrapperClass}>
						<Button
							iconName={open ? "arrowUp" : "arrowDown"}
							onClick={handleOnToggle}
						/>
					</div>
				</div>
			</motion.div>
		</div>
	);
};
