import { ButtonSkeleton } from "@thekeytechnology/academies-lib-webapp/components/button";
import { DividerList } from "@thekeytechnology/academies-lib-webapp/components/divider-list";
import { Skeleton } from "@thekeytechnology/epic-ui";
import {
	avatarWrapperClass,
	currentUserWrapperClass,
	menuWrapperClass,
	pointsWrapperClass,
} from "./profile-menu.styles";
import { ChipSkeleton } from "../chip/chip.skeleton";
import { ProfileMenuRowSkeleton } from "../profile-menu-row/profile-menu-row.skeleton";

// TODO: add-translations
export const ProfileMenuSkeleton = () => {
	return (
		<div className={menuWrapperClass}>
			<div className={currentUserWrapperClass}>
				<div className={avatarWrapperClass}>
					<Skeleton width="5rem" height="5rem" />
					<div className={pointsWrapperClass}>
						<ChipSkeleton />
					</div>
				</div>
				<Skeleton height="2rem" width="15rem" />
			</div>
			<ButtonSkeleton fillParent />
			<DividerList hasDivider gapRem={1}>
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
			</DividerList>
		</div>
	);
};
