import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { InputTextStatus, InputTextType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { colorSecondary100Class } from "@themes/color-classes";
import { H1Span, P1Span, P3Span } from "@themes/font-tags";
import {
	formWrapperClass,
	inputsWrapperClass,
	textCenterClass,
} from "./password-forgot-email-step.styles";
import {
	PasswordForgotEmailStepFormState,
	PasswordForgotEmailStepProps,
} from "./password-forgot-email-step.types";

export const PasswordForgotEmailStep = ({ onGoToLogin, onNext }: PasswordForgotEmailStepProps) => {
	const { t } = useTkaTranslation("forgotPasswordForm");

	const form = useFormik<PasswordForgotEmailStepFormState>({
		initialValues: {
			email: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("forgot_password_form.validationInvalidEmail"))
				.required(t("forgot_password_form.validationRequiredEmail")),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<div className={formWrapperClass}>
				<div className={textCenterClass}>
					<H1Span>{t("forgot_password_form.title")}</H1Span>
					<P1Span>{t("forgot_password_form.title2")}</P1Span>
				</div>
				<div className={inputsWrapperClass}>
					<ValidatedInputText
						formikConfig={form}
						name="email"
						type={InputTextType.Default}
						status={InputTextStatus.Default}
						icon="email"
						placeholder={t("forgot_password_form.placeholderEmail")}
					/>
				</div>
				<Button fillParent label={t("forgot_password_form.buttonText")} />
				<div className={textCenterClass}>
					<P3Span className={colorSecondary100Class} onClick={onGoToLogin}>
						{t("forgot_password_form.backToLogin")}
					</P3Span>
				</div>
			</div>
		</form>
	);
};
