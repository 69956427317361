import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { motion } from "framer-motion";
import { ReactComponent as FailureModalIcon } from "@assets/modal-icon-failure-new.svg";
import { colorError100Class } from "@themes/color-classes";
import { P2Span, H1Span } from "@themes/font-tags";
import {
	wrapperClass,
	messageContainerClass,
	iconAndBadgeWrapperClass,
	contentWrapperClass,
} from "./content-submission-failure-modal.styles";
import { ContentSubmissionFailureModalProps } from "./content-submission-failure-modal.types";

export const ContentSubmissionFailureModal = ({
	isVisible = true,
}: ContentSubmissionFailureModalProps) => {
	const { t } = useTkaTranslation("failureEvaluationModal");

	if (!isVisible) return null;

	return (
		<motion.div
			initial={{ y: "100%", opacity: 0 }}
			animate={{ y: "calc(0% + 1.25rem)", opacity: 1 }}
			exit={{ y: "100%", opacity: 0 }}
			transition={{ type: "spring", stiffness: 100 }}
			className={wrapperClass}
		>
			<div className={contentWrapperClass}>
				<div className={iconAndBadgeWrapperClass}>
					<FailureModalIcon />
				</div>
				<div className={messageContainerClass}>
					<H1Span className={colorError100Class}>
						{t("failure_evaluation_modal.title")}
					</H1Span>
					<P2Span className={colorError100Class}>
						{t("failure_evaluation_modal.description")}
					</P2Span>
				</div>
			</div>
		</motion.div>
	);
};
