import { css, cva } from "@styled-system/css";

export const classTypeTextClass = cva({
	variants: {
		classType: {
			D: {
				color: "error.100",
			},
			I: {
				color: "warning.100",
			},
			G: {
				color: "primary.80",
			},
			S: {
				color: "success.100",
			},
		},
	},
});

export const carouselRootClass = css({
	my: "20",
	md: {
		my: "0",
	},
});

export const carouselContentClass = css({
	display: "flex",
});

export const carouselContainerClass = css({
	position: "relative",
	width: "full",
	x: "-10!",
	gap: "16",
	mdDown: {
		width: "[20em]",
	},
	margin: "auto",
});

export const carouselItemClass = css({
	width: "full",
	flex: "[1 0 100%]",
});

export const carouselIndicatorContainerClass = css({
	display: "flex",
	alignItems: "center",
	gap: "[6px]",
	paddingBottom: "4!",
});

export const carouselIndicatorClass = css({
	width: "[6px]",
	height: "[6px]",
	marginRight: "0!",
	marginBottom: "0!",
	borderRadius: "full",
	bg: "shade.10",
	"& > *": {
		width: "[6px!]",
		height: "[6px!]",
	},
	"&.p-highlight": {
		width: "12",
		height: "12",
		bg: "primary.100",
		"& > *": {
			width: "12!",
			height: "12!",
		},
	},
});

export const carouselIndicatorButtonClass = css({
	borderRadius: "full!",
	bg: "transparent!",
});

export const classWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
	width: "full",
	md: {
		flexDirection: "row",
		gap: "24",
		pr: "24",
	},
});

export const classHeaderClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "4",
	md: {
		minWidth: "[10rem]",
		maxWidth: "[10rem]",
	},
});

export const classImageClass = css({
	width: "full",
	height: "full",
	borderRadius: "12",
	mb: "20",
	md: {
		mb: "12",
	},
});

export const classTextClass = css({
	color: "shade.80",
	display: "block",
	width: "full",
});

export const modalCloseButtonClass = css({
	position: "absolute",
	top: "16",
	right: "16",
	zIndex: "10",
	display: "none",
	md: {
		display: "block",
	},
});
