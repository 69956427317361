import { H3Span } from "@thekeytechnology/epic-ui";
import { FC, useContext, useEffect } from "react";
import { readInlineData, useRefetchableFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { ReminderCard } from "@components/reminder-card";
import { reminderOverview_QueryFragment$key } from "@relay/reminderOverview_QueryFragment.graphql";
import { reminderOverview_RefetchQuery } from "@relay/reminderOverview_RefetchQuery.graphql";
import { reminderOverview_ReminderCardInlineFragment$key } from "@relay/reminderOverview_ReminderCardInlineFragment.graphql";
import { NotesScreenContext } from "@screens/notes/notes.context";
import { info100 } from "@themes/colors";
import { NOTE_CONNECTION_FRAGMENT, NOTE_INLINE_FRAGMENT } from "./reminder-overview.graphql";
import { listClass, listWrapperClass, wrapperClass } from "./reminder-overview.style";
import { ReminderOverviewProps } from "./reminder-overview.types";

export const ReminderOverviewComponent: FC<ReminderOverviewProps> = ({ reminderFragmentRef }) => {
	const [query, refetch] = useRefetchableFragment<
		reminderOverview_RefetchQuery,
		reminderOverview_QueryFragment$key
	>(NOTE_CONNECTION_FRAGMENT, reminderFragmentRef);
	const {
		filters,
		helpers: { registerRefetch, unregisterRefetch },
	} = useContext(NotesScreenContext);

	const edges = query?.Note?.Note.edges;

	const reminders = edges?.reduce<{
		complete: typeof edges;
		incomplete: typeof edges;
	}>(
		(acc, edge) => {
			const data = readInlineData<reminderOverview_ReminderCardInlineFragment$key>(
				NOTE_INLINE_FRAGMENT,
				edge!.node,
			);

			if (data?.reminder === null) return acc;

			if (data.reminder.isCompleted) {
				return {
					complete: [...acc.complete, edge],
					incomplete: acc.incomplete,
				};
			}

			return {
				complete: acc.complete,
				incomplete: [...acc.incomplete, edge],
			};
		},
		{
			complete: [],
			incomplete: [],
		},
	);

	const handleDeleteReminder = () => {
		refetch(
			{
				filterByText: filters.text,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	useEffect(() => {
		const refetchId = registerRefetch(() => {
			refetch(
				{
					...filters,
					filterByText: filters.text,
				},
				{
					fetchPolicy: "store-and-network",
				},
			);
		});

		return () => {
			unregisterRefetch(refetchId);
		};
	}, []);

	useEffect(() => {
		refetch({
			...filters,
			filterByText: filters.text,
		});
	}, [filters]);

	const isEmpty = reminders?.complete.length === 0 && reminders?.incomplete.length === 0;

	return (
		<EmptyPlaceholder
			isVisible={isEmpty}
			iconName="email"
			iconColor={info100}
			iconBackgroundColor="onPrimary"
			title="Hier findest du deine Erinnerungen"
			subtitle="Du kannst jederzeit Erinnerungen erstellen und einsehen. Du wirst dann nach Bedarf auf der Plattform und via Mail erinnert."
		>
			<div className={wrapperClass}>
				<div className={listWrapperClass}>
					<H3Span>Erinnerungen</H3Span>
					<div className={listClass}>
						{reminders?.incomplete.map((note) => (
							<ReminderCard
								key={note?.node.id}
								onDeleteReminder={handleDeleteReminder}
								noteFragmentRef={note?.node}
							/>
						))}
					</div>
				</div>
				<div className={listWrapperClass}>
					<H3Span>Erledigt</H3Span>
					<div className={listClass}>
						{reminders?.complete.map((note) => (
							<ReminderCard
								key={note?.node.id}
								onDeleteReminder={handleDeleteReminder}
								noteFragmentRef={note?.node}
							/>
						))}
					</div>
				</div>
			</div>
		</EmptyPlaceholder>
	);
};
