import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useMatch, useNavigate } from "react-router-dom";
import { brandStrong100, shade0, shade100 } from "@themes/colors";
import { L1Span } from "@themes/font-tags";
import { linkTextClass, wrapperClass } from "./navbar-item.styles";
import { NavbarItemProps } from "./navbar-item.types";

export const NavbarItem = ({ iconName, label, path }: NavbarItemProps) => {
	const navigate = useNavigate();
	const { isXLargeUp } = useWindowSize();
	const isActive = useMatch(path);
	const color = isActive ? brandStrong100 : isXLargeUp ? shade0 : shade100;

	const handleOnClick = () => {
		navigate(path);
	};

	return (
		<div className={wrapperClass} onClick={handleOnClick}>
			<Icon icon={iconName} color={color} />
			<L1Span
				className={linkTextClass({
					isActive: Boolean(isActive),
				})}
			>
				{label}
			</L1Span>
		</div>
	);
};
