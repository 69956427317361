import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useCallback, useContext } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { buttonClass } from "@components/go-to-next-element-button/go-to-next-element-button.styles";
import { goToNextElementButton_ContentSubmissionFragment$key } from "@relay/goToNextElementButton_ContentSubmissionFragment.graphql";
import { goToNextElementButton_GoToNextElementMutation } from "@relay/goToNextElementButton_GoToNextElementMutation.graphql";
import { goToNextElementButton_SubmitDisplayElementMutation } from "@relay/goToNextElementButton_SubmitDisplayElementMutation.graphql";
import { GoToNextElementButtonProps } from "./go-to-next-element-button.interface";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment goToNextElementButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
					}
				}
				canGoToNext
			}
		}
	}
`;

const GO_TO_NEXT_ELEMENT_MUTATION = graphql`
	mutation goToNextElementButton_GoToNextElementMutation($input: GoToNextElementInput!) {
		ELearning {
			goToNextElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

const SUBMIT_DISPLAY_ELEMENT_MUTATION = graphql`
	mutation goToNextElementButton_SubmitDisplayElementMutation(
		$input: SubmitDisplayElementInput!
	) {
		LearnV2 {
			submitDisplayElement(input: $input) {
				clientMutationId
				contentSubmission {
					id
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const GoToNextElementButton = ({
	contentSubmissionFragmentRef,
}: GoToNextElementButtonProps) => {
	const { t } = useTkaTranslation("contentSubmissionScreen");

	const [goToNextElement, loadingNext] =
		useMutation<goToNextElementButton_GoToNextElementMutation>(GO_TO_NEXT_ELEMENT_MUTATION);

	const [submitDisplayElement, loadingSubmit] =
		useMutation<goToNextElementButton_SubmitDisplayElementMutation>(
			SUBMIT_DISPLAY_ELEMENT_MUTATION,
		);
	const contentSubmission = useFragment<goToNextElementButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const { isMediumUp } = useWindowSize();
	const {
		canGoNext,
		goToNextClicked: goToNextClickedInContext,
		onGoToNext,
		nextButtonText,
		loading: loadingElement,
	} = useContext(ContentSubmissionModalContext);

	const contentSubmissionId = contentSubmission.id!;
	const elementId = contentSubmission.definition.currentElementState?.element?.id!;
	const canGoToNextElement = contentSubmission.definition.canGoToNext || false;

	const label = nextButtonText || t("content_submission_screen.continue");

	const goToNextClicked = useCallback(() => {
		const handled = goToNextClickedInContext();
		if (handled) return;
		onGoToNext();

		if (canGoToNextElement) {
			goToNextElement({
				variables: {
					input: {
						contentSubmissionId,
					},
				},
			});
		} else {
			submitDisplayElement({
				variables: {
					input: {
						contentSubmissionId,
						displayElementId: elementId,
					},
				},
			});
		}
	}, [
		goToNextClickedInContext,
		onGoToNext,
		canGoToNextElement,
		submitDisplayElement,
		goToNextElement,
		contentSubmissionId,
		elementId,
	]);

	const loading = loadingElement || loadingNext || loadingSubmit;

	return (
		<Button
			className={buttonClass}
			loading={loading}
			label={label}
			fillParent={!isMediumUp}
			iconName="arrowRight"
			disabled={!canGoNext}
			minWidthRem={10}
			onClick={goToNextClicked}
		/>
	);
};
