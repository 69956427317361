import { css, cva } from "@styled-system/css";

export const elementWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "32",
});

export const hrClass = css({
	display: "block",
	border: "1px solid",
	borderColor: "shade.10",
	width: "full",
});

export const buttonsWrapperClass = css({
	display: "flex",
	justifyContent: "center",
});

export const buttonsContentClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "32",
	padding: "12",
	borderRadius: "16",
	backgroundColor: "[rgba(243, 243, 244, 0.9)]",
});

export const paginationWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "[6px]",
});

export const paginationItemClass = cva({
	base: {
		borderRadius: "full",
		transition: "all",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
	},
	variants: {
		isActive: {
			true: {
				width: "12",
				height: "12",
				backgroundColor: "primary.100",
			},
			false: {
				height: "[6px]",
				width: "[6px]",
				backgroundColor: "shade.10",
			},
		},
	},
});

export const answerItemClass = cva({
	base: {
		width: "full",
		minHeight: "[56.5px]",
		display: "grid",
		gridTemplateColumns: "[16px 16px minmax(0,1fr)]",
		alignItems: "center",
		gap: "16",
		paddingX: "16",
		paddingY: "8",
		borderRadius: "10",
		border: "1px solid",
		borderColor: "shade.10",
		transition: "opacity",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
	},
	variants: {
		number: {
			"2": {
				opacity: "0.8",
			},
			"3": {
				opacity: "0.7",
			},
			"4": {
				opacity: "0.4",
			},
		},
		isDragging: {
			true: {
				opacity: "1",
			},
		},
	},
});

export const answerNumberClass = css({
	width: "16",
	textAlign: "center",
	fontVariantNumeric: "tabular-nums",
});

export const listClass = css({
	display: "flex",
	flexDirection: "column",
	marginX: "auto",
	gap: "4",
	maxWidth: "[530px]",
	paddingY: "20",
});

export const navigationButtonClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingX: "12",
		paddingY: "[6px]",
		borderRadius: "10",
		border: "2px solid",
		borderColor: "primary.100",
		transition: "all",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
	},
	variants: {
		disabled: {
			true: {
				opacity: "0.5",
				pointerEvents: "none",
			},
		},
		finished: {
			true: {
				pointerEvents: "none",
				borderColor: "secondary.40",
				backgroundColor: "secondary.40",
			},
		},
	},
});
