import { graphql } from "react-relay";

export const QUERY = graphql`
	query advanceTreeNavigation_Query($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				...advanceTreeNavigation_LearnOpportunityV2Fragment
			}
		}
		...orderIhkModal_BaseDataFragment
	}
`;

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment advanceTreeNavigation_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		root {
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					viewerTreeState {
						kind
						... on IsStartedViewerTreeState {
							headContentId
							startedAt
						}
						... on IsFinishedViewerTreeState {
							startedAt
						}
					}
					extension {
						... on PublishedRootExtensionImpl {
							unlockInfo {
								kind
								... on UnlockInfoDemo {
									firstContentId
								}
							}
							licenseAvailability {
								... on LicenseAvailabilityFreeLicensesAvailable {
									numAvailable
								}
							}
							cartClickout {
								relativeLink
							}
							product {
								id
								netPrice
							}
							ihkState
						}
					}
				}
			}
		}
		typeDefinition {
			... on LearnOpportunityContentTypeDefinition {
				contentKind
				contentNodeAdvancementResult {
					status
					... on CanNotBeStartedContentNodeAdvancementResult {
						reasonConfigTypes
					}
				}
			}
			... on LearnAsyncContentTypeDefinition {
				contentKind
				contentNodeAdvancementResult {
					status
					... on CanNotBeStartedContentNodeAdvancementResult {
						reasonConfigTypes
					}
				}
			}
		}
		nextContentNodeId
	}
`;

export const START_TREE_MUTATION = graphql`
	mutation advanceTreeNavigation_StartTreeMutation($input: StartTreeInput!) {
		LearnV2 {
			startTree(input: $input) {
				clientMutationId
				learnOpportunity {
					...advanceTreeNavigation_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;

export const BOOK_TREE_MUTATION = graphql`
	mutation advanceTreeNavigation_BookTreeMutation($input: BookTreeInput!) {
		Billing {
			bookTree(input: $input) {
				clientMutationId
				root {
					...advanceTreeNavigation_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;

export const START_CONTENT_NODE_MUTATION = graphql`
	mutation advanceTreeNavigation_StartContentNodeMutation($input: StartContentNodeInput!) {
		LearnV2 {
			startContentNode(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;

export const RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION = graphql`
	mutation advanceTreeNavigation_RestartContentNodeAfterPassedMutation(
		$input: RestartContentNodeAfterPassedInput!
	) {
		LearnV2 {
			restartContentNodeAfterPassed(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;

export const RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION = graphql`
	mutation advanceTreeNavigation_RestartContentNodeAfterFailedMutation(
		$input: RestartContentNodeAfterFailedInput!
	) {
		LearnV2 {
			restartContentNodeAfterFailed(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;
