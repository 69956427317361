import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { MouseEvent, useRef } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Path } from "@router/paths";
import { cx } from "@styled-system/css";
import {
	colorError100Class,
	colorInfo100Class,
	colorShade60Class,
	colorWarning100Class,
} from "@themes/color-classes";
import { error100, info100, warning100 } from "@themes/colors";
import { P3Span } from "@themes/font-tags";
import { LICENSE_GROUPING_FRAGMENT } from "./licenses-group-card.graphql";
import {
	barWrapperClass,
	cardImageClass,
	cardWrapperClass,
	contentClass,
	labelContainerClass,
	labelWrapperClass,
	licenseBarContainerClass,
	statusInfoClass,
	statusTextClass,
	statusWrapperClass,
	titleClass,
	titleSubtitleWrapperClass,
	usedLicensesBarClass,
	wrapperClass,
} from "./licenses-group-card.styles";
import { LicensesGroupCardProps } from "./licenses-group-card.type";
import { contextMenuWrapperClass } from "../user-card/user-card.styles";

// TODO: add-translations
export const LicensesGroupCard = ({
	licensesGroupCardFragmentRef,
	isAdminCard = false,
	onClick,
	children,
}: LicensesGroupCardProps) => {
	const ref = useRef<any>(null);
	const licenseGroup = useFragment(
		LICENSE_GROUPING_FRAGMENT,
		licensesGroupCardFragmentRef ?? null,
	);

	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();

	if (!licenseGroup) return null;
	const totalLicenseAmount = isAdminCard
		? licenseGroup.totalLicenseAmount
		: licenseGroup.totalLicenseAmountForUser;
	const usedLicenseAmount = isAdminCard
		? licenseGroup.usedLicenseAmount
		: licenseGroup.usedLicenseAmountForUser;

	const learnableKind = licenseGroup.learnable?.kind;
	const isTreeLicense = learnableKind === "Course" || learnableKind === "MicroLearning";

	const imageUrl = licenseGroup.learnable?.root?.image?.thumbnail;

	const numberOfFreeLicenses = totalLicenseAmount - usedLicenseAmount;
	const freeLicensesLabel =
		numberOfFreeLicenses > 1
			? `${numberOfFreeLicenses}/${totalLicenseAmount} Verfügbar`
			: "Verfügbar";

	const usedLicensesLabel =
		usedLicenseAmount === 1
			? "Eingelöst"
			: usedLicenseAmount > 1
			? `${usedLicenseAmount}/${usedLicenseAmount} Eingelöst`
			: `${totalLicenseAmount}/${totalLicenseAmount} Eingelöst`;

	const licensesStillAvailable = numberOfFreeLicenses > 0;
	const licensesLabelSeverity = numberOfFreeLicenses > 0 ? "success" : "info";

	const usedLicensesPercentage = (usedLicenseAmount / totalLicenseAmount) * 100;

	const noAssignmentRules = licenseGroup.assignmentRules.length === 0;
	const showStatusInfo = noAssignmentRules || usedLicensesPercentage > 49;
	const allLicensesUsed = usedLicensesPercentage === 100;

	const handleOnClick = () => {
		if (isAdminCard) {
			onClick?.(licenseGroup.id);
		}
		if (isTreeLicense && !isAdminCard) {
			const rootId = licenseGroup?.learnable?.root?.id;
			rootId && navigate(Path.root.withId(rootId).path);
		}
	};

	const title = licenseGroup?.learnable?.root?.structureDefinition?.title;
	const subtitle = "Weiterbildungen";

	const handleOpenContextMenu = (event: MouseEvent<HTMLElement>) => {
		ref.current?.toggle(event, event?.currentTarget);
	};

	const statusTkaColor = allLicensesUsed ? error100 : noAssignmentRules ? warning100 : info100;

	const statusColorClassname = allLicensesUsed
		? colorError100Class
		: noAssignmentRules
		? colorWarning100Class
		: colorInfo100Class;

	const getStatusText = () => {
		return allLicensesUsed
			? "Lizenzen aufgebraucht"
			: noAssignmentRules
			? "Keine Zuweisungsregel(n)"
			: "Lizenzen auffüllen";
	};

	return (
		<>
			<div className={cardWrapperClass}>
				<Card fillParent bodyPaddingInRem={0.75} onClick={handleOnClick}>
					<div className={wrapperClass}>
						{imageUrl && (
							<img
								src={imageUrl}
								alt=""
								className={cardImageClass}
								aria-hidden="true"
							/>
						)}
						<div className={contentClass}>
							<div className={titleSubtitleWrapperClass}>
								<P3Span>{subtitle}</P3Span>
								<span className={titleClass}>{title}</span>
							</div>
						</div>
						{isAdminCard ? (
							<>
								{showStatusInfo && (
									<>
										<button
											type="button"
											className={statusInfoClass}
											onClick={(e) => e.stopPropagation()}
										>
											<Icon
												icon="informationCircle"
												sizeRem={0.75}
												color={statusTkaColor}
											/>
											<span
												className={cx(
													statusTextClass,
													statusColorClassname,
												)}
											>
												{getStatusText()}
											</span>
										</button>
									</>
								)}
								<div className={statusWrapperClass}>
									{!isMediumUp && <Divider />}
									<div className={barWrapperClass}>
										<div className={licenseBarContainerClass}>
											<div
												className={usedLicensesBarClass}
												style={
													{
														"--width": `${usedLicensesPercentage}%`,
													} as any
												}
											/>
										</div>
										<P3Span className={colorShade60Class}>
											{licensesStillAvailable
												? freeLicensesLabel
												: usedLicensesLabel}
										</P3Span>
									</div>
								</div>
							</>
						) : (
							<div className={labelWrapperClass}>
								<div className={labelContainerClass}>
									<Label
										size="smal"
										severity={licensesLabelSeverity}
										label={
											licensesStillAvailable
												? freeLicensesLabel
												: usedLicensesLabel
										}
									/>
								</div>
							</div>
						)}
						{isAdminCard && isMediumUp && (
							<button
								type="button"
								className={contextMenuWrapperClass}
								onClick={(e) => {
									e.stopPropagation();
									handleOpenContextMenu(e);
								}}
							>
								<Icon icon="contextMenu" sizeInRem={1} />
							</button>
						)}
					</div>
				</Card>
			</div>
			{children?.(ref)}
		</>
	);
};
