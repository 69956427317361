import { graphql } from "react-relay";

export const QUERY = graphql`
	query dashboard_Query(
		$tagIds: [ID!]!
		$kinds: [LearnableKind!]!
		$first: Int
		$last: Int
		$before: String
		$after: String
		$text: String
	) {
		Viewer {
			Auth {
				currentUser {
					user {
						extensions {
							kind
							... on AcademiesUserExtension {
								firstName
								lastName
								level
								progressInPercent
								sumGamificationPoints
							}
							... on TkaUserExtension {
								levelDefinition {
									id
									title
								}
							}
						}
						...userAvatar_UserFragment
					}
				}
			}
		}

		...dashboard_QueryFragment
			@arguments(
				kinds: $kinds
				tagIds: $tagIds
				first: $first
				last: $last
				before: $before
				after: $after
				text: $text
			)
	}
`;
export const QUERY_FRAGMENT = graphql`
	fragment dashboard_QueryFragment on Query
	@argumentDefinitions(
		tagIds: { type: "[ID!]!" }
		kinds: { type: "[LearnableKind!]!" }
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
		text: { type: "String", defaultValue: null }
	) {
		Learnable {
			PublishedLearnables(
				before: $before
				after: $after
				first: $first
				last: $last
				tagIds: $tagIds
				text: $text
				kinds: $kinds
			) @connection(key: "dashboard_PublishedLearnables") {
				edges {
					node {
						id
						kind
						...offerCard_PublishedLearnableFragment
					}
				}
			}
		}
	}
`;
