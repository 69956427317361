import { graphql } from "react-relay";

export const PERSOLOG_EVALUATION_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment persologEvaluationClassModal_PersologEvaluationLearnElementFragment on Persolog_PersologEvaluationLearnElement {
		classes {
			classType
			title
			text
			image {
				id
				name
				url
			}
		}
		evaluation {
			classes {
				classType
				percentage
			}
		}
	}
`;
