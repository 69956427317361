import { css } from "@styled-system/css";

export const cardWrapperClass = css({
	minHeight: "64",
	display: "flex",
});

export const wrapperClass = css({
	display: "flex",
	width: "full",
	flexWrap: "wrap",
	alignItems: "center",
	gap: "12",
	md: {
		flexWrap: "nowrap",
	},
});

export const cardImageClass = css({
	width: "[4.375rem]",
	maxHeight: "[4.375rem]",
	height: "full",
	objectFit: "cover",
	borderRadius: "8",
});

export const contentClass = css({
	display: "flex",
	flexDirection: "column",
	flex: "1",
	gap: "4",
});

export const titleSubtitleWrapperClass = css({
	display: "flex",
	flexDirection: "column",
});

export const titleClass = css({
	textStyle: "h3",
	overflow: "hidden",
	display: "[-webkit-box]",
	boxOrient: "vertical",
	lineClamp: "2",
	WebkitLineClamp: "2",
});

export const licensesUsedWrapperClass = css({
	display: "flex",
	flexDirection: "row",
	gap: "4",
});

export const licenseBarContainerClass = css({
	position: "relative",
	display: "flex",
	width: "[3.75rem]",
	height: "8",
	marginTop: "8",
	marginBottom: "8",
	borderRadius: "[5.975rem]",
	backgroundColor: "shade.10",
	_after: {
		content: "''",
		position: "absolute",
		zIndex: 10,
		width: "full",
		height: "full",
		borderRadius: "[5.875rem]",
		boxShadow: "[inset -1px 1px 15px 0px rgba(9, 49, 71, 0.2)]",
		mixBlendMode: "multiply",
	},
	_before: {
		content: "''",
		position: "absolute",
		zIndex: 10,
		width: "full",
		height: "full",
		borderRadius: "[5.875rem]",
		boxShadow: "[inset 0 0 1px 0px rgba(6, 31, 42, 0.3)]",
		mixBlendMode: "darken",
	},
});

export const usedLicensesBarClass = css({
	width: "var(--width)",
	height: "full",
	backgroundColor: "secondary.100",
	borderRadius: "[5.875rem]",
	zIndex: 1,
});

export const statusTextClass = css({
	textStyle: "p3",
	marginLeft: "4",
});

export const statusWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	width: "full",
	md: {
		flexDirection: "row",
		width: "auto",
	},
});

export const statusInfoClass = css({
	display: "flex",
	md: {
		display: "inline-flex",
	},
});

export const barWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});

export const labelWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	width: "full",
	borderTop: "1px solid",
	borderColor: "shade.10",
	marginTop: "12",
	paddingTop: "12",
	paddingBottom: "0",
	md: {
		borderTop: "0",
		flexDirection: "row",
		width: "auto",
		marginTop: "0",
		paddingTop: "0",
	},
});

export const labelContainerClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
	justifyContent: "space-between",
});
