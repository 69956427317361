/**
 * @generated SignedSource<<7799db7fad8a3bf1e99b31bb6ba445cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type notesOverview_QueryFragment$data = {
  readonly Note: {
    readonly Note: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly reminder: {
            readonly __typename: string;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"noteCard_NoteFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "notesOverview_QueryFragment";
};
export type notesOverview_QueryFragment$key = {
  readonly " $data"?: notesOverview_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"notesOverview_QueryFragment">;
};

import notesOverview_RefetchQuery_graphql from './notesOverview_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteColors"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteSourceKinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noteColorToSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": notesOverview_RefetchQuery_graphql
    }
  },
  "name": "notesOverview_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteQueries",
      "kind": "LinkedField",
      "name": "Note",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByText",
              "variableName": "filterByText"
            },
            {
              "kind": "Variable",
              "name": "includeNoteColors",
              "variableName": "includeNoteColors"
            },
            {
              "kind": "Variable",
              "name": "includeNoteSourceKinds",
              "variableName": "includeNoteSourceKinds"
            },
            {
              "kind": "Variable",
              "name": "noteColorToSortBy",
              "variableName": "noteColorToSortBy"
            }
          ],
          "concreteType": "NoteConnection",
          "kind": "LinkedField",
          "name": "Note",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NoteEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Note",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "reminder",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "noteCard_NoteFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a9f9d5e590ac87f0897f19857245b0d5";

export default node;
