import { css } from "@styled-system/css";

export const tabWrapperClass = css({
	flex: "1",
	gap: "32",
	overflowY: "auto",
	xl: {
		py: "16",
		px: "0",
		overflowY: "auto",
	},
});
