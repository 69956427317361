/**
 * @generated SignedSource<<e2805047abfb0528bd94b28b7e271db2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AcademiesTagKind = "Child" | "Root";
export type LearnableKind = "Course";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type offerCard_PublishedLearnableFragment$data = {
  readonly id?: string;
  readonly kind?: LearnableKind;
  readonly root?: {
    readonly description: string | null;
    readonly id: string;
    readonly image: {
      readonly url: string | null;
    } | null;
    readonly shortDescription: string | null;
    readonly structureDefinition: {
      readonly extension?: {
        readonly licenseAvailability?: {
          readonly numAvailable?: number;
        };
        readonly reducedData?: {
          readonly isIHK: boolean;
        } | null;
        readonly tags?: ReadonlyArray<{
          readonly data: {
            readonly isClickable: boolean;
            readonly isTopic: boolean;
            readonly name: string;
          };
          readonly id: string;
          readonly kind: AcademiesTagKind;
        }>;
        readonly unlockInfo?: {
          readonly firstContentId?: string;
          readonly kind: UnlockInfoKind;
        };
      };
      readonly progressPercentage?: number | null;
      readonly title?: string;
      readonly viewerTreeState?: {
        readonly headContentId?: string;
        readonly kind: ViewerTreeStateKind;
      };
    };
    readonly typeDefinition: {
      readonly extension?: {
        readonly isUnlockNecessaryToStart?: boolean;
      };
    };
  } | null;
  readonly " $fragmentType": "offerCard_PublishedLearnableFragment";
};
export type offerCard_PublishedLearnableFragment$key = {
  readonly " $data"?: offerCard_PublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offerCard_PublishedLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offerCard_PublishedLearnableFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortDescription",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "typeDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isUnlockNecessaryToStart",
                              "storageKey": null
                            }
                          ],
                          "type": "LearnContentExtensionImpl",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnAsyncContentTypeDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "progressPercentage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "viewerTreeState",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "headContentId",
                              "storageKey": null
                            }
                          ],
                          "type": "IsStartedViewerTreeState",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ReducedData",
                              "kind": "LinkedField",
                              "name": "reducedData",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isIHK",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "tags",
                              "plural": true,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AcademiesTagData",
                                  "kind": "LinkedField",
                                  "name": "data",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "name",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "isClickable",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "isTopic",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "unlockInfo",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "firstContentId",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "UnlockInfoDemo",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "licenseAvailability",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "numAvailable",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "LicenseAvailabilityFreeLicensesAvailable",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "PublishedRootExtensionImpl",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PublishedCourseLearnable",
      "abstractKey": null
    }
  ],
  "type": "PublishedLearnable",
  "abstractKey": "__isPublishedLearnable"
};
})();

(node as any).hash = "ef10110ef68ec845403f035d16f00aa7";

export default node;
