import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { useCallback, useContext, useEffect, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { Persolog_ClassType } from "@relay/persologElement_ContentSubmissionFragment.graphql";
import type { persologElement_SubmitPersologQuestionElementMutation } from "@relay/persologElement_SubmitPersologQuestionElementMutation.graphql";
import { colorShade100Class, colorShade80Class } from "@themes/color-classes";
import { H1Span, H3Span, P1Span, P2Span } from "@themes/font-tags";
import {
	PERSOLOG_ELEMENT_FRAGMENT,
	SUBMIT_PERSOLOG_QUESTION_ELEMENT_MUTATION,
} from "./persolog-element.graphql";
import {
	answerItemClass,
	answerNumberClass,
	buttonsContentClass,
	buttonsWrapperClass,
	elementWrapperClass,
	hrClass,
	listClass,
	navigationButtonClass,
	paginationItemClass,
	paginationWrapperClass,
} from "./persolog-element.styles";
import type { PersologElementProps } from "./persolog-element.types";

export const PersologElement = ({ contentSubmissionFragmentRef }: PersologElementProps) => {
	const contentSubmission = useFragment(PERSOLOG_ELEMENT_FRAGMENT, contentSubmissionFragmentRef);
	const [submitPersologQuestionElement] =
		useMutation<persologElement_SubmitPersologQuestionElementMutation>(
			SUBMIT_PERSOLOG_QUESTION_ELEMENT_MUTATION,
		);

	const elementState = contentSubmission?.definition.currentElementState;
	const element = elementState?.element;
	const questions = element?.questions ?? [];

	const currentElementStateKind = elementState?.kind || "Untouched";

	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";

	const {
		isModalVisible,
		setCanGoNext,
		setNextButtonText,
		addGoToNextOnClickListener,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [hasPreviousQuestion, setHasPreviousQuestion] = useState(false);
	const [hasNextQuestion, setHasNextQuestion] = useState(true);
	const question = questions[currentQuestionIndex];
	const isOnLastQuestion = currentQuestionIndex === questions.length - 1;
	const dragDisabled = isModalVisible || !isUntouched;

	const [questionClassOrders, setQuestionClassOrders] = useState<
		{
			questionId: string;
			title: string;
			questionClasses: {
				readonly classType: Persolog_ClassType;
				readonly text: string;
			}[];
		}[]
	>(
		questions.map((q) => ({
			...q,
			questionClasses: [...q.questionClasses],
			questionId: q.id,
		})),
	);
	const currentQuestionClassOrder = questionClassOrders.find((q) => q.questionId === question.id);

	const handleSubmit = useCallback(() => {
		if (!element?.id) return;
		setLoading(true);
		submitPersologQuestionElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id,
					persologElementId: element?.id,
					order: questionClassOrders.map((q) => ({
						questionId: q.questionId,
						classTypeOrder: [
							q.questionClasses[0].classType,
							q.questionClasses[q.questionClasses.length - 1].classType,
						],
					})),
				},
			},
			onCompleted: () => {
				setLoading(false);
			},
		});
	}, [
		contentSubmission.id,
		element?.id,
		questionClassOrders,
		submitPersologQuestionElement,
		setLoading,
	]);

	const handleGoToNextClicked = async () => {
		const nextQuestionIndex = currentQuestionIndex + 1;
		if (nextQuestionIndex < questions.length) {
			setCurrentQuestionIndex(nextQuestionIndex);
			setHasNextQuestion(nextQuestionIndex < questions.length - 1);
			setHasPreviousQuestion(true);
		} else {
			setHasNextQuestion(false);
		}

		setCanGoNext(isOnLastQuestion);
	};

	const handleGoToPreviousClicked = async () => {
		const previousQuestionIndex = currentQuestionIndex - 1;
		if (previousQuestionIndex >= 0) {
			setCurrentQuestionIndex(previousQuestionIndex);
			setHasPreviousQuestion(previousQuestionIndex > 0);
			setHasNextQuestion(true);
		} else {
			setHasPreviousQuestion(false);
		}

		setCanGoNext(false);
	};

	const handleDrop = (droppedItem: any) => {
		if (!droppedItem.destination) return;
		const updatedList = [...(currentQuestionClassOrder?.questionClasses ?? [])];
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		setQuestionClassOrders((prev) =>
			prev.map((q) => {
				if (q.questionId === question.id) {
					return {
						...q,
						questionClasses: updatedList,
					};
				}
				return q;
			}),
		);
	};

	const createAnswerItem = (item: typeof question.questionClasses[number], index: number) => {
		const number = index + 1;

		return (
			<Draggable
				key={item.classType}
				draggableId={item.classType}
				index={index}
				isDragDisabled={dragDisabled}
			>
				{(provided, snapshot) => (
					<div
						className={answerItemClass({
							number: number.toString() as "2" | "3" | "4",
							isDragging: snapshot.isDragging,
						})}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
					>
						<Icon icon="dragDrop" />
						<H3Span className={answerNumberClass}>{number}</H3Span>
						<P2Span>{item.text}</P2Span>
					</div>
				)}
			</Draggable>
		);
	};

	useEffect(() => {
		setCanGoNext(isOnLastQuestion);
		setNextButtonText("Abschließen");
	}, [isOnLastQuestion, setCanGoNext, setNextButtonText]);

	const handleGoToNextClickedListener = useCallback(() => {
		if (isUntouched) {
			handleSubmit();
			return true;
		}
		return false;
	}, [isUntouched, handleSubmit]);

	useEffect(
		() => addGoToNextOnClickListener(handleGoToNextClickedListener),
		[handleGoToNextClickedListener],
	);

	return (
		<SideMenuContentPadding className={elementWrapperClass}>
			{element?.title && (
				<H1Span className={colorShade100Class}>{stripHtml(element.title)}</H1Span>
			)}
			<P1Span className={colorShade80Class}>
				Sortiere die Antworten nach deinem persönlichen Empfinden von oben nach unten.
				<br />
				Was trifft am ehesten auf dich zu?
			</P1Span>
			<hr className={hrClass} />
			<div>
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId="persolog-list" isDropDisabled={dragDisabled}>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								<div className={listClass}>
									{currentQuestionClassOrder?.questionClasses.map(
										(questionClass, index) =>
											createAnswerItem(questionClass, index),
									)}
									{provided.placeholder}
								</div>
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<div className={buttonsWrapperClass}>
				<div className={buttonsContentClass} data-no-selection-menu>
					<button
						type="button"
						onClick={handleGoToPreviousClicked}
						disabled={!hasPreviousQuestion}
						className={navigationButtonClass({
							disabled: !hasPreviousQuestion,
						})}
					>
						<Icon icon="arrowLeft" sizeRem={0.875} />
					</button>
					<ul className={paginationWrapperClass}>
						{questions.map((_, i) => (
							<li
								key={i}
								className={paginationItemClass({
									isActive: i === currentQuestionIndex,
								})}
							/>
						))}
					</ul>
					<button
						type="button"
						onClick={handleGoToNextClicked}
						disabled={!hasNextQuestion}
						className={navigationButtonClass({
							finished: isOnLastQuestion,
						})}
					>
						<Icon
							icon={isOnLastQuestion ? "checkBadge" : "arrowRight"}
							sizeRem={0.875}
						/>
					</button>
				</div>
			</div>
		</SideMenuContentPadding>
	);
};
