import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { ValidatedDropdown } from "@thekeytechnology/academies-lib-webapp/components/validated-dropdown";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { getLocalDate } from "@thekeytechnology/academies-lib-webapp/utils";
import { useFormik } from "formik";
import { useFragment, useMutation } from "react-relay";
import { ValidatedCalendar } from "@components/validated-calendar";
import { orderIhkModal_OrderIHKCertificateMutation } from "@relay/orderIhkModal_OrderIHKCertificateMutation.graphql";
import { TkaH1Span, TkaP3Span } from "@themes/font-tags";
import { BASE_DATA_FRAGMENT, ORDER_IHK_CERTIFICATE_MUTATION } from "./order-ihk-modal.graphql";
import {
	InputsWrapper,
	RowSpan12,
	RowSpan6,
	TitleWrapper,
	Wrapper,
} from "./order-ihk-modal.styles";
import {
	OrderIHKModalFormSchema,
	OrderIHKModalFormState,
	OrderIHKModalProps,
} from "./order-ihk-modal.types";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { getSalutationOptions, getTitleOptions } from "./order-ihk-modal.utils";

// TODO: add-translations
export const OrderIHKModal = ({
	rootId,
	isVisible = false,
	onDismiss,
	onOrdered,
	baseDataFragmentRef,
}: OrderIHKModalProps) => {
	const baseData = useFragment(BASE_DATA_FRAGMENT, baseDataFragmentRef ?? null);
	const [orderIHKCertificate, loadingOrderIHKCertificate] =
		useMutation<orderIhkModal_OrderIHKCertificateMutation>(ORDER_IHK_CERTIFICATE_MUTATION);

	const accountBaseData = baseData?.AccountBaseData.AccountBaseData;

	const form = useFormik<OrderIHKModalFormState>({
		initialValues: {
			salutation: accountBaseData?.salutation ?? undefined,
			title: accountBaseData?.title ?? undefined,
			firstName: accountBaseData?.firstName ?? "",
			lastName: accountBaseData?.lastName ?? "",
			street: accountBaseData?.street ?? "",
			houseNumber: accountBaseData?.houseNumber ?? "",
			city: accountBaseData?.city ?? "",
			postCode: accountBaseData?.postalCode ?? "",
			country: accountBaseData?.countryCode ?? "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: toFormikValidationSchema(OrderIHKModalFormSchema),
		onSubmit: (values) => {
			const localDate = getLocalDate(values.dateOfBirth!);
			orderIHKCertificate({
				variables: {
					input: {
						rootId,
						userData: {
							salutation: values.salutation,
							title: values.title,
							firstName: values.firstName,
							lastName: values.lastName,
							street: values.street,
							houseNumber: values.houseNumber,
							postalCode: values.postCode,
							city: values.city,
							country: values.country,
							dateOfBirth: localDate.toString(),
						},
					},
				},
				onCompleted: () => onOrdered?.(),
			});
		},
	});

	const handleOrderOnClick = () => {
		form.submitForm();
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} blocking={false}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonIconName="arrowRight"
				primaryButtonLabel="IHK-Zertifikat anfordern"
				primaryButtonLoading={loadingOrderIHKCertificate}
				onPrimaryButtonClick={handleOrderOnClick}
				maxWidthRem={50}
			>
				<Wrapper>
					<TitleWrapper>
						<TkaH1Span>Deine Adressdaten</TkaH1Span>
						<TkaP3Span>
							Um Dein Zertifikat in den nächsten Tagen per Post zu erhalten.
						</TkaP3Span>
					</TitleWrapper>
					<form onSubmit={form.handleSubmit}>
						<InputsWrapper>
							<RowSpan6>
								<ValidatedDropdown
									formikConfig={form}
									name="salutation"
									options={getSalutationOptions()}
									label="Anrede*"
									placeholder="Anrede"
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedDropdown
									showClear
									formikConfig={form}
									name="title"
									options={getTitleOptions()}
									label="Titel"
									placeholder="Titel"
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="firstName"
									label={"Name*"}
									placeholder={"Name"}
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="lastName"
									label={"Nachname*"}
									placeholder={"Nachname"}
								/>
							</RowSpan6>
							<RowSpan12>
								<ValidatedCalendar
									formikConfig={form}
									name="dateOfBirth"
									label={"Geburtsdatum*"}
									placeholder={"01.01.1990"}
								/>
							</RowSpan12>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="street"
									label={"Straße*"}
									placeholder={"Straße"}
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="houseNumber"
									label={"Hausnummer*"}
									placeholder={"Hausnummer"}
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="postCode"
									label={"PLZ*"}
									placeholder={"PLZ"}
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="city"
									label={"Ort/Stadt*"}
									placeholder={"Ort/Stadt"}
								/>
							</RowSpan6>
							<RowSpan12>
								<ValidatedInputText
									formikConfig={form}
									name="country"
									label={"Land*"}
									placeholder={"Deutschland"}
								/>
							</RowSpan12>
						</InputsWrapper>
					</form>
				</Wrapper>
				<Divider />
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
