import { css } from "@styled-system/css";
import { flex, grid } from "@styled-system/patterns";

export const gradientWrapperClass = flex({
	position: "relative",
	flexDirection: "column",
	alignItems: "flex-start",
	justifyContent: "space-between",
	padding: "32",
	borderRadius: "12",
	width: "full",
	background: "[rgba(83, 39, 230, 0.60)]",
	boxShadow:
		"[0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)]",
	xl: {
		flexDirection: "row",
	},
	_before: {
		content: "''",
		position: "absolute",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		zIndex: "1",
		borderRadius: "12",
		background: "[radial-gradient(150% 417.55% at 5.45% 43.49%, #8E73EB 0%, #5327E6 60%)]",
	},
});

export const userDataWrapperClass = grid({
	position: "relative",
	zIndex: "10",
	gap: "16",
	gridTemplateColumns: "[5rem minmax(0,1fr)]",
});

export const userDataClass = flex({
	flexDirection: "column",
	alignItems: "flex-start",
	gap: "4",
});

export const userLevelAndButtonWrapperClass = flex({
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-end",
	marginTop: "16",
	marginLeft: "96",
	lg: {
		marginTop: "0",
		marginLeft: "0",
		alignSelf: "flex-end",
	},
});

export const userEditButtonClass = flex({
	position: "absolute",
	zIndex: "10",
	top: "12",
	right: "12",
	alignItems: "center",
	gap: "4",
	paddingX: "8",
	paddingY: "[3px]",
	backgroundColor: "shade.0",
	borderRadius: "4",
});

export const userLevelWrapperClass = css({
	position: "relative",
	zIndex: "10",
});

export const userLevelTitleClass = flex({
	gap: "4",
});

export const userLevelProgressWrapperClass = flex({
	display: "flex",
	gap: "8",
	alignItems: "center",
});

export const userLevelProgressBarClass = css({
	height: "full",
	width: "[0%]",
	borderRadius: "full",
	backgroundColor: "shade.100",
});

export const userLevelProgressClass = css({
	width: "[84px]",
	height: "[10px]",
	borderRadius: "full",
	backgroundColor: "primary.40",
});
