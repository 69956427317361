import { type Color } from "@thekeytechnology/epic-ui";
import { RadioButton } from "primereact/radiobutton";
import styled from "styled-components";

export const IconContainer = styled.div<{ iconBackgroundColor?: Color }>`
	width: 1.365rem;
	height: 1.365rem;
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${({ theme, iconBackgroundColor }) =>
		iconBackgroundColor ?? theme.colors.shade0};
`;

export const StyledRadioButton = styled(RadioButton)`
	box-sizing: border-box;
	width: 1.5rem;
	height: 1.5rem;

	/* Default */
	&.p-radiobutton .p-radiobutton-box {
		width: 1.5rem;
		height: 1.5rem;
		border: none;

		background: transparent;
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.shade40} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box {
		background: ${({ theme }) => theme.colors.secondary100};
		box-shadow: none;
		.p-radiobutton-icon {
			background-color: ${({ theme }) => theme.colors.onSecondary};
			width: 0.5rem;
			height: 0.5rem;
		}
	}

	/* Focus */
	&.p-radiobutton .p-radiobutton-box.p-focus {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.info100} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box.p-focus {
		background: ${({ theme }) => theme.colors.secondary100};
		.p-radiobutton-icon {
			background-color: ${({ theme }) => theme.colors.onSecondary};
		}
	}

	/* Hover */
	&.p-radiobutton .p-radiobutton-box:hover {
		background: transparent;
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.shade20} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box:hover {
		background: ${({ theme }) => theme.colors.secondary80};
		box-shadow: none;
		.p-radiobutton-icon {
			background-color: ${({ theme }) => theme.colors.onSecondary};
		}
	}

	/* Disabled */
	&.p-radiobutton .p-radiobutton-box.p-disabled {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.shade10} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box.p-disabled {
		background: ${({ theme }) => theme.colors.shade5};
		box-shadow: none;
		.p-radiobutton-icon {
			background-color: ${({ theme }) => theme.colors.shade20};
		}
	}
	&.p-radiobutton.p-radiobutton-disabled:hover {
		cursor: default !important;
	}

	/* Error */
	&.p-radiobutton.p-invalid .p-radiobutton-box {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.error100} inset;
	}

	&.p-radiobutton.p-radiobutton-checked.p-invalid .p-radiobutton-box {
		background: ${({ theme }) => theme.colors.error20};
		.p-radiobutton-icon {
			background-color: ${({ theme }) => theme.colors.shade0};
		}
	}
	&.p-radiobutton.p-radiobutton-checked.p-invalid .p-radiobutton-box:hover {
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.error100} inset;
	}
`;
