import { Icon, UserAvatar } from "@thekeytechnology/academies-lib-webapp";
import { motion } from "framer-motion";
import { useFragment } from "react-relay";
import { cx } from "@styled-system/css";
import { colorShade0Class, colorShade80Class } from "@themes/color-classes";
import { shade80 } from "@themes/colors";
import { H1Span, H3Span, L1Span, P2Span, P3Span } from "@themes/font-tags";
import { greetingForTimeOfDay } from "@utils/greeting";
import { USER_HEADER_FRAGMENT } from "./user-header.graphql";
import {
	gradientWrapperClass,
	userDataClass,
	userDataWrapperClass,
	userEditButtonClass,
	userLevelAndButtonWrapperClass,
	userLevelProgressBarClass,
	userLevelProgressClass,
	userLevelProgressWrapperClass,
	userLevelTitleClass,
	userLevelWrapperClass,
} from "./user-header.styles";
import { UserHeaderProps } from "./user-header.types";

export const UserHeader = ({ className, onEditButtonClick, userFragmentRef }: UserHeaderProps) => {
	const data = useFragment(USER_HEADER_FRAGMENT, userFragmentRef ?? null);

	if (!data) return null;

	const academiesUserExtension = data.extensions?.find((e) => e.kind === "Academies");
	const tkaUserExtension = data.extensions?.find((e) => e.kind === "Tka");

	const firstName = academiesUserExtension?.firstName;
	const lastName = academiesUserExtension?.lastName;

	const userLevel = academiesUserExtension?.level ?? 0;
	const userLevelProgress = academiesUserExtension?.progressInPercent ?? 0;
	const userLevelTitle = tkaUserExtension?.levelDefinition?.title;

	return (
		<div className={cx(gradientWrapperClass, className)}>
			<div className={userDataWrapperClass}>
				{data && <UserAvatar userFragmentRef={data} sizeInRem={5} />}
				<div className={userDataClass}>
					<P3Span>{greetingForTimeOfDay()}</P3Span>
					<H1Span>
						{firstName} {lastName}
					</H1Span>
					<P2Span>
						Schön, dass Du wieder da bist. Schau Dich doch mal bei
						<br />
						den Kursen um. Viel Spaß mit der thekey.academy
					</P2Span>
				</div>
			</div>
			<div className={userLevelAndButtonWrapperClass}>
				<button type="button" onClick={onEditButtonClick} className={userEditButtonClass}>
					<Icon icon="pencil" sizeRem={0.75} color={shade80} />
					<L1Span className={colorShade80Class}>Edit</L1Span>
				</button>
				<div className={userLevelWrapperClass}>
					<div className={userLevelTitleClass}>
						<H3Span className={colorShade0Class}>{userLevelTitle}</H3Span>
						<P3Span className={colorShade0Class}>Level {userLevel}</P3Span>
					</div>
					<div className={userLevelProgressWrapperClass}>
						<div className={userLevelProgressClass}>
							<motion.div
								animate={{
									width: userLevelProgress,
								}}
								transition={{
									type: "spring",
									stiffness: 210,
									damping: 16,
								}}
								className={userLevelProgressBarClass}
							/>
						</div>
						<P3Span className={colorShade0Class}>{userLevelProgress}%</P3Span>
					</div>
				</div>
			</div>
		</div>
	);
};
