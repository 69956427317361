import { graphql } from "react-relay";

export const USER_FRAGMENT = graphql`
	fragment userCard_UserInAccountWrapperFragment on UserInAccountWrapper {
		id
		name
		isManager
		groups {
			id
			name
		}
		user {
			user {
				id
				name
				email
				...userAvatar_UserFragment
			}
		}
	}
`;
