import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	justifyContent: "space-between",
	flex: "1",
	gap: "16",
	md: {
		flexDirection: "row",
		alignItems: "center",
	},
});

export const leftWrapperClass = stack({});

export const textWrapperClass = css({
	alignSelf: "end",
	whiteSpace: "nowrap",
	md: {
		alignSelf: "center",
	},
});
