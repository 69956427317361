import { graphql } from "react-relay";

export const QUERY = graphql`
	query shoppingCartOverview_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartOverview_OrderFragment
			}
		}
		Viewer {
			Auth {
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartOverview_OrderFragment on Order {
		id
		cart {
			items {
				kind
				... on LimitedDiscountItem {
					title
					value
				}
				... on DiscountItem {
					validUntil
					code
					title
					value
				}
				... on GlobalDiscountItem {
					title
					value
				}
				... on ProductItem {
					amount
					product {
						id
						title
						netPrice
						data {
							... on LicenseProductData {
								licenseDefinitionAmount
								licenseDefinition {
									data {
										kind
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			totals {
				withoutDiscounts {
					grossPrice
				}
				includingAllDiscounts {
					grossPrice
					netPrice
				}
				monthlyOptions {
					downPayment {
						grossPrice
						netPrice
						taxRatePercentage
					}
					financedAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					monthlyRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					rateCount
					totalAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					totalAmount {
						grossPrice
					}
				}
			}
		}
		selectedPaymentMethod {
			kind
			paymentMethodType
			... on OneTimePaymentMethod {
				stripeData {
					paymentIntentSecret
				}
			}
			... on MonthlyPaymentMethod {
				chosenOption {
					downPayment {
						grossPrice
						netPrice
						taxRatePercentage
					}
					financedAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					monthlyRate {
						grossPrice
						netPrice
						taxRatePercentage
					}
					rateCount
					totalAmount {
						grossPrice
						netPrice
						taxRatePercentage
					}
					totalAmount {
						grossPrice
					}
				}
			}
		}
		customerDetails {
			data {
				kind
				... on Billing_BusinessCustomerDetails {
					company
				}
			}
			invoiceEmail
			firstName
			lastName
			street
			houseNumber
			city
			postalCode
			country
		}
		allowedPaymentMethods {
			paymentMethods
		}
		...shoppingCartLegalForm_OrderFragment
		...shoppingCartTemplate_OrderFragment
	}
`;

export const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
	mutation shoppingCartOverview_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
		Billing {
			changePaymentMethod(input: $input) {
				order {
					...shoppingCartOverview_OrderFragment
				}
			}
		}
	}
`;

export const PAY_ORDER_MUTATION = graphql`
	mutation shoppingCartOverview_PayOrderMutation($input: PayOrderInput!) {
		Billing {
			payOrder(input: $input) {
				order {
					id
				}
			}
		}
	}
`;
