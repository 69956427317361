import { graphql } from "react-relay";

export const LICENSE_GROUPING_FRAGMENT = graphql`
	fragment licensesGroupCard_LicenseGroupingFragment on LicenseGrouping {
		id
		kind
		totalLicenseAmount
		usedLicenseAmount
		totalLicenseAmountForUser
		usedLicenseAmountForUser
		assignmentRules {
			licenseGroupingId
		}
		... on LearnableLicenseGrouping {
			learnable {
				kind
				... on PublishedCourseLearnable {
					root {
						structureDefinition {
							title
						}
						id
						description
						image {
							thumbnail
						}
					}
				}
			}
		}
	}
`;
