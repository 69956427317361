import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "12",
});

export const deleteIconWrapperClass = css({
	paddingRight: "12",
});

export const nameAndGroupsWrapperClass = css({
	display: "flex",
	alignItems: "center",
	flex: "1",
});

export const labelsWrapperClass = css({
	display: "flex",
	marginLeft: "16",
	gap: "8",
	padding: "4",
});
