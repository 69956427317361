import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const carouselWrapperClass = css({
	mdDown: {
		pr: "32",
		mb: "[12em]",
	},
});

export const pagesGroupClass = flex({
	gap: "32",
	mdDown: {
		flexDirection: "column",
	},
});
