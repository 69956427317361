/**
 * @generated SignedSource<<a42432e477b75c091b420b65d4363d13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LicenseAvailabilityKind = "FreeLicensesAvailable" | "NoneAvailable";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type rootProgress_LearnOpportunityV2Fragment$data = {
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly structureDefinition: {
    readonly extension?: {
      readonly licenseAvailability?: {
        readonly kind: LicenseAvailabilityKind;
        readonly numAvailable?: number;
      };
      readonly product?: {
        readonly isTaxFree: boolean;
        readonly netPrice: number;
      } | null;
      readonly receivedGamificationPoints?: number;
      readonly reducedData?: {
        readonly receivableGamificationPoints: number;
      } | null;
      readonly unlockInfo?: {
        readonly kind: UnlockInfoKind;
      };
    };
    readonly progressPercentage?: number | null;
    readonly title: string;
    readonly viewerTreeState?: {
      readonly kind: ViewerTreeStateKind;
    };
  };
  readonly " $fragmentType": "rootProgress_LearnOpportunityV2Fragment";
};
export type rootProgress_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: rootProgress_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rootProgress_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rootProgress_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "progressPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "viewerTreeState",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "receivedGamificationPoints",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedData",
                      "kind": "LinkedField",
                      "name": "reducedData",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "receivableGamificationPoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "licenseAvailability",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "numAvailable",
                              "storageKey": null
                            }
                          ],
                          "type": "LicenseAvailabilityFreeLicensesAvailable",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "unlockInfo",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Product",
                      "kind": "LinkedField",
                      "name": "product",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "netPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isTaxFree",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PublishedRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "a750db85e44b051d67128a1deca06942";

export default node;
