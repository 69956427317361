import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { usePermissions } from "@thekeytechnology/academies-lib-webapp/hooks/use-permissions";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { type FC, useContext } from "react";
import { SearchBar } from "@screens/account/parts/search-bar";
import { SearchBarMobile } from "@screens/account/parts/search-bar/search-bar-mobile";
import { colorShade100Class } from "@themes/color-classes";
import { H2Span } from "@themes/font-tags";
import {
	buttonWrapperClass,
	headerWrapperClass,
	labelsWrapperClass,
	memberAndLabelsWrapperClass,
	searchAndFiltersWrapperClass,
	searchBarMobileWrapperClass,
} from "./header.styles";
import { HeaderProps } from "./header.types";
import { MembersTabContext } from "../../members-tab.context";
import { MembersFilter } from "../members-filter/members-filter.component";

export const Header: FC<HeaderProps> = ({
	searchIsVisibleOnMobile,
	handleToggleOnClick,
	handleSearchOnChange,
	handleInviteOnClick,
	searchByNameOrEmail,
	userInAccountGroupQueryFragment,
}) => {
	const { numberOfConfirmedMembers, numberOfUnconfirmedMembers } = useContext(MembersTabContext);
	const { isAccountOwner } = usePermissions();
	const { isMediumUp } = useWindowSize();
	return (
		<div className={headerWrapperClass}>
			<div className={memberAndLabelsWrapperClass}>
				<H2Span className={colorShade100Class}>Mitglieder</H2Span>
				<div className={labelsWrapperClass}>
					<Label label={`${numberOfConfirmedMembers} Mitglieder`} />
					<Label label={`${numberOfUnconfirmedMembers} unbestätigt`} />
				</div>
			</div>
			<div className={searchAndFiltersWrapperClass}>
				<SearchBar
					onClick={handleToggleOnClick}
					onChange={handleSearchOnChange}
					value={searchByNameOrEmail}
				/>
				<MembersFilter queryFragmentRef={userInAccountGroupQueryFragment} />
			</div>
			{searchIsVisibleOnMobile && (
				<div className={searchBarMobileWrapperClass}>
					<SearchBarMobile onChange={handleSearchOnChange} value={searchByNameOrEmail} />
				</div>
			)}
			{isAccountOwner && (
				<div className={buttonWrapperClass}>
					<Button
						colorVersion="outline"
						label="Hinzufügen"
						iconName="add"
						onClick={handleInviteOnClick}
						fillParent={!isMediumUp}
					/>
				</div>
			)}
		</div>
	);
};
