import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { ReactComponent as QuoteIcon } from "@assets/quotation_mark.svg";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	marketingImageClass,
	quoteAuthorClass,
	quoteClass,
	quoteHeadlineClass,
	quoteWrapperClass,
	wrapperClass,
} from "./login-marketing.styles";

export const LoginMarketing = () => {
	const { t } = useTkaTranslation("authLayout");

	return (
		<div className={wrapperClass}>
			<blockquote className={quoteClass}>
				<div className={quoteWrapperClass}>
					<QuoteIcon />
					<H1Span className={quoteHeadlineClass}>
						{t("auth_layout.testimonialContent")}
					</H1Span>
					<P2Span className={quoteAuthorClass}>
						{t("auth_layout.testimonialAuthor")}
						{t("auth_layout.testimonialAuthorExtra")}
					</P2Span>
				</div>
				<img
					className={marketingImageClass}
					src="/marketing_image.jpg"
					alt="Marketing Image"
				/>
			</blockquote>
		</div>
	);
};
