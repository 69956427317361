import { AuthTemplate } from "@components/auth-template";
import { SignUpSteps } from "./parts/sign-up-steps";
import { LoginMarketing } from "../login/parts/login-marketing";

export const SignUpScreen = () => {
	return (
		<AuthTemplate rightSide={<LoginMarketing />}>
			<SignUpSteps />
		</AuthTemplate>
	);
};
