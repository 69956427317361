import { EpicP2Span } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";
import { RadioButton, RadioButtonStatus } from "@components/radio-button";
import { type RadioOptionProps } from "./radio-option.interface";
import { Wrapper } from "./radio-option.styles";

export const RadioOption = ({ label, tkaColor, ...props }: RadioOptionProps) => {
	const theme = useTheme();

	const labelColor =
		tkaColor ?? props.status === RadioButtonStatus.Disabled ? theme.colors.shade20 : undefined;
	return (
		<Wrapper>
			<RadioButton {...props} />
			{label && <EpicP2Span $color={labelColor}>{label}</EpicP2Span>}
		</Wrapper>
	);
};
