import { flex } from "@styled-system/patterns";
import styled from "styled-components";
import tw from "twin.macro";

export const wrapperClass = flex({
	alignItems: "center",
	justifyContent: "center",
	width: "24",
	height: "24",
	borderRadius: "12",
	backgroundColor: "shade.5",
	cursor: "pointer",
	_disabled: {
		cursor: "default",
	},
});

export const Wrapper = styled.div<{ disabled?: boolean }>`
	${tw`
		w-[1.5rem]
		h-[1.5rem]
		flex
		items-center
		justify-center
		bg-shade-5
		rounded-12
	`}
	${({ disabled }) => (disabled ? tw`hover:cursor-default` : tw`hover:cursor-pointer`)}
`;
