import { flex } from "@styled-system/patterns";

export const legalWrapperClass = flex({
	direction: "column",
	gap: "16",
});

export const legalConditionsClass = flex({
	direction: "column",
	gap: "12",
});

export const legalConditionRowClass = flex({
	direction: "row",
	gap: "12",
});
