import { H1Span } from "@thekeytechnology/epic-ui";
import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { header } from "./notes.styles";
import { NotesTabbarSkeleton } from "./parts/notes-tabbar/notes-tabbar.skeleton";

export const NotesScreenSkeleton = () => {
	return (
		<ScreenWithProfileMenuLayout bottomContent={<BottomNavigationTemplateSkeleton />}>
			<div className={header}>
				<H1Span>Notizen</H1Span>
				<NotesTabbarSkeleton />
			</div>
		</ScreenWithProfileMenuLayout>
	);
};
