import { graphql } from "react-relay";

export const QUERY = graphql`
	query root_GetRootQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				root {
					id
					...rootProgress_LearnOpportunityV2Fragment
					structureDefinition {
						title
						... on LearnOpportunityRootStructureDefinition {
							extension {
								... on PublishedRootExtensionImpl {
									reducedData {
										isIHK
										receivableGamificationPoints
									}
									unlockInfo {
										kind
									}
									trailer {
										url
									}
									product {
										id
										title
									}
									licenseAvailability {
										... on LicenseAvailabilityFreeLicensesAvailable {
											numAvailable
										}
									}
									cartClickout {
										relativeLink
									}
									learnGoals
									ihkState
								}
							}
							viewerTreeState {
								kind
								... on IsStartedViewerTreeState {
									headContentId
								}
							}
						}
					}
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								typeDefinition {
									... on LearnOpportunityBranchTypeDefinition {
										children {
											id
										}
									}
								}
							}
						}
					}
					instructorsV2 {
						edges {
							__typename
						}
					}
					...branchNodesTab_LearnOpportunityV2Fragment
					...materialsTab_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;

export const ADD_ITEM_TO_USER_CART_MUTATION = graphql`
	mutation root_AddItemToUserCartMutation($input: AddItemToUserCartInput!) {
		Viewer {
			Billing {
				addItemToUserCart(input: $input) {
					userCart {
						...userCart_UserCartFragment
					}
				}
			}
		}
	}
`;

export const BOOK_TREE_MUTATION = graphql`
	mutation root_BookTreeMutation($input: BookTreeInput!) {
		Billing {
			bookTree(input: $input) {
				clientMutationId
				root {
					...advanceTreeNavigation_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;
