import { css, cva } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	paddingTop: "20",
	paddingBottom: "16",
	md: {
		paddingTop: "0",
		paddingBottom: "0",
	},
});

export const optionsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	paddingY: "8",
});

export const itemWrapperClass = css({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "12",
	paddingY: "16",
	md: {
		gap: "8",
		paddingY: "8",
		paddingX: "16",
	},
	_hover: {
		opacity: 0.6,
		cursor: "pointer",
	},
});

export const titleWrapperClass = css({
	display: "flex",
	flexDirection: "column",
});

export const itemLabelSpanClass = cva({
	base: {
		textStyle: "p1",
		md: {
			textStyle: "p2",
		},
	},
	variants: {
		color: {
			error: {
				color: "error.100",
			},
		},
	},
});
