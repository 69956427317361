import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	position: "relative",
	width: "full",
	height: "full",
	flex: "1",
	flexDirection: "column",
	overflowY: "hidden",
});

export const contentWrapperClass = css({
	flex: "1",
	overflowY: "auto",
	height: "full",
});

export const textWrapperClass = css({
	rowGap: "12",
});

export const spacing16Class = css({
	mb: "16",
});

export const gapTextWrapperClass = css({
	display: "inline-block",
	mx: "8",
});

export const fixedBottomWrapperClass = css({
	position: "fixed",
	width: "full",
	zIndex: "20",
	bottom: "var(--bottom-px)",
	xl: {
		display: "none",
	},
});

export const relativeBottomWrapperClass = css({
	visibility: "hidden",
	xl: {
		visibility: "visible",
	},
});
