import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment privateClientForm_BaseDataFragment on baseData {
		street
		houseNumber
		postalCode
		city
		__typename
		... on PrivateBaseData {
			phoneNumber
			firstName
			lastName
			title
			salutation
		}
	}
`;
