import { Color } from "@thekeytechnology/epic-ui";
import { Recursive, Token } from "@styled-system/types/composition";
/**
 * @deprecated This class should not be used
 */
export class TkaColor extends Color {
	constructor(color: string, alpha: number = 1) {
		super(color, alpha);
	}

	public withAlpha(alpha: number): TkaColor {
		return new TkaColor(this.hexValue(), alpha);
	}

	getColor(alpha?: number): string {
		if (alpha) {
			return this.withAlpha(alpha).toString();
		}

		return this.toString();
	}
}

/**
 * @deprecated This color should not be used
 */
export const brandStrong20: TkaColor = new TkaColor("rgb(212, 250, 235)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong40: TkaColor = new TkaColor("rgb(169, 245, 215)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong80: TkaColor = new TkaColor("rgb(82, 234, 175)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong100: TkaColor = new TkaColor("rgb(39, 229, 155)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong120: TkaColor = new TkaColor("rgb(7, 158, 131)");

/**
 * @deprecated This color should not be used
 */
export const brandMain20: TkaColor = new TkaColor("rgb(230, 229, 239)");
/**
 * @deprecated This color should not be used
 */
export const brandMain40: TkaColor = new TkaColor("rgb(114, 112, 207)");
/**
 * @deprecated This color should not be used
 */
export const brandMain80: TkaColor = new TkaColor("rgb(83, 39, 230)");
/**
 * @deprecated This color should not be used
 */
export const brandMain100: TkaColor = new TkaColor("rgb(41, 19, 113)");

/**
 * @deprecated This color should not be used
 */
export const onBrandMain: TkaColor = new TkaColor("rgb(244, 248, 249)");
/**
 * @deprecated This color should not be used
 */
export const onBrandStrong: TkaColor = new TkaColor("rgb(41, 19, 113)");

/**
 * @deprecated This color should not be used
 */
export const shade0: TkaColor = new TkaColor("rgb(255, 255, 255)");
/**
 * @deprecated This color should not be used
 */
export const shade5: TkaColor = new TkaColor("rgb(246, 247, 249)");
/**
 * @deprecated This color should not be used
 */
export const shade10: TkaColor = new TkaColor("rgb(234, 237, 240)");
/**
 * @deprecated This color should not be used
 */
export const shade20: TkaColor = new TkaColor("rgb(217, 222, 226)");
/**
 * @deprecated This color should not be used
 */
export const shade40: TkaColor = new TkaColor("rgb(149, 159, 178)");
/**
 * @deprecated This color should not be used
 */
export const shade60: TkaColor = new TkaColor("rgb(93, 109, 131)");
/**
 * @deprecated This color should not be used
 */
export const shade80: TkaColor = new TkaColor("rgb(45, 68, 97)");
/**
 * @deprecated This color should not be used
 */
export const shade100: TkaColor = new TkaColor("rgb(6, 31, 62)");

/**
 * @deprecated This color should not be used
 */
export const success0: TkaColor = new TkaColor("rgb(229, 252, 243)");
/**
 * @deprecated This color should not be used
 */
export const success20: TkaColor = new TkaColor("rgb(169, 245, 215)");
/**
 * @deprecated This color should not be used
 */
export const success100: TkaColor = new TkaColor("rgb(7, 158, 131)");

/**
 * @deprecated This color should not be used
 */
export const info0: TkaColor = new TkaColor("rgb(240, 247, 255)");
/**
 * @deprecated This color should not be used
 */
export const info20: TkaColor = new TkaColor("rgb(219, 237, 255)");
/**
 * @deprecated This color should not be used
 */
export const info100: TkaColor = new TkaColor("rgb(38, 148, 254)");
/**
 * @deprecated This color should not be used
 */
export const info120: TkaColor = new TkaColor("rgb(8, 38, 120)");

/**
 * @deprecated This color should not be used
 */
export const warning0: TkaColor = new TkaColor("rgb(255, 252, 239)");
/**
 * @deprecated This color should not be used
 */
export const warning20: TkaColor = new TkaColor("rgb(254, 238, 215)");
/**
 * @deprecated This color should not be used
 */
export const warning100: TkaColor = new TkaColor("rgb(249, 165, 39)");
/**
 * @deprecated This color should not be used
 */
export const warning120: TkaColor = new TkaColor("rgb(79, 36, 2)");

/**
 * @deprecated This color should not be used
 */
export const error0: TkaColor = new TkaColor("rgb(255, 242, 237)");
/**
 * @deprecated This color should not be used
 */
export const error20: TkaColor = new TkaColor("rgb(252, 227, 218)");
/**
 * @deprecated This color should not be used
 */
export const error100: TkaColor = new TkaColor("rgb(234, 90, 46)");
/**
 * @deprecated This color should not be used
 */
export const error120: TkaColor = new TkaColor("rgb(63, 10, 4)");

export const colors: Recursive<Token<string>> = {
	primary: {
		20: { value: "rgb(230, 229, 239)" },
		40: { value: "rgb(114, 112, 207)" },
		80: { value: "rgb(83, 39, 230)" },
		100: { value: "rgb(41, 19, 113)" },
	},
	secondary: {
		20: { value: "rgb(212, 250, 235)" },
		40: { value: "rgb(169, 245, 215)" },
		80: { value: "rgb(82, 234, 175)" },
		100: { value: "rgb(39, 229, 155)" },
		120: { value: "rgb(7, 158, 131)" },
	},
	onPrimary: { value: "rgb(244, 248, 249)" },
	onSecondary: { value: "rgb(41, 19, 113)" },
	shade: {
		0: { value: "rgb(255, 255, 255)" },
		5: { value: "rgb(246, 247, 249)" },
		10: { value: "rgb(234, 237, 240)" },
		20: { value: "rgb(217, 222, 226)" },
		40: { value: "rgb(149, 159, 178)" },
		60: { value: "rgb(93, 109, 131)" },
		80: { value: "rgb(45, 68, 97)" },
		100: { value: "rgb(6, 31, 62)" },
	},
	success: {
		0: { value: "rgb(229, 252, 243)" },
		20: { value: "rgb(169, 245, 215)" },
		100: { value: "rgb(7, 158, 131)" },
	},
	info: {
		0: { value: "rgb(240, 247, 255)" },
		20: { value: "rgb(219, 237, 255)" },
		100: { value: "rgb(38, 148, 254)" },
		120: { value: "rgb(8, 38, 120)" },
	},
	warning: {
		0: { value: "rgb(255, 252, 239)" },
		20: { value: "rgb(254, 238, 215)" },
		100: { value: "rgb(249, 165, 39)" },
		120: { value: "rgb(79, 36, 2)" },
	},
	error: {
		0: { value: "rgb(255, 242, 237)" },
		20: { value: "rgb(252, 227, 218)" },
		100: { value: "rgb(234, 90, 46)" },
		120: { value: "rgb(63, 10, 4)" },
	},
	transparent: { value: "rgba(0, 0, 0, 0)" },
};
