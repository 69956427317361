import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { TabPanel } from "primereact/tabview";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { RootProgressSkeleton } from "@components/root-progress/root-progress.skeleton";
import { useCurrentPath } from "@hooks/use-current-path";
import { ScreenWithNavbarSidemenuLayout } from "src/layouts/screen-with-navbar-sidemenu-layout";
import { BranchNodeTabSkeleton } from "./branch-nodes-tab/branch-nodes-tab.skeleton";
import { MaterialsTabSkeleton } from "./materials-tab/materials-tab.skeleton";
import { RootOverviewTabSkeleton } from "./root-overview-tab/root-overview-tab.skeleton";
import {
	paddingWrapperClass,
	sideMenuHeaderWrapperClass,
	sideMenuWrapperClass,
	subpathBackgroundClass,
	wrapperClass,
} from "./root.styles";
import { getTabIndexForPath } from "./root.util";

export const RootScreenSkeleton = () => {
	const currentPath = useCurrentPath();
	const { isXLargeUp } = useWindowSize();
	const isRootOverviewPath = currentPath?.route.path === "overview";

	const [activeIndex] = useState(getTabIndexForPath(currentPath?.route.path));

	const tabBar = (
		<TabBar activeIndex={activeIndex}>
			<TabPanel headerTemplate={<Skeleton width="5rem" />} />
			<TabPanel headerTemplate={<Skeleton width="5rem" />} />
			<TabPanel headerTemplate={<Skeleton width="5rem" />} />
		</TabBar>
	);

	return (
		<ScreenWithNavbarSidemenuLayout
			noSideMenuPadding
			canGoBack={!isXLargeUp}
			sideMenu={
				<div className={sideMenuWrapperClass}>
					<div className={sideMenuHeaderWrapperClass}>
						<RootProgressSkeleton />
						{tabBar}
					</div>
					<TabBar
						hideHeader
						renderActiveOnly={false}
						activeIndex={activeIndex}
						onTabChange={() => {
							//ignore just required from primereact to work with activeIndex
						}}
					>
						<TabPanel>
							<RootOverviewTabSkeleton />
						</TabPanel>
						<TabPanel>
							<BranchNodeTabSkeleton />
						</TabPanel>
						<TabPanel>
							<MaterialsTabSkeleton />
						</TabPanel>
					</TabBar>
				</div>
			}
			bottomContent={<AdvanceTreeNavigation />}
		>
			<div className={wrapperClass}>
				{!isXLargeUp && <RootProgressSkeleton />}
				{!isRootOverviewPath ? (
					<div className={subpathBackgroundClass}>
						<Outlet />
					</div>
				) : (
					<div className={paddingWrapperClass}>
						<Outlet />
					</div>
				)}
			</div>
		</ScreenWithNavbarSidemenuLayout>
	);
};
