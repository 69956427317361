import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	marginTop: "40",
});

export const bottomBatchEditSpacerClass = css({
	height: "4",
	md: {
		display: "none",
	},
});
