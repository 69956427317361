import { css } from "@styled-system/css";
import { flex, grid } from "@styled-system/patterns";

export const formClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "12",
	height: "full",
	minWidth: "[300px]",
});

export const intervalWrapperClass = flex({
	flexDirection: "column",
	gap: "8",
	bg: "shade.5",
	padding: "12",
	borderRadius: "12",
});

export const intervalIntervalWrapperClass = flex({
	width: "full",
	flexDirection: "column",
	gap: "2",
});

export const intervalFieldsWrapperClass = grid({
	gridTemplateColumns: "2",
});

export const intervalDropdownClass = css({
	position: "relative",
	zIndex: "10",
});
