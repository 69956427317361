import { Icon, useWindowSize } from "@thekeytechnology/academies-lib-webapp";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ControlButton } from "@components/control-button";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { userCart_ChangeUserCartMutation } from "@relay/userCart_ChangeUserCartMutation.graphql";
import { userCart_GenerateLinkForUserCartMutation } from "@relay/userCart_GenerateLinkForUserCartMutation.graphql";
import { userCart_Query } from "@relay/userCart_Query.graphql";
import { userCart_UserCartFragment$key } from "@relay/userCart_UserCartFragment.graphql";
import { Path } from "@router/paths";
import { colorShade100Class, colorShade80Class, colorSuccess100Class } from "@themes/color-classes";
import { shade20 } from "@themes/colors";
import { H2Span, H3Span, P1Span, P3Span } from "@themes/font-tags";
import { formatDateTime } from "@utils/date-utils";
import {
	CHANGE_USER_CART_MUTATION,
	GENERATE_LINK_FOR_USER_CART_MUTATION,
	QUERY,
	USER_CART_FRAGMENT,
} from "./user-cart.graphql";
import {
	buttonClass,
	controlAmountTextClass,
	controlWrapperClass,
	currencyTextClass,
	deleteButtonClass,
	dividerClass,
	monthlyRateTextClass,
	openButtonClass,
	openButtonIndicatorClass,
	popoverWrapperClass,
	pricesItemClass,
	pricesWrapperClass,
	productLeftClass,
	productPriceClass,
	productRightClass,
	productWrapperClass,
} from "./user-cart.styles";
import {
	getCartDiscountItems,
	getCartLimitedDiscountItems,
	getProductItems,
} from "./user-cart.utils";

export const UserCart = () => {
	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();

	const query = useLazyLoadQuery<userCart_Query>(
		QUERY,
		{},
		{
			fetchPolicy: "network-only",
		},
	);
	const userCart = useFragment<userCart_UserCartFragment$key>(
		USER_CART_FRAGMENT,
		query.Viewer.Billing.GetUserCart,
	);

	const [generateLinkForUserCart] = useMutation<userCart_GenerateLinkForUserCartMutation>(
		GENERATE_LINK_FOR_USER_CART_MUTATION,
	);
	const [changeUserCart] =
		useMutation<userCart_ChangeUserCartMutation>(CHANGE_USER_CART_MUTATION);

	const [open, setOpen] = useState(false);

	const cart = userCart?.cart;
	const products = getProductItems(cart);
	const cartDiscounts = getCartDiscountItems(cart) ?? [];
	const cartLimitedDiscounts = getCartLimitedDiscountItems(cart) ?? [];
	const discounts = [...cartLimitedDiscounts, ...cartDiscounts];

	const totals = cart?.totals;
	const netPriceWithoutDiscounts = totals?.withoutDiscounts.netPrice;
	const appliedDiscountGross = totals?.appliedDiscount?.grossPrice;
	const totalGrossPrice = totals?.includingAllDiscounts?.grossPrice;
	const taxes = totals?.withoutDiscounts
		? totals.withoutDiscounts.grossPrice - totals.withoutDiscounts.netPrice
		: undefined;
	const smallestMonthlyRate = cart?.totals.monthlyOptions.length
		? cart?.totals.monthlyOptions
				.slice()
				.sort((a, b) => a.monthlyRate.grossPrice - b.monthlyRate.grossPrice)[0].monthlyRate
				.grossPrice
		: undefined;

	const hasDiscounts = discounts.length > 0;
	const hasProducts = products && products.length !== 0;
	const hasProductWithTaxes = products && products.some((product) => !product.product?.isTaxFree);

	const handleGenerateLinkOnClick = () => {
		if (!hasProducts) {
			navigate(Path.progression.path);
			return;
		}

		generateLinkForUserCart({
			variables: {
				input: {},
			},
			onCompleted: (response) => {
				const link = response.Viewer.Billing.generateLinkForUserCart?.link;
				if (!link) return;
				window.location.href = link;
			},
		});
	};

	const handlePopoverButtonOnClick = () => {
		setOpen((p) => !p);
	};

	const createRemoveProductHandler = (id: string) => () => {
		const selection = cart?.selection;
		if (!selection) return;

		const selectedProducts = selection.selectedProducts
			.filter((product) => product?.product?.id !== id)
			.map((p) => ({
				productId: p.product?.id ?? "",
				amount: p.amount,
			}));

		changeUserCart({
			variables: {
				input: {
					selection: {
						selectedProducts,
						selectedDiscountCodes: cart?.selection?.selectedDiscountCodes,
					},
				},
			},
		});
	};

	const createChangeProductAmountHandler = (id: string, operation?: "add" | "subtract") => () => {
		const selection = cart?.selection;
		if (!selection) return;

		const selectedProducts = selection.selectedProducts
			.map((p) => {
				let amount = p.amount;

				if (p.product?.id === id) {
					if (operation === "add") {
						amount = amount + 1;
					} else {
						amount = amount - 1;
					}
				}

				if (amount < 1) {
					return undefined;
				}

				return {
					productId: p.product?.id ?? "",
					amount,
				};
			})
			.filter(Boolean);

		changeUserCart({
			variables: {
				input: {
					selection: {
						selectedProducts,
						selectedDiscountCodes: cart?.selection?.selectedDiscountCodes,
					},
				},
			},
		});
	};

	const handleDismiss = () => {
		setOpen(false);
	};

	const content = (
		<>
			<div>
				{hasProducts &&
					products.map(({ product, amount }) => {
						return (
							<div key={product?.id} className={productWrapperClass}>
								<div className={productLeftClass}>
									{product?.data?.licenseDefinition?.data.rootInfo?.image
										?.url && (
										<img
											src={
												product?.data?.licenseDefinition?.data.rootInfo
													?.image?.url
											}
											alt=""
											aria-hidden="true"
										/>
									)}
									<div className={controlWrapperClass}>
										<ControlButton
											iconName="remove"
											onClick={createChangeProductAmountHandler(
												product?.id ?? "",
												"subtract",
											)}
											disabled={(amount ?? 1) <= 1}
										/>
										<H2Span className={controlAmountTextClass}>{amount}</H2Span>
										<ControlButton
											iconName="add"
											onClick={createChangeProductAmountHandler(
												product?.id ?? "",
												"add",
											)}
										/>
									</div>
								</div>
								<div className={productRightClass}>
									<div>
										<H3Span>{product?.title ?? ""}</H3Span>
									</div>
									<div className={productPriceClass}>
										<H3Span className={colorShade100Class}>
											{formatCurrency(product?.netPrice ?? 0)}
										</H3Span>
										{product?.isTaxFree && (
											<P3Span className={colorShade80Class}>
												(MwSt. befreit)
											</P3Span>
										)}
									</div>
								</div>
								<button
									type="button"
									onClick={createRemoveProductHandler(product?.id ?? "")}
									className={deleteButtonClass}
								>
									<Icon icon="trash" color={shade20} />
								</button>
							</div>
						);
					})}
			</div>

			{!hasProducts && (
				<EmptyPlaceholder
					iconName="shoppingBasketSad1"
					title="Dein Warenkorb ist leer"
					subtitle="Füge Produkte hinzu, um dein Warenkorb zu verwalten"
				/>
			)}

			{hasProducts && <hr className={dividerClass} />}

			{hasDiscounts && (
				<div>
					{discounts.map((discount) => (
						<div key={discount.code}>
							<div>
								{discount.validUntil && (
									<Label
										label={`Noch bis ${formatDateTime(discount.validUntil)}`}
										severity="info"
									/>
								)}
								<div>
									<H2Span>{discount.title ?? "Rabatt"}</H2Span>
									<P1Span>{discount.code ?? "Rabatt Aktion"}</P1Span>
								</div>
							</div>
							<div>
								{discount.kind === "Discount" && discount.code && (
									<Icon color={shade20} icon="trash" />
								)}
								<H2Span>-{discount.value}%</H2Span>
							</div>
						</div>
					))}
				</div>
			)}

			{hasDiscounts && <hr className={dividerClass} />}

			{hasProducts && (
				<div className={pricesWrapperClass}>
					<div className={pricesItemClass}>
						<P1Span>Zwischensumme</P1Span>
						<P1Span className={currencyTextClass()}>
							{formatCurrency(netPriceWithoutDiscounts)}
						</P1Span>
					</div>
					{hasProductWithTaxes && (
						<div className={pricesItemClass}>
							<P1Span>
								Mehrwertsteuer {totals?.withoutDiscounts.taxRatePercentage}%
							</P1Span>
							<P1Span className={currencyTextClass()}>{formatCurrency(taxes)}</P1Span>
						</div>
					)}
					{appliedDiscountGross !== undefined && appliedDiscountGross > 0 && (
						<div className={pricesItemClass}>
							<P1Span className={colorSuccess100Class}>
								Rabatt -{totals?.appliedDiscountPercentage}%
							</P1Span>
							<P1Span
								className={currencyTextClass({
									color: "success",
								})}
							>
								{formatCurrency(appliedDiscountGross)}
							</P1Span>
						</div>
					)}
					<div className={pricesItemClass}>
						<P1Span>Gesamtpreis</P1Span>
						<P1Span className={currencyTextClass()}>
							{formatCurrency(totalGrossPrice)}
						</P1Span>
					</div>
					<div className={pricesItemClass}>
						<div />
						<P1Span className={monthlyRateTextClass}>
							oder monatlich <wbr />
							<strong>ab {formatCurrency(smallestMonthlyRate)}</strong>
						</P1Span>
					</div>
				</div>
			)}

			<hr className={dividerClass} />

			<div>
				<Button
					label={hasProducts ? "Zum Warenkorb" : "Zu den Angeboten"}
					iconName={hasProducts ? "shoppingBasket" : "compass"}
					onClick={handleGenerateLinkOnClick}
					className={buttonClass}
				/>
			</div>
		</>
	);

	return (
		<div data-no-selection-menu>
			<button type="button" onClick={handlePopoverButtonOnClick} className={openButtonClass}>
				<Icon icon="shoppingBasket" sizeRem={1.5} />
				{hasProducts && <div className={openButtonIndicatorClass} />}
			</button>
			{open &&
				(isMediumUp ? (
					<div className={popoverWrapperClass}>{content}</div>
				) : (
					<ResponsiveBottomSheetModal isVisible onDismiss={handleDismiss}>
						{content}
					</ResponsiveBottomSheetModal>
				))}
		</div>
	);
};
