/**
 * @generated SignedSource<<e4ae1ba47db633c18657d4c149ea96db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type membersTab_UserInAccountGroupQueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"inviteMemberModal_QueryFragment" | "membersFilter_QueryFragment">;
  readonly " $fragmentType": "membersTab_UserInAccountGroupQueryFragment";
};
export type membersTab_UserInAccountGroupQueryFragment$key = {
  readonly " $data"?: membersTab_UserInAccountGroupQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"membersTab_UserInAccountGroupQueryFragment">;
};

import membersTab_UserInAccountGroupRefetchQuery_graphql from './membersTab_UserInAccountGroupRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": membersTab_UserInAccountGroupRefetchQuery_graphql
    }
  },
  "name": "membersTab_UserInAccountGroupQueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "membersFilter_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "inviteMemberModal_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "39d859faa3900aa9b08ccce51c5d90ae";

export default node;
