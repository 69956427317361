import { graphql } from "react-relay";

export const TEXT_WITH_PAGES_LEARN_ELEMENT = graphql`
	fragment textWithPagesLearnElement_TextWithPagesLearnElementFragment on TextWithPagesLearnElement {
		id
		title
		pages {
			id
			... on TextWithPagesPage {
				data {
					kind
					...textWithPagesElementPage_TextWithPagesPageFragment
				}
			}
		}
	}
`;
