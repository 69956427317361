import { css } from "@styled-system/css";
import { circle, flex } from "@styled-system/patterns";

export const messagePreviewCardClass = flex({
	basis: "[15em]",
	lgDown: {
		flexBasis: "[13em]",
	},
	mdDown: {
		flexBasis: "[10em]",
	},
	grow: 0,
	shrink: 0,
	position: "relative",
	_hover: {
		cursor: "pointer",
		transform: "rotate(-1deg) translateX(-5%) ",
		_after: {
			transform: "translateY(-48%) translateX(15%) rotate(5deg)",
		},
		_before: {
			transform: "translateY(-45%) translateX(40%) rotate(10deg)",
		},
	},
	transition: "all",
	transitionDuration: "200ms",
	transitionTimingFunction: "ease",
	_after: {
		display: "inline-block",
		transition: "all",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
		zIndex: "1",
		content: '""',
		bg: "primary.20",
		width: "1/3",
		right: "-16",
		top: "[50%]",
		height: "[94%]",
		position: "absolute",
		borderRadius: "8",
		transform: "translateY(-50%)",
	},
	_before: {
		transformOrigin: "center",
		transition: "all",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
		zIndex: "1",
		content: '""',
		bg: "primary.20",
		width: "1/3",
		right: "-32",
		top: "[50%]",
		height: "[90%]",
		position: "absolute",
		borderRadius: "8",
		transform: "translateY(-50%)",
		opacity: "[0.3]",
	},
});

export const messageImageClass = css({
	zIndex: "2",
	objectFit: "cover",
	borderRadius: "8",
	aspectRatio: "9 / 16",
	position: "relative",
});

export const unreadIndicatorOuterClass = circle({
	zIndex: "3",
	size: "[1em]",
	bg: "shade.0",
	position: "absolute",
	right: "[1em]",
	top: "[1em]",
});

export const unreadIndicatorInnerClass = circle({
	zIndex: "4",
	size: "[0.7em]",
	bg: "success.100",
});
