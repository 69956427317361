import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import tw from "twin.macro";
import { TkaP3Span } from "@themes/font-tags";

export const Content = tw(SideMenuContentPadding)`flex flex-col`;
export const Wrapper = tw.div`grid 
    grid-cols-1 
    lg:grid-cols-2 
    mt-12
    gap-x-24
    gap-y-12
`;
export const Spacing12 = tw.div`h-12`;
export const Spacing40 = tw.div`h-40`;
export const InstructionText = tw(TkaP3Span)`mt-32`;
