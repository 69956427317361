import { graphql } from "react-relay";

export const USER_HEADER_FRAGMENT = graphql`
	fragment userHeader_UserHeaderFragment on User {
		id
		name
		extensions {
			kind
			... on AcademiesUserExtension {
				firstName
				lastName
				level
				progressInPercent
				sumGamificationPoints
			}
			... on TkaUserExtension {
				levelDefinition {
					id
					title
				}
			}
		}
		...userAvatar_UserFragment
	}
`;
