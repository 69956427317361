import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { dataWrapperClass, iconWrapperClass, wrapperClass } from "./invoice-card.styles";

export const InvoiceCardSkeleton = () => {
	return (
		<li className={wrapperClass}>
			<div className={dataWrapperClass}>
				<div className={iconWrapperClass}>
					<Icon icon="paragraphTwoColumn" sizeRem={1.5} />
				</div>
				<div>
					<Skeleton width="6.25rem" height="1.125rem" />
					<Skeleton width="10rem" height="1.25rem" />
				</div>
			</div>
		</li>
	);
};
