import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";

// TODO: add-translations
export const AdvanceTreeNavigationSkeleton = () => {
	return (
		<>
			<BottomNavigationTemplateSkeleton showSecondaryButton />
		</>
	);
};
