import { ScrollPanel } from "primereact/scrollpanel";
import { GapText } from "@components/gap-text";
import { P3Span } from "@themes/font-tags";
import { wrapperClass, scrollPanelClass } from "./cloze-text-element-answers.styles";
import { ClozeTextElementAnswersProps } from "./cloze-text-element-answers.types";

export const ClozeTextElementAnswers = ({
	answers,
	answerOnClick,
}: ClozeTextElementAnswersProps) => {
	return (
		<div className={wrapperClass} onClick={(e) => e.stopPropagation()}>
			<P3Span>Wähle die passende Antwort aus.</P3Span>
			<ScrollPanel className={scrollPanelClass}>
				{answers.map((answer, index) => (
					<GapText
						key={`${answer.text}_${index}`}
						onClick={() => answerOnClick?.(answer.id)}
						text={answer.text}
						id={answer.id}
						draggable={true}
					/>
				))}
			</ScrollPanel>
		</div>
	);
};
