import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { P2Span } from "@themes/font-tags";
import { iconWrapperClass, wrapperClass } from "./payment-method-radio-button-template.styles";
import { PaymentMethodRadioButtonTemplateProps } from "./payment-method-radio-button-template.types";

export const PaymentMethodRadioButtonTemplate = ({
	icon,
	iconRenderer,
	title,
}: PaymentMethodRadioButtonTemplateProps) => {
	return (
		<div className={wrapperClass}>
			<div className={iconWrapperClass}>
				{icon && <Icon icon={icon} sizeRem={1.5} />}
				{iconRenderer && iconRenderer}
			</div>
			<P2Span>{title}</P2Span>
		</div>
	);
};
