import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import {
	Checkbox,
	CheckboxStatus,
} from "@thekeytechnology/academies-lib-webapp/components/checkbox";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { MaterialIcon } from "@thekeytechnology/academies-lib-webapp/components/material-icon";
import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { invitationCard_DeleteInvitationMutation } from "@relay/invitationCard_DeleteInvitationMutation.graphql";
import { AccountContext } from "@screens/account";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { colorShade20Class } from "@themes/color-classes";
import { shade20, shade5 } from "@themes/colors";
import { H3Span } from "@themes/font-tags";
import { DELETE_INVITATION_MUTATION, INVITATION_FRAGMENT } from "./invitation-card.graphql";
import {
	deleteIconWrapperClass,
	labelsWrapperClass,
	nameAndGroupsWrapperClass,
	wrapperClass,
} from "./invitation-card.style";
import { InvitationCardProps } from "./invitation-card.types";

export const InvitationCard = ({ invitationFragmentRef }: InvitationCardProps) => {
	const invitation = useFragment(INVITATION_FRAGMENT, invitationFragmentRef ?? null);
	const [deleteInvitation] = useMutation<invitationCard_DeleteInvitationMutation>(
		DELETE_INVITATION_MUTATION,
	);

	const { membersConnectionIds } = useContext(AccountContext);
	const { refetch } = useContext(RefetchContext);

	const handleDeleteOnClick = () => {
		invitation?.invitation?.id &&
			deleteInvitation({
				variables: {
					input: {
						id: invitation?.invitation.id,
					},
					connections: membersConnectionIds,
				},
				onCompleted: refetch,
			});
	};

	return (
		<Card bodyPaddingInRem={0.75} borderColor={shade20}>
			<div className={wrapperClass}>
				<Checkbox checked={false} status={CheckboxStatus.Disabled} />
				<MaterialIcon
					icon="user"
					iconColor={shade20}
					showBorder={false}
					backgroundColor={shade5}
				/>
				<div className={nameAndGroupsWrapperClass}>
					<H3Span className={colorShade20Class}>{invitation?.invitation?.email}</H3Span>
					<div className={labelsWrapperClass}>
						{invitation?.groups?.map((group: any) => (
							<Label key={group.id} size="smal" label={group.name} severity="info" />
						))}
					</div>
				</div>

				<div className={deleteIconWrapperClass}>
					<Icon icon="wrong" sizeRem={1} onClick={handleDeleteOnClick} />
				</div>
			</div>
		</Card>
	);
};
