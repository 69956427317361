import { css } from "@styled-system/css";

export const batchEditWrapperClass = css({
	position: "fixed",
	zIndex: "100",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "24",
	left: "24",
	right: "24",
	bottom: "[6.125rem]",
	paddingX: "16",
	paddingY: "12",
	backgroundColor: "shade.5",
	borderRadius: "12",
	border: "1px solid",
	borderColor: "shade.20",
	boxShadow:
		"[0.625rem 0.625rem 2.25rem 0rem token(colors.shade.100/0.1) -0.0625rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.03)]",
	md: {
		position: "static",
		bottom: "0",
		paddingX: "24",
		paddingY: "16",
		marginBottom: "16",
		boxShadow: "[none]",
	},
});

export const optionsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	flex: "1",
	md: {
		flexDirection: "row",
		gap: "24",
	},
});

export const itemWrapperClass = css({
	display: "flex",
	flexDirection: "row",
	gap: "12",
	paddingY: "16",
	md: {
		gap: "8",
		paddingX: "0",
		paddingY: "0",
	},
	_hover: {
		opacity: 0.6,
		cursor: "pointer",
	},
});

export const verticalDividerClass = css({
	bg: "shade.20",
	width: "[0.0625rem]",
	height: "16",
	md: {
		height: "24",
	},
});

export const actionWrapperClass = css({
	flex: "1",
	display: "flex",
	flexDirection: "row",
	gap: "8",
	_hover: {
		cursor: "pointer",
		opacity: 0.6,
	},
});

export const titleWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	mt: "16",
});

export const centerSpacerClass = css({
	mt: "8",
	mb: "24",
});

export const hoverOpacityClass = css({
	opacity: 0.6,
	cursor: "pointer",
});
