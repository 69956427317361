import { Children } from "react";
import { wrapperClass } from "./empty-placeholder.styles";
import { EmptyPlaceholderProps } from "./empty-placeholder.types";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";

export const EmptyPlaceholder = ({ isVisible, children, ...props }: EmptyPlaceholderProps) => {
	const hasChildren = Children.count(children) > 0;
	const showEmptyPlaceholder = isVisible || (isVisible === undefined && !hasChildren);

	return showEmptyPlaceholder ? (
		<div className={wrapperClass}>
			<IconHeadlineButtonTemplate {...props} />
		</div>
	) : (
		<>{children}</>
	);
};
