/**
 * @generated SignedSource<<fd304b13b3f02e195b6d7c6a434156df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserExtensionKind = "Academies" | "Tka";
import { FragmentRefs } from "relay-runtime";
export type userHeader_UserHeaderFragment$data = {
  readonly extensions: ReadonlyArray<{
    readonly firstName?: string;
    readonly kind: UserExtensionKind;
    readonly lastName?: string;
    readonly level?: number;
    readonly levelDefinition?: {
      readonly id: string;
      readonly title: string;
    };
    readonly progressInPercent?: number;
    readonly sumGamificationPoints?: number;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  readonly " $fragmentType": "userHeader_UserHeaderFragment";
};
export type userHeader_UserHeaderFragment$key = {
  readonly " $data"?: userHeader_UserHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userHeader_UserHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userHeader_UserHeaderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "level",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "progressInPercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sumGamificationPoints",
              "storageKey": null
            }
          ],
          "type": "AcademiesUserExtension",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LevelDefinition",
              "kind": "LinkedField",
              "name": "levelDefinition",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "TkaUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userAvatar_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "cfa4c82f0d2c1ebca801952ebbaada35";

export default node;
