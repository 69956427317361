import { cva } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const itemWrapperClass = flex({
	flexDirection: "row",
	gap: "12",
	alignItems: "center",
});

export const iconWrapperClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		p: "8",
		borderRadius: "12",
		border: "1px solid",
		borderColor: "shade.40",
	},
	variants: {
		finished: {
			true: {
				bg: "secondary.100",
				borderColor: "secondary.100",
			},
		},
	},
	defaultVariants: {
		finished: false,
	},
});

export const textWrapperClass = stack({});
