/**
 * @generated SignedSource<<bd82285357d68558c0d1ea59cf1cef7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type dashboard_QueryFragment$data = {
  readonly Learnable: {
    readonly PublishedLearnables: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly kind: LearnableKind;
          readonly " $fragmentSpreads": FragmentRefs<"offerCard_PublishedLearnableFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "dashboard_QueryFragment";
};
export type dashboard_QueryFragment$key = {
  readonly " $data"?: dashboard_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dashboard_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": [
          "Learnable",
          "PublishedLearnables"
        ]
      }
    ]
  },
  "name": "dashboard_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnableQueries",
      "kind": "LinkedField",
      "name": "Learnable",
      "plural": false,
      "selections": [
        {
          "alias": "PublishedLearnables",
          "args": [
            {
              "kind": "Variable",
              "name": "kinds",
              "variableName": "kinds"
            },
            {
              "kind": "Variable",
              "name": "tagIds",
              "variableName": "tagIds"
            },
            {
              "kind": "Variable",
              "name": "text",
              "variableName": "text"
            }
          ],
          "concreteType": "PublishedLearnablesConnection",
          "kind": "LinkedField",
          "name": "__dashboard_PublishedLearnables_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PublishedLearnablesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "offerCard_PublishedLearnableFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2c09ca982828ad4300258ad48bc2dca3";

export default node;
