/**
 * @generated SignedSource<<53900d8460e5dcf61cf1c21ad25ef367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentMethodType = "Card" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type ChangePaymentMethodInput = {
  clientMutationId?: string | null;
  orderId: string;
  paymentMethodType: PaymentMethodType;
  rateCount?: number | null;
  selectedPaymentMethodKind: string;
};
export type shoppingCartPaymentMethod_ChangePaymentMethodMutation$variables = {
  input: ChangePaymentMethodInput;
};
export type shoppingCartPaymentMethod_ChangePaymentMethodMutation$data = {
  readonly Billing: {
    readonly changePaymentMethod: {
      readonly order: {
        readonly " $fragmentSpreads": FragmentRefs<"shoppingCartPaymentMethod_OrderFragment">;
      };
    } | null;
  };
};
export type shoppingCartPaymentMethod_ChangePaymentMethodMutation = {
  response: shoppingCartPaymentMethod_ChangePaymentMethodMutation$data;
  variables: shoppingCartPaymentMethod_ChangePaymentMethodMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taxRatePercentage",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rateCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shoppingCartPaymentMethod_ChangePaymentMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ChangePaymentMethodPayload",
            "kind": "LinkedField",
            "name": "changePaymentMethod",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "shoppingCartPaymentMethod_OrderFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shoppingCartPaymentMethod_ChangePaymentMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ChangePaymentMethodPayload",
            "kind": "LinkedField",
            "name": "changePaymentMethod",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Totals",
                        "kind": "LinkedField",
                        "name": "totals",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Price",
                            "kind": "LinkedField",
                            "name": "includingAllDiscounts",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MonthlyOption",
                            "kind": "LinkedField",
                            "name": "monthlyOptions",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Price",
                                "kind": "LinkedField",
                                "name": "downPayment",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Price",
                                "kind": "LinkedField",
                                "name": "financedAmount",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Price",
                                "kind": "LinkedField",
                                "name": "monthlyRate",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Price",
                                "kind": "LinkedField",
                                "name": "totalAmount",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllowedPaymentMethods",
                    "kind": "LinkedField",
                    "name": "allowedPaymentMethods",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paymentMethods",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "selectedPaymentMethod",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paymentMethodType",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MonthlyOption",
                            "kind": "LinkedField",
                            "name": "chosenOption",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MonthlyPaymentMethod",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Billing_CustomerDetails",
                    "kind": "LinkedField",
                    "name": "customerDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Purchaser",
                    "kind": "LinkedField",
                    "name": "purchaser",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "575b3ca23fe6afc0b81dfb12987f1351",
    "id": null,
    "metadata": {},
    "name": "shoppingCartPaymentMethod_ChangePaymentMethodMutation",
    "operationKind": "mutation",
    "text": "mutation shoppingCartPaymentMethod_ChangePaymentMethodMutation(\n  $input: ChangePaymentMethodInput!\n) {\n  Billing {\n    changePaymentMethod(input: $input) {\n      order {\n        ...shoppingCartPaymentMethod_OrderFragment\n        id\n      }\n    }\n  }\n}\n\nfragment basketTimeline_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n    }\n  }\n  customerDetails {\n    data {\n      __typename\n      kind\n    }\n  }\n  selectedPaymentMethod {\n    __typename\n    kind\n    ... on MonthlyPaymentMethod {\n      chosenOption {\n        rateCount\n      }\n    }\n  }\n}\n\nfragment paypalButton_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n    }\n  }\n  purchaser {\n    userId\n  }\n}\n\nfragment shoppingCartPaymentMethod_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n      monthlyOptions {\n        downPayment {\n          grossPrice\n          netPrice\n          taxRatePercentage\n        }\n        financedAmount {\n          grossPrice\n          netPrice\n          taxRatePercentage\n        }\n        monthlyRate {\n          grossPrice\n          netPrice\n          taxRatePercentage\n        }\n        rateCount\n        totalAmount {\n          grossPrice\n          netPrice\n          taxRatePercentage\n        }\n      }\n    }\n  }\n  allowedPaymentMethods {\n    paymentMethods\n  }\n  selectedPaymentMethod {\n    __typename\n    kind\n    paymentMethodType\n    ... on MonthlyPaymentMethod {\n      chosenOption {\n        rateCount\n      }\n    }\n  }\n  ...shoppingCartTemplate_OrderFragment\n}\n\nfragment shoppingCartTemplate_OrderFragment on Order {\n  ...basketTimeline_OrderFragment\n  selectedPaymentMethod {\n    __typename\n    kind\n    paymentMethodType\n  }\n  ...paypalButton_OrderFragment\n}\n"
  }
};
})();

(node as any).hash = "908cbca9b074bb2b9a8f924ad28bfe04";

export default node;
