import { BranchNodesTree } from "@thekeytechnology/academies-lib-webapp/components/branch-nodes-tree";
import { PathParams } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { BranchNodesPath } from "@router/paths";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./branch-nodes-tab.graphql";
import { tabWrapperClass } from "./branch-nodes-tab.styles";
import { BranchNodeTabProps } from "./branch-nodes-tab.types";
import { useRootNavigation } from "../root.util";

export const BranchNodeTab = ({ learnOpportunityV2FragmentRef }: BranchNodeTabProps) => {
	const { branchNodeId } = useParams<PathParams<typeof BranchNodesPath>>();
	const { handleContentNodeOnClick } = useRootNavigation();

	const root = useFragment(LEARN_OPPORTUNITY_V2_FRAGMENT, learnOpportunityV2FragmentRef);

	return (
		<div className={tabWrapperClass}>
			{root && (
				<BranchNodesTree
					learnOpportunityV2FragmentRef={root}
					onContentNodeClick={handleContentNodeOnClick}
					activeNodeId={branchNodeId}
				/>
			)}
		</div>
	);
};
