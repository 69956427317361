import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
	md: {
		minWidth: "[25rem]",
	},
});

export const contentWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "12",
});

export const iconWrapperClass = css({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "8",
});

export const iconCircleClass = css({
	width: "20",
	height: "20",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: "error.100",
	borderRadius: "full",
});
