import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { AvatarWithProfileMenu } from "@components/avatar-with-profile-menu";
import { navbar_AuthViewerSchemaFragment$key } from "@relay/navbar_AuthViewerSchemaFragment.graphql";
import { navbar_Query } from "@relay/navbar_Query.graphql";
import { Path } from "@router/paths";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, QUERY } from "./navbar.graphql";
import { NavbarSkeleton } from "./navbar.skeleton";
import { centerWrapperClass, visibilityClass, wrapperClass } from "./navbar.styles";
import { NavbarItem } from "../navbar-item";

// TODO: add-translations
export const NavbarComponent = () => {
	const theme = useTheme();
	const { isXLargeUp } = useWindowSize();

	const query = useLazyLoadQuery<navbar_Query>(QUERY, {}, { fetchPolicy: "store-or-network" });

	const auth = useFragment<navbar_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		query.Viewer.Auth,
	);

	const navigate = useNavigate();

	const handleLogoOnClick = () => {
		navigate(Path.dashboard.path);
	};

	return (
		<div className={wrapperClass}>
			{isXLargeUp && (
				<button type="button" onClick={handleLogoOnClick}>
					<theme.LogoSquareComponent />
				</button>
			)}
			<div className={centerWrapperClass}>
				<NavbarItem iconName="home" label="Home" path={Path.dashboard.path} />
				<NavbarItem iconName="compass" label="Entdecken" path={Path.progression.path} />
				<NavbarItem iconName="note" label="Notizen" path={Path.notes.path} />
				<div
					className={visibilityClass({
						hideXLUp: true,
					})}
				>
					<AvatarWithProfileMenu authViewerSchemaFragmentRef={auth} />
				</div>
			</div>
			<div className={visibilityClass({ showXLUp: true })}>
				<AvatarWithProfileMenu authViewerSchemaFragmentRef={auth} />
			</div>
		</div>
	);
};

export const Navbar = withSuspense(NavbarComponent, NavbarSkeleton);
