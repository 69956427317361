import { ButtonSkeleton } from "@thekeytechnology/academies-lib-webapp/components/button";
import {
	buttonsWrapperClass,
	contentWrapperClass,
	wrapperClass,
} from "./bottom-navigation-template.styles";

export const BottomNavigationTemplateSkeleton = ({
	showSecondaryButton,
}: {
	showSecondaryButton?: boolean;
}) => {
	return (
		<div className={wrapperClass}>
			<div className={contentWrapperClass}>
				<div className={buttonsWrapperClass}>
					{showSecondaryButton && <ButtonSkeleton iconOnly />}
					<ButtonSkeleton />
				</div>
			</div>
		</div>
	);
};
