import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthTemplateStep } from "@components/auth-template-step";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { type signUpSteps_InvitationByTokenQuery } from "@relay/signUpSteps_InvitationByTokenQuery.graphql";
import { Path } from "@router/paths";
import { InlineWrapper } from "./sign-up-steps.styles";
import { type SignUpLocationState, type SignUpStepsProps } from "./sign-up-steps.types";
import { INVITATION_QUERY } from "../../../login/parts/login-steps/login-steps.graphql";
import { SignUpAGBStep } from "../sign-up-agb-step";
import { SignUpConfirmEmailStep } from "../sign-up-confirm-email-step";
import { SignUpJobStep } from "../sign-up-job-step";
import { SignUpNameStep } from "../sign-up-name-step";
import { SignUpPasswordEmailStep } from "../sign-up-password-email-step";
import { RegisterUserInput } from "@relay/useAuthContext_RegisterMutation.graphql";

export const SignUpSteps = ({ inline, onGoToLogin }: SignUpStepsProps) => {
	const navigate = useNavigate();
	const { signUp, acceptInvitationWithNewUser } = useAuthContext();
	const { state }: SignUpLocationState = useLocation();
	const { t } = useTranslation("inviteScreens");
	const [signUpEmail, setSignUpEmail] = useState<string | undefined>();
	const searchParams = new URLSearchParams(location.search);
	const orderIdParam = searchParams.get("orderId");

	const {
		Auth: { InvitationByToken: invitation },
	} = useLazyLoadQuery<signUpSteps_InvitationByTokenQuery>(INVITATION_QUERY, {
		token: state?.invitationToken ?? "",
		skip: !state?.invitationToken,
	});

	const handleOnSubmit = (
		values: Omit<RegisterUserInput, "rawPassword" | "adsOptIn"> & {
			password: string;
			adsAccepted: boolean;
		},
		goToNext: () => void,
	) => {
		if (state?.invitationToken) {
			acceptInvitationWithNewUser(values, state.invitationToken).then(() => {
				if (inline) return;
				navigate(Path.progression.path);
			});
		} else {
			signUp(values).then(() => {
				setSignUpEmail(values.email);
				goToNext();
			});
		}
	};

	const handleGoToLogin = () => {
		if (onGoToLogin) {
			onGoToLogin();
			return;
		}

		let path = Path.login.path;

		if (orderIdParam) {
			path += `?orderId=${orderIdParam}`;
		}

		navigate(path, { state });
	};

	const inviteText =
		invitation?.accountName && invitation?.invitingUserName
			? t("invite_screens.invitedByTitle", {
					account: invitation.accountName,
					invitingUser: invitation.invitingUserName,
			  })
			: undefined;

	const wrapper = inline ? InlineWrapper : AuthTemplateStep;

	return (
		<MultiStepAuthContainer submitStep={4} StepWrapper={wrapper} onSubmit={handleOnSubmit}>
			{({ onNext, onPrevious }) => (
				<>
					<SignUpPasswordEmailStep
						currentStep={1}
						maxSteps={4}
						headerText={inviteText}
						onNext={onNext}
						onGoToLogin={handleGoToLogin}
					/>
					<SignUpNameStep
						currentStep={2}
						maxSteps={4}
						onNext={onNext}
						onPrevious={onPrevious}
					/>
					<SignUpJobStep
						currentStep={3}
						maxSteps={4}
						onNext={onNext}
						onPrevious={onPrevious}
					/>
					<SignUpAGBStep
						currentStep={4}
						maxSteps={4}
						onNext={onNext}
						onPrevious={onPrevious}
					/>
					<SignUpConfirmEmailStep
						email={signUpEmail ?? ""}
						showLoginButton={inline}
						onGoToLogin={handleGoToLogin}
					/>
				</>
			)}
		</MultiStepAuthContainer>
	);
};
