/**
 * @generated SignedSource<<cc833ceb71b214dc8f6f49fecfa2b030>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type reminderOverview_QueryFragment$data = {
  readonly Note: {
    readonly Note: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"reminderCard_ReminderNoteFragment" | "reminderOverview_ReminderCardInlineFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "reminderOverview_QueryFragment";
};
export type reminderOverview_QueryFragment$key = {
  readonly " $data"?: reminderOverview_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderOverview_QueryFragment">;
};

import reminderOverview_RefetchQuery_graphql from './reminderOverview_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteColors"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteSourceKinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noteColorToSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": reminderOverview_RefetchQuery_graphql
    }
  },
  "name": "reminderOverview_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteQueries",
      "kind": "LinkedField",
      "name": "Note",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByText",
              "variableName": "filterByText"
            },
            {
              "kind": "Variable",
              "name": "includeNoteColors",
              "variableName": "includeNoteColors"
            },
            {
              "kind": "Variable",
              "name": "includeNoteSourceKinds",
              "variableName": "includeNoteSourceKinds"
            },
            {
              "kind": "Variable",
              "name": "noteColorToSortBy",
              "variableName": "noteColorToSortBy"
            }
          ],
          "concreteType": "NoteConnection",
          "kind": "LinkedField",
          "name": "Note",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NoteEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Note",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "reminderCard_ReminderNoteFragment"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "reminderOverview_ReminderCardInlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "content",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "reminder",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "kind",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCompleted",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "remindAt",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7f3c1f7735b8b37436dd5c9448edc539";

export default node;
