import { PathBase, Path } from "@thekeytechnology/epic-ui";

export class ProfilePath extends PathBase {
	static readonly pathKey = "profile";
}

export class VocabularyPath extends PathBase {
	static readonly pathKey = "vocabulary";
}

export class MyAvailabilitiesPath extends PathBase {
	static readonly pathKey = "myAvailabilities";
	static readonly pathName = "my-availabilities";
}

export class MyCoachingOffersPath extends PathBase {
	static readonly pathKey = "myCoachingOffers";
	static readonly pathName = "my-coaching-offers";
}

export class AccountDataPath extends PathBase {
	static readonly pathKey = "accountData";
	static readonly pathName = "account-data";
}
export class MembersPath extends PathBase {
	static readonly pathKey = "members";
}
export class LicensesPath extends PathBase {
	static readonly pathKey = "licenses";
}
export class InvoicesPath extends PathBase {
	static readonly pathKey = "invoices";
}
export class AccountPath extends Path<typeof AccountPath> {
	static readonly pathKey = "account";
	static readonly childPaths = [AccountDataPath, MembersPath, LicensesPath, InvoicesPath];
}

export class SettingsPath extends PathBase {
	static readonly pathKey = "settings";
}

export class ImprintPath extends PathBase {
	static readonly pathKey = "imprint";
}

export class ProfileMenuPath extends Path<typeof ProfileMenuPath> {
	static readonly pathKey = "profileMenu";
	static readonly pathName = "profile-menu";
	static readonly childPaths = [
		ProfilePath,
		VocabularyPath,
		MyAvailabilitiesPath,
		MyCoachingOffersPath,
		AccountPath,
		SettingsPath,
		ImprintPath,
	];
}
