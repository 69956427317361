import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Skeleton, SkeletonShape } from "@thekeytechnology/epic-ui";
import { useMatch } from "react-router-dom";
import { BasketTimelineSkeleton } from "@components/basket-timeline";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import { Path } from "@router/paths";
import { H2Span } from "@themes/font-tags";
import {
	accountWrapperClass,
	sideMenuContentWrapperClass,
	skeletonCardsWrapperClass,
	timelineHeaderWrapperClass,
} from "./shopping-cart-template.styles";
import { BottomNavigationTemplateSkeleton } from "../bottom-navigation-template/bottom-navigation-template.skeleton";
import { RootCardSkeleton } from "../root-card/root-card.skeleton";

//TODO: add-translations
export const ShoppingCartTemplateSkeleton = () => {
	const { t } = useTkaTranslation("basketScreen");
	const { isXLargeUp } = useWindowSize();

	const currentPath = [
		useMatch(Path.shoppingCart.withIdPlaceholder().products.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().invoice.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().paymentMethod.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().overview.path),
	];

	const currentStepIndex = currentPath.findIndex(Boolean);
	const currentStep = currentStepIndex < 0 ? 1 : currentStepIndex + 1;

	const basketTimeline = (
		<BasketTimelineSkeleton
			currentStep={currentStep}
			orientation={isXLargeUp ? "vertical" : "horizontal"}
		/>
	);

	return (
		<ScreenSidemenuLayout
			headerLeftIconName="close"
			sideMenu={
				<div className={sideMenuContentWrapperClass}>
					<H2Span>{t("basket_screen.basketTimelineTitle")}</H2Span>
					{basketTimeline}
					<div className={accountWrapperClass}>
						<Skeleton shape={SkeletonShape.Circle} size="2.5rem" />
					</div>
				</div>
			}
			headerBottomContent={
				!isXLargeUp && <div className={timelineHeaderWrapperClass}>{basketTimeline}</div>
			}
			bottomContent={<BottomNavigationTemplateSkeleton />}
		>
			<Skeleton width="15rem" height="1.5rem" />
			<Skeleton width="85%" className="mt-16" />
			<Skeleton width="30%" className="mt-4" />
			<Skeleton width="60%" className="mt-4" />
			<div className={skeletonCardsWrapperClass}>
				<RootCardSkeleton />
				<RootCardSkeleton />
				<RootCardSkeleton />
			</div>
		</ScreenSidemenuLayout>
	);
};
