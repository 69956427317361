import { InputText } from "@thekeytechnology/epic-ui";
import type { FC } from "react";
import { SearchBarProps } from "./search-bar.types";

export const SearchBarMobile: FC<SearchBarProps> = ({ value, onChange }) => {
	const placeholder = "Suchen";

	const handleOnChange = (e?: string) => {
		onChange?.(e ?? "");
	};

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
		/>
	);
};
