import { graphql } from "react-relay";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment deleteAccountModalsFlow_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			accounts {
				id
				name
			}
		}
	}
`;
