import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	flexDirection: "row",
	align: "center",
	gap: "8",
});

export const iconWrapperClass = flex({
	align: "center",
	justify: "center",
	p: "8",
	bg: "shade.5",
	borderRadius: "4",
});
