import { graphql } from "react-relay";

export const QUERY = graphql`
	query shoppingCartSuccess_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						email
					}
				}
			}
		}
	}
`;
