import { graphql } from "react-relay";

export const NOTE_FRAGMENT = graphql`
	fragment reminderForm_QueryFragment on Note {
		id
		content
		reminder {
			kind
			remindAt
			isCompleted
			... on RecurringNoteReminder {
				kind
				interval {
					kind
					... on DailyInterval {
						value
					}
					... on WeeklyInterval {
						value
					}
					... on MonthlyInterval {
						value
					}
				}
			}
			... on NonRecurringNoteReminder {
				kind
			}
		}
	}
`;

export const CREATE_NOTE_MUTATION = graphql`
	mutation reminderForm_createNoteMutation($input: CreateNoteInput!) {
		Note {
			createNote(input: $input) {
				note {
					node {
						...reminderForm_QueryFragment
					}
				}
			}
		}
	}
`;

export const EDIT_NOTE_MUTATION = graphql`
	mutation reminderForm_editNoteMutation($input: EditNoteInput!) {
		Note {
			editNote(input: $input) {
				note {
					node {
						...reminderForm_QueryFragment
					}
				}
			}
		}
	}
`;
