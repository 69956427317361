import { useFormik } from "formik";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { ValidatedInputChips } from "@components/validated-input-chips/validated-input-chips.component";
import { standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation } from "@relay/standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation.graphql";
import { AccountContext } from "@screens/account";
import { INVITE_MUTATION, QUERY_FRAGMENT } from "./standard-invite-form.graphql";
import { StandardInviteFormRef, StandardInviteFormProps } from "./standard-invite-form.types";
import { InviteMemberModalFormState } from "../invite-member-modal/invite-member-modal.types";

export const StandardInviteForm = forwardRef<StandardInviteFormRef, StandardInviteFormProps>(
	function StandardInviteForm(
		{ selectedUsers, queryFragmentRef, setInviteMembersMutationResponse, onSuccess },
		ref,
	) {
		const data = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
		const { membersConnectionIds, addGroupsConnectionId } = useContext(AccountContext);

		const groupsConnectionId =
			data?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers
				.__id;

		useEffect(() => {
			groupsConnectionId && addGroupsConnectionId(groupsConnectionId);
		}, [groupsConnectionId]);

		const selectedUserEmails = selectedUsers && selectedUsers.map((user) => user.email);

		const [invite] =
			useMutation<standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation>(
				INVITE_MUTATION,
			);

		const form = useFormik<InviteMemberModalFormState>({
			initialValues: {
				emails: selectedUserEmails ?? [],
			},
			validateOnChange: true,
			validateOnBlur: true,
			validationSchema: Yup.object().shape({
				emails: Yup.array()
					.of(Yup.string().email("Bitte gib eine gültige E-Mail Adresse ein"))
					.min(1, "Bitte gib mindestens eine E-Mail Adresse ein")
					.required("Bitte gib eine E-Mail Adresse ein"),
			}),
			onSubmit: (values) => {
				invite({
					variables: {
						input: {
							emails: values.emails,
							groupIds: values.groups?.map((group) => group.id) ?? [],
						},
						connections: membersConnectionIds,
					},
					onCompleted: (response) => {
						form.resetForm({});
						form.setErrors({});
						setInviteMembersMutationResponse &&
							setInviteMembersMutationResponse(
								response.AccountMemberManagement
									.inviteOrAddUsersToUserInAccountGroups,
							);
						onSuccess?.();
					},
				});
			},
		});

		useImperativeHandle(ref, () => ({
			submit: () => {
				form.submitForm();
			},
		}));

		return (
			<form onSubmit={form.handleSubmit}>
				<ValidatedInputChips
					formikConfig={form}
					label={"Benutzer"}
					icon={"user"}
					iconSizeInRem={1.5}
					placeholder="Benutzer Email"
					name="emails"
					addOnBlur
				/>
			</form>
		);
	},
);
