import { BranchDropdown } from "@thekeytechnology/academies-lib-webapp/components/branch-dropdown";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { TeamSizeDropdown } from "@thekeytechnology/academies-lib-webapp/components/team-size-dropdown";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useFormik } from "formik";
import * as Yup from "yup";
import { P1Span, H1Span } from "@themes/font-tags";
import {
	ActionButtonsWrapper,
	FormWrapper,
	InputsWrapper,
	TextCenter,
} from "./sign-up-job-step.styles";
import { type SignUpJobStepFormState, type SignUpJobStepProps } from "./sign-up-job-step.types";

export const SignUpJobStep = ({
	onNext,
	onPrevious,
	currentStep,
	maxSteps,
}: SignUpJobStepProps) => {
	const { t } = useTkaTranslation("registerForm");

	const form = useFormik<SignUpJobStepFormState>({
		initialValues: {
			teamSize: undefined,
			branch: undefined,
			position: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			teamSize: Yup.string().optional(),
			branch: Yup.string().optional(),
			position: Yup.string().optional(),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	const showStepInfo = maxSteps !== undefined && currentStep !== undefined;

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				{showStepInfo && (
					<TextCenter>
						<P1Span>
							{currentStep}/{maxSteps}
						</P1Span>
					</TextCenter>
				)}
				<TextCenter>
					<H1Span>{t("register_form.section3Title")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<TeamSizeDropdown
						formikConfig={form}
						name="teamSize"
						label={t("register_form.section3LabelTeamSize")}
						placeholder={t("register_form.section3PlaceholderTeamSize")}
					/>
					<BranchDropdown
						formikConfig={form}
						name="branch"
						label={t("register_form.section3LabelBranch")}
						placeholder={t("register_form.section3PlaceholderBranch")}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="position"
						label={t("register_form.section3LabelPosition")}
						placeholder={t("register_form.section3PlaceholderPosition")}
					/>
				</InputsWrapper>
				<ActionButtonsWrapper>
					<Button
						type="button"
						iconName="arrowLeft"
						colorVersion={"tertiary"}
						onClick={onPrevious}
					/>
					<Button
						fillParent
						label={t("register_form.section3ButtonText")}
						iconName="arrowRight"
						colorVersion={"default"}
					/>
				</ActionButtonsWrapper>
			</FormWrapper>
		</form>
	);
};
