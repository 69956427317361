import * as Yup from "yup";
import "yup-phone-lite";

export const businessClientFormSchema = Yup.object().shape({
	company: Yup.string().required("Bitte gib den Name des Unternehmens ein."),
	recipient: Yup.string().required("Bitt gib eine Rechnungsstelle ein."),
	businessType: Yup.string().required("Bitte wähle eine Unternehmensart aus."),
	email: Yup.string()
		.email("Bitte gib eine gültige E-Mail Adresse ein.")
		.required("Bitte gib eine E-Mail Adresse ein."),
	street: Yup.string().required("Bitte gib eine Straße ein."),
	houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
	city: Yup.string().required("Bitte gib einen Ort ein."),
	postCode: Yup.string()
		.length(5, "Bitte gib eine Gültige Postleitzahl ein")
		.matches(/^[0-9]{5}/, "Bitte gib eine Gültige Postleitzahl ein")
		.required("Bitte gib eine Postleitzahl ein."),
	phoneNumber: Yup.string()
		.phone("DE", "Bitte gib eine gültige Telefonnummer ein.")
		.required("Bitte gib eine Telefonnummer ein."),
});
