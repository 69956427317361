import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { Spacing32 } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { graphql, useFragment } from "react-relay";
import { ImageLearnElement } from "@components/image-learn-element";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { ImageElement_ElearningContentSubmissionDefinitionFragment$key } from "@relay/ImageElement_ElearningContentSubmissionDefinitionFragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH1Span } from "@themes/font-tags";
import { ImageElementProps } from "./image-element.interface";

const E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment ImageElement_ElearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				title
				id
				... on FileLearnElement {
					title
					file {
						url
					}
					...imageLearnElement_ImageLearnElementFragment
				}
			}
		}
	}
`;

export const ImageElement = ({ contentSubmissionDefinitionFragmentRef }: ImageElementProps) => {
	const contentSubmissionDefinition =
		useFragment<ImageElement_ElearningContentSubmissionDefinitionFragment$key>(
			E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);

	const element = contentSubmissionDefinition?.currentElementState?.element;
	const url = element?.file?.url;

	return (
		<SideMenuContentPadding data-selection-menu-element-id={element?.id}>
			{element?.title && (
				<TkaH1Span tkaColor={shade100}>{stripHtml(element.title)}</TkaH1Span>
			)}
			{url && (
				<>
					<Spacing32 />
					<ImageLearnElement learnElementFragmentRef={element} />
				</>
			)}
		</SideMenuContentPadding>
	);
};
