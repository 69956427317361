import { css, cva } from "@styled-system/css";
import { flex, grid } from "@styled-system/patterns";

export const controlsWrapperClass = flex({
	width: "full",
	justifyContent: "center",
	mdDown: {
		position: "fixed",
		bottom: "80",
		left: "0",
	},
});

export const controlsContainerClass = grid({
	gridTemplateColumns: "[.5fr 1fr .5fr]",
	background: "shade.5",
	padding: "16",
	borderRadius: "24",
	width: "[max-content]",
	gap: "8",
	justifyContent: "center",
	my: "32",
});

export const indicatorsClass = css({
	zIndex: "10",
	position: "relative",
	display: "flex!",
	gap: "8",
	justifyContent: "center",
	mx: "32",
	alignItems: "center",
});
export const indicatorButtonClass = cva({
	base: {
		borderRadius: "full",
		cursor: "pointer",
	},
	variants: {
		isActive: {
			true: {
				position: "absolute",
				left: "-8",
				h: "16",
				w: "16",
				bg: "primary.100!",
			},
			false: {
				h: "8",
				w: "8",
				bg: "shade.20",
			},
		},
	},
});

export const lastPageButtonClass = flex({
	background: "secondary.40",
	borderRadius: "16",
	color: "secondary.120",
	alignItems: "center",
	justifyContent: "center",
});

export const iconWrapperClass = flex({
	opacity: "0.3",
	w: "[1.2em]",
});

export const springIndicatorAnimation = (index: number) => {
	return { x: index * 20 };
};
export const springIndicatorTransition = { type: "spring", stiffness: 400, damping: 25 };
