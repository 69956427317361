import { css } from "@styled-system/css";

export const headerWrapperClass = css({
	display: "flex",
	alignItems: "center",
	flexWrap: "wrap",
	width: "full",
	marginBottom: "16",
	md: {
		flexWrap: "nowrap",
	},
});

export const memberAndLabelsWrapperClass = css({
	flexDirection: "column",
	flexGrow: "1",
});

export const labelsWrapperClass = css({
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	gap: "8",
	marginTop: "4",
	md: {
		flexWrap: "nowrap",
	},
});

export const searchAndFiltersWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});

export const searchBarMobileWrapperClass = css({
	marginTop: "16",
	width: "full",
});

export const buttonWrapperClass = css({
	display: "flex",
	justifyContent: "flex-end",
	width: "full",
	marginTop: "16",
	md: {
		width: "auto",
		marginTop: "0",
		marginLeft: "8",
	},
});
