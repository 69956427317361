export const INTERVAL_TYPE_OPTIONS = [
	{
		label: "Tage",
		value: "Daily",
	},
	{
		label: "Wochen",
		value: "Weekly",
	},
	{
		label: "Monate",
		value: "Monthly",
	},
];
