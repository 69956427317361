import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	direction: "column",
	gap: "32",
	padding: "24",
	paddingTop: "40",
	mx: "auto",
	maxWidth: "[30rem]",
	md: {
		paddingX: "80",
		paddingY: "40",
	},
});

export const iconBackgroundClass = flex({
	alignItems: "center",
	justifyContent: "center",
	alignSelf: "center",
	width: "[7.5rem]",
	height: "[7.5rem]",
	borderRadius: "[3.75rem]",
	backgroundColor: "success.0",
	marginBottom: "8",
});

export const textWrapperClass = flex({
	direction: "column",
	gap: "12",
	alignItems: "center",
	textAlign: "center",
});
