import { listClass, wrapperClass } from "./invoices-tab.styles";
import { InvoiceCardSkeleton } from "./parts/invoice-card";

export const InvoicesTabSkeleton = () => {
	const skeletonCount = 6;

	return (
		<div className={wrapperClass}>
			<ul className={listClass}>
				{Array.from({ length: skeletonCount }).map((_, index) => (
					<InvoiceCardSkeleton key={"invoiceCardSkeleton" + index} />
				))}
			</ul>
		</div>
	);
};
