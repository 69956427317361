import { useFragment } from "react-relay";
import { ACTIVE_ELEARNING_CONTENT_SUBMISSION_DEFINITION } from "@components/text-with-pages-element/text-with-pages-element.graphql";
import { TextWithPagesElementProps } from "@components/text-with-pages-element/text-with-pages-element.types";
import { TextWithPagesLearnElement } from "@components/text-with-pages-learn-element";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment$key } from "@relay/textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment.graphql";

export const TextWithPagesElement = ({
	contentSubmissionDefinitionFragmentRef,
}: TextWithPagesElementProps) => {
	const contentSubmissionDefinition =
		useFragment<textWithPagesElement_ActiveElearningContentSubmissionDefinitionFragment$key>(
			ACTIVE_ELEARNING_CONTENT_SUBMISSION_DEFINITION,
			contentSubmissionDefinitionFragmentRef,
		);

	const element = contentSubmissionDefinition?.currentElementState?.element;

	return (
		<SideMenuContentPadding data-selection-menu-element-id={element?.id}>
			<TextWithPagesLearnElement learnElementFragmentRef={element} />
		</SideMenuContentPadding>
	);
};
