import { css, cva } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
	md: {
		minWidth: "[25rem]",
	},
});

export const inputWrapperClass = css({
	position: "relative",
});

export const inputIconClass = cva({
	base: {
		position: "absolute",
		zIndex: 10,
		top: "50%",
		marginTop: "-12",
		left: "[0.35rem]",
		transform: "translateY(-50%)",
		padding: "[6px]",
		borderRadius: "full",
	},
	variants: {
		background: {
			error: {
				backgroundColor: "error.100",
			},
			success: {
				backgroundColor: "success.100",
			},
		},
	},
});
