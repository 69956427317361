import { ValidatedInputSwitch } from "@thekeytechnology/academies-lib-webapp/components/validated-input-switch";
import { useFormik } from "formik";
import mapValues from "lodash/mapValues";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import "yup-phone-lite";
import { shoppingCartLegalForm_OrderFragment$key } from "@relay/shoppingCartLegalForm_OrderFragment.graphql";
import { colorSecondary100Class } from "@themes/color-classes";
import { H2Span, P2Span } from "@themes/font-tags";
import { ORDER_FRAGMENT } from "./shopping-cart-legal-form.graphql";
import {
	legalConditionRowClass,
	legalConditionsClass,
	legalWrapperClass,
} from "./shopping-cart-legal-form.styles";
import {
	ShoppingCartLegalForm as ShoppingCartLegalFormClass,
	ShoppingCartLegalFormProps,
	ShoppingCartLegalFormState,
} from "./shopping-cart-legal-form.types";
import { AGB_URL, DATA_PROTECTION_URL } from "../../consts";

//TODO: add-translations
const ShoppingCartLegalFormComponent = (
	{ onSubmit, onValidationChanged, orderFragmentRef }: ShoppingCartLegalFormProps,
	ref: ForwardedRef<any>,
) => {
	const order = useFragment<shoppingCartLegalForm_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	const isMonthlyPayment = order.selectedPaymentMethod?.kind === "Monthly";

	const form = useFormik<ShoppingCartLegalFormState>({
		initialValues: {
			sepaAccepted: false,
			agbAccepted: false,
			rightOfWithdrawalAccepted: false,
		},
		validateOnMount: true,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: false,
		validationSchema: Yup.lazy((obj) =>
			Yup.object(
				mapValues(obj, (_, key) => {
					if (key === "sepaAccepted") {
						if (!isMonthlyPayment) {
							return Yup.bool();
						}
						return Yup.bool().oneOf(
							[true],
							"Bitte akzeptiere das SEPA-Lastschriftmandat.",
						);
					}
					if (key === "agbAccepted") {
						return Yup.bool().oneOf(
							[true],
							"Bitte stimme der Datenschutzerklärung zu.",
						);
					}
					if (key === "rightOfWithdrawalAccepted") {
						Yup.bool().oneOf([true], "Bitte stimme dem Wiederrufsrecht zu.");
					}
					return Yup.bool().oneOf([true], "Bitte stimme den AGBs zu.");
				}),
			),
		),
		onSubmit: () => {
			onSubmit?.();
		},
	});

	useEffect(() => {
		onValidationChanged?.(form.isValid);
	}, [form.isValid]);

	useImperativeHandle(ref, () => ({
		submit: () => {
			return form
				.submitForm()
				.then(form.validateForm)
				.then((errors) => Object.keys(errors).length === 0);
		},
	}));

	const handleDataProtectionOnClick = () => {
		window.open(DATA_PROTECTION_URL, "_blank");
	};
	const handleTermsOfUseOnClick = () => {
		window.open(AGB_URL, "_blank");
	};

	return (
		<form onSubmit={form.handleSubmit}>
			<div className={legalWrapperClass}>
				<H2Span>Rechtliches</H2Span>
				<div className={legalConditionsClass}>
					{isMonthlyPayment && (
						<div className={legalConditionRowClass}>
							<ValidatedInputSwitch formikConfig={form} name="sepaAccepted" />
							<P2Span>
								<strong>SEPA-Lastschriftmandat:</strong> Hiermit ermächtige ich die
								the key - academy GmbH, Zahlungen von meinem Konto mittels
								Lastschrift einzuziehen. Zugleich weise ich mein Geldinstitut an,
								die von der the key - academy GmbH,
								(Gläubiger-Identifikationsnummer: DE71ZZZ00002347934) von meinem
								Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von
								acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des
								belasteten Betrages verlangen. Es gelten dabei die mit meinem
								Kreditinstitut vereinbarten Bedingungen. Ich verzichte zudem
								ausdrücklich auf jegliche Erinnerungen, bevor die Lastschrift
								eingezogen wird.
								<br /> <br />
								<strong>Information:</strong> Um sich besser auf das Bereitstellen
								interaktiver Lehrgänge konzentrieren zu können, gibt die the key -
								academy GmbH die Debitorenbuchhaltung und den Forderungseinzug in
								die Hände der Danubio Forderungsmanagement GmbH & Co. KG. Dazu
								erhält Danubio die an Dich fakturierten Rechnungen, führt die
								Debitorenbuchhaltung und überwacht den Eingang der Beträge zu den
								mit Dir vereinbarten Fälligkeiten. Für alle anderen Fragen zu
								thekey.academy sind wir natürlich weiterhin Dein 1. Ansprechpartner.
								(erforderlich)
							</P2Span>
						</div>
					)}
					<div className={legalConditionRowClass}>
						<ValidatedInputSwitch formikConfig={form} name="agbAccepted" />
						<P2Span>
							Mit der Bestellung stimme ich der&nbsp;
							<P2Span
								className={colorSecondary100Class}
								onClick={handleDataProtectionOnClick}
							>
								Datenschutzerklärung
							</P2Span>
							&nbsp; und den&nbsp;
							<P2Span
								className={colorSecondary100Class}
								onClick={handleTermsOfUseOnClick}
							>
								Nutzungsbedingungen
							</P2Span>
							&nbsp; zu.*
						</P2Span>
					</div>
					<div className={legalConditionRowClass}>
						<ValidatedInputSwitch
							formikConfig={form}
							name="rightOfWithdrawalAccepted"
						/>
						<P2Span>
							Ich bin einverstanden und verlange ausdrücklich, dass Ihr vor Ende der
							Wiederrufsfrist mit der Ausführung der beauftragten Dienstleistungen
							beginnt. Mir ist bekannt, dass ich bei völliger Vertragserfüllung durch
							Euch mein Wiederrufsrecht verliere.*
						</P2Span>
					</div>
				</div>
			</div>
		</form>
	);
};

export const ShoppingCartLegalForm = forwardRef(ShoppingCartLegalFormComponent);
export type ShoppingCartLegalForm = ShoppingCartLegalFormClass;
