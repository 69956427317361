import { UserCardSkeleton } from "@components/user-card/user-card.skeleton";
import { memberCardsWrapperClass } from "./members-list.styles";

export const MembersListSkeleton = () => {
	return (
		<div className={memberCardsWrapperClass}>
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
		</div>
	);
};
