import { ReactComponent as CertificateLineSmall } from "@assets/certificate-line-small.svg";
import { ReactComponent as CertifiedCertificate } from "@assets/certified-certificate.svg";
import { colorPrimary40Class } from "@themes/color-classes";
import { L1Span, P3Span } from "@themes/font-tags";
import { contentClass, headerClass, titleClass, wrapperClass } from "./certificate-item.styles";
import { CertificateItemProps } from "./certificate-item.types";

export const CertificateItem = ({ title }: CertificateItemProps) => {
	return (
		<div className={wrapperClass}>
			<div className={headerClass}>
				<CertificateLineSmall />
				<L1Span className={colorPrimary40Class}>ZERTIFIKAT</L1Span>
				<CertificateLineSmall />
			</div>
			<div className={contentClass}>
				<CertifiedCertificate />
				<P3Span className={titleClass}>{title}</P3Span>
			</div>
		</div>
	);
};
