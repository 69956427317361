import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { InputText } from "@thekeytechnology/epic-ui";
import { shade100, shade40 } from "@themes/colors";
import { SearchBarProps } from "./search-bar.types";

export const SearchBar = ({ onClick: handleOnClick, value, onChange }: SearchBarProps) => {
	const placeholder = "Suchen";

	const { isMediumUp } = useWindowSize();

	const handleOnChange = (e?: string) => {
		onChange?.(e ?? "");
	};

	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;
	if (!isMediumUp)
		return (
			<Icon icon={"search"} sizeInRem={iconSize} color={iconColor} onClick={handleOnClick} />
		);

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
		/>
	);
};
