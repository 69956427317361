import tw from "twin.macro";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	minHeight: "full",
	direction: "column",
	align: "center",
	justify: "center",
	mt: "64",
});

export const Wrapper = tw(SideMenuContentPadding)`
	min-h-full
	flex
	flex-col
	items-center
	justify-center
`;

export const PlaceholderWrapper = tw.div`
	mt-[-2rem]
`;
