import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { shade40 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	iconBottomNodeWrapperClass,
	iconWrapperClass,
	textWrapperClass,
	wrapperClass,
} from "./icon-headline-button-template.styles";
import { IconHeadlineButtonTemplateProps } from "./icon-headline-button-template.types";

export const IconHeadlineButtonTemplate = ({
	icon,
	iconName,
	iconColor,
	iconBackgroundColor,
	iconBottomNode,
	title,
	subtitle,
	buttonLabel,
	buttonIconName,
	buttonIcon,
	buttonWidthRem,
	buttonColorVersion,
	onClick,
	secondaryButtonLabel,
	secondaryButtonIconName,
	secondaryButtonIcon,
	secondaryButtonWidthRem,
	secondaryButtonColorVersion,
	secondaryOnClick,
}: IconHeadlineButtonTemplateProps) => {
	const handleOnClick = () => {
		if (onClick) {
			onClick();
		}
	};
	const renderIcon = () => {
		if (!icon && !iconName) return null;
		return (
			<div
				className={iconWrapperClass({
					iconBackgroundColor,
				})}
			>
				{icon ??
					(iconName && (
						<Icon icon={iconName} color={iconColor ?? shade40} sizeRem={2.5} />
					))}
				{iconBottomNode && (
					<div className={iconBottomNodeWrapperClass}>{iconBottomNode}</div>
				)}
			</div>
		);
	};

	return (
		<div className={wrapperClass}>
			{renderIcon()}
			{(title || subtitle) && (
				<div className={textWrapperClass}>
					{title && <H1Span>{title}</H1Span>}
					{subtitle && <P2Span>{subtitle}</P2Span>}
				</div>
			)}
			{(buttonLabel || buttonIconName || buttonIcon) && (
				<Button
					label={buttonLabel}
					iconName={buttonIconName}
					icon={buttonIcon}
					widthRem={buttonWidthRem}
					colorVersion={buttonColorVersion}
					onClick={handleOnClick}
				/>
			)}
			{(secondaryButtonLabel || secondaryButtonIconName || secondaryButtonIcon) && (
				<Button
					label={secondaryButtonLabel}
					iconName={secondaryButtonIconName}
					icon={secondaryButtonIcon}
					widthRem={secondaryButtonWidthRem}
					colorVersion={secondaryButtonColorVersion}
					onClick={secondaryOnClick}
				/>
			)}
		</div>
	);
};
