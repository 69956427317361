import { match } from "ts-pattern";
import { P2Span } from "@themes/font-tags";
import { textBoldClass } from "./success-invite.styles";
import { SuccessfulInvite } from "./success-invite.types";

export const getSuccessContent = (
	successType: SuccessfulInvite,
	numberOfNewAccountMembers: number,
	numberOfUpdatedAccountMembers: number,
) => {
	const newAccountMembers =
		numberOfNewAccountMembers === 1
			? `${numberOfNewAccountMembers}`
			: `${numberOfNewAccountMembers}`;

	const updatedAccountMembers =
		numberOfUpdatedAccountMembers === 1
			? `${numberOfUpdatedAccountMembers} vorhandener`
			: `${numberOfUpdatedAccountMembers} vorhandene`;

	const newAccountMembersSuffix = numberOfNewAccountMembers === 1 ? "wurde" : "wurden";
	const updatedAccountMembersSuffix = numberOfUpdatedAccountMembers === 1 ? "wurde" : "wurden";
	const updatedMembersSuffix = numberOfUpdatedAccountMembers === 1 ? "war" : "waren";

	return match(successType)
		.with(SuccessfulInvite.INVITEUSERS, () => ({
			subtitle: "Benutzerkonten erfolgreich aktualisiert",
			infotext: (
				<>
					<P2Span className={textBoldClass}>{newAccountMembers} Benutzer </P2Span>
					{newAccountMembersSuffix} erfolgreich eingeladen.
					<br />
					{numberOfUpdatedAccountMembers > 0 && (
						<>
							<P2Span className={textBoldClass}>
								{numberOfUpdatedAccountMembers}
							</P2Span>
							<P2Span>{updatedAccountMembersSuffix} bereits im Konto.</P2Span>
						</>
					)}
				</>
			),
		}))
		.with(SuccessfulInvite.INVITEUSERSWITHGROUP, () => ({
			subtitle: "Aktualisierung der Benutzergruppen abgeschlossen",
			infotext: (
				<>
					<P2Span className={textBoldClass}>{newAccountMembers} Benutzer </P2Span>
					<P2Span>
						{newAccountMembersSuffix} erfolgreich eingeladen und den entsprechenden
						Gruppen zugeordnet.{" "}
					</P2Span>
					<br />
					<P2Span className={textBoldClass}>
						{updatedAccountMembers} Benutzer{" "}
					</P2Span>{" "}
					<P2Span> {updatedMembersSuffix} den relevanten Gruppen zugewiesen.</P2Span>
				</>
			),
		}))
		.exhaustive();
};
