import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
	md: {
		minWidth: "[25rem]",
	},
});
