import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { InputText, InputTextStatus } from "@thekeytechnology/epic-ui";
import { FC, useRef, useState } from "react";
import { useMutation } from "react-relay";
import { confirmDeleteAccountModal_AnonymizeAccountAsManagerMutation } from "@relay/confirmDeleteAccountModal_AnonymizeAccountAsManagerMutation.graphql";
import { H1Span, P2Span } from "@themes/font-tags";
import { ANONYMIZE_ACCOUNT_AS_MANAGER_MUTATION } from "./confirm-delete-account-modal.graphql";
import {
	wrapperClass,
	inputIconClass,
	inputWrapperClass,
} from "./confirm-delete-account-modal.styles";
import { ConfirmDeleteAccountModalProps } from "./confirm-delete-account-modal.types";

export const ConfirmDeleteAccountModal: FC<ConfirmDeleteAccountModalProps> = ({
	isVisible,
	onDismiss,
	onSuccess,
	accountName,
}) => {
	const [disabled, setDisabled] = useState(true);
	const countdownRef = useRef<NodeJS.Timeout>();
	const [countdown, setCountdown] = useState<number>();
	const [touched, setTouched] = useState(false);
	const [textToBeDeleted, setTextToBeDeleted] = useState("");

	const [deleteAccount, _] =
		useMutation<confirmDeleteAccountModal_AnonymizeAccountAsManagerMutation>(
			ANONYMIZE_ACCOUNT_AS_MANAGER_MUTATION,
		);
	const handlePrimaryOnClick = () => {
		deleteAccount({
			variables: {
				input: {},
			},
			onCompleted: () => {
				onSuccess?.();
			},
		});
	};
	let status: InputTextStatus = InputTextStatus.Default;
	if (textToBeDeleted !== accountName) {
		status = InputTextStatus.Error;
	} else {
		status = InputTextStatus.Success;
	}
	if (!touched) {
		status = InputTextStatus.Default;
	}

	const handleOnChange = (value: string | undefined) => {
		setTouched(true);
		if (countdownRef.current) {
			clearInterval(countdownRef.current);
		}

		if (value === accountName) {
			countdownRef.current = setInterval(() => {
				setCountdown((prevCountdown) => {
					if (prevCountdown === 0) {
						clearInterval(countdownRef.current!);
						setDisabled(false);
						return undefined;
					}
					return (prevCountdown ?? 6) - 1;
				});
			}, 1000);
		} else {
			setDisabled(true);
		}
		setTextToBeDeleted(value || "");
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				secondaryButtonStretch={false}
				primaryButtonDisabled={disabled}
				primaryButtonLabel={`Konto endgültig löschen ${countdown ? `(${countdown})` : ""}`}
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handlePrimaryOnClick}
				primaryButtonStretch={true}
			>
				<div className={wrapperClass}>
					<H1Span>Willst du das Konto “{accountName}” wirklich löschen?</H1Span>
					<P2Span>
						Du und alle Mitglieder des Kontos verlieren nach Bestätigung alle Daten.
						Diese können dann auch nicht wiederhergestellt werden.
					</P2Span>
					<div className={inputWrapperClass}>
						{status !== "default" && (
							<div className={inputIconClass({ background: status })}>
								<i
									className={`pi ${
										status === "error" ? "pi-times" : "pi-check"
									} text-shade-0`}
								></i>
							</div>
						)}
						<InputText
							icon={status !== "default" ? "add" : undefined}
							value={textToBeDeleted}
							onChange={handleOnChange}
							name="textToBeDeleted"
							placeholder="Name des Kontos zur Bestätigung..."
							bottomLabel={`Schreibe in das Feld „${accountName}“, um das Konto endgültig zu löschen. Dieses kann später nicht wiederhergestellt werden.`}
							status={status}
						/>
					</div>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
