import { graphql } from "react-relay";

export const QUERY = graphql`
	query userCart_Query {
		Viewer {
			Billing {
				GetUserCart {
					...userCart_UserCartFragment
				}
			}
		}
	}
`;

export const USER_CART_FRAGMENT = graphql`
	fragment userCart_UserCartFragment on UserCart {
		id
		userId
		orderId
		cart {
			selection {
				selectedProducts {
					product {
						id
						title
					}
					amount
				}
				selectedDiscountCodes
			}
			items {
				kind
				... on LimitedDiscountItem {
					title
					value
				}
				... on DiscountItem {
					validUntil
					code
					title
					value
				}
				... on GlobalDiscountItem {
					title
					value
				}
				... on ProductItem {
					amount
					product {
						id
						title
						netPrice
						isTaxFree
						data {
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			totals {
				withoutDiscounts {
					grossPrice
					netPrice
					taxRatePercentage
				}
				appliedDiscount {
					grossPrice
				}
				includingAllDiscounts {
					grossPrice
					netPrice
				}
				appliedDiscountPercentage
				monthlyOptions {
					monthlyRate {
						grossPrice
					}
				}
			}
			otherAvailableProducts {
				edges {
					node {
						id
						title
						data {
							kind
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GENERATE_LINK_FOR_USER_CART_MUTATION = graphql`
	mutation userCart_GenerateLinkForUserCartMutation($input: GenerateLinkForUserCartInput!) {
		Viewer {
			Billing {
				generateLinkForUserCart(input: $input) {
					link
				}
			}
		}
	}
`;

export const CHANGE_USER_CART_MUTATION = graphql`
	mutation userCart_ChangeUserCartMutation($input: ChangeUserCartInput!) {
		Viewer {
			Billing {
				changeUserCart(input: $input) {
					userCart {
						...userCart_UserCartFragment
					}
				}
			}
		}
	}
`;
