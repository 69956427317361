import { flex } from "@styled-system/patterns";
import tw from "twin.macro";

export const wrapperClass = flex({
	direction: "column",
	align: "flex-end",
	bg: "shade.0",
	boxShadow: "card",
	width: "full",
	borderTopRadius: "12",
	lg: {
		gap: "8",
		bottom: "24",
		width: "full",
		borderRadius: "24",
	},
	xl: {
		maxWidth: "[75rem]",
	},
});

export const childrenWrapperClass = flex({
	transform: "translateY(8px)",
	align: "center",
	width: "full",
	p: "16",
	pb: "24",
	bg: "[rgba(243, 243, 244, 0.8)]",
	borderTopRadius: "6",
	backdropFilter: "[blur(24px)]",
});

export const contentWrapperClass = flex({
	position: "relative",
	justify: "flex-end",
	zIndex: "12",
	width: "full",
	height: "full",
	p: "16",
	bg: "shade.0",
	borderTopRadius: "12",
	lg: {
		borderRadius: "24",
	},
});

export const buttonsWrapperClass = flex({
	justify: "flex-end",
	align: "center",
	gap: "16",
});

export const BottomTextWrapper = tw.div`
	px-16
`;
