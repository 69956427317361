import { css, cva } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = css({
	display: "grid",
	height: "full",
	bg: "shade.5",
	gridTemplateRows: "[1fr min-content]",
	xl: {
		gridTemplateColumns: "[min-content 1fr]",
	},
});

export const navbarWrapperClass = css({
	zIndex: "10",
	gridRowStart: "2",
	gridRowEnd: "2",
	boxShadow: "[0rem -0.125rem 0.625rem 0rem token(primary.100/10)]",
	xl: {
		gridRow: "[auto]",
		boxShadow: "[none]",
	},
});

export const screenWrapperClass = flex({
	direction: "column",
	gridRowStart: "1",
	gridRowEnd: "1",
	overflow: "hidden",
	xl: {
		gridRow: "[auto]",
	},
});

export const scrollWrapperClass = flex({
	direction: "column",
	flex: "1",
	overflowY: "auto",
	xl: {
		overflow: "hidden",
	},
});

export const childrenWrapperClass = cva({
	base: {
		flex: "1",
		bg: "shade.0",
		borderTopRadius: "24",
		p: "24",
		md: {
			p: "40",
		},
		xl: {
			borderTopRadius: "0",
			ml: "24",
		},
	},
	variants: {
		noPadding: {
			true: {
				p: "0",
				md: {
					p: "0",
				},
			},
		},
	},
});

export const bottomContentWrapperClass = css({
	flex: "none",
	zIndex: "10",
	bg: "shade.0",
	boxShadow: "[0rem -0.125rem 0.625rem 0rem token(primary.100/10)]",
	xl: {
		ml: "24",
	},
});
