import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { TabPanel, type TabViewTabChangeEvent } from "primereact/tabview";
import { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { notesTabbar_NotesQuery } from "@relay/notesTabbar_NotesQuery.graphql";
import { NotesScreenContext } from "@screens/notes/notes.context";
import { QUERY } from "./notes-tabbar.graphql";
import { NotesTabbarSkeleton } from "./notes-tabbar.skeleton";
import { NotesOverview } from "../notes-overview";
import { ReminderOverviewComponent } from "../reminder-overview";

const NotesTabbarComponent = () => {
	const query = useLazyLoadQuery<notesTabbar_NotesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const {
		activeIndex,
		helpers: { setActiveIndex },
	} = useContext(NotesScreenContext);

	const handleOnTabChange = (event: TabViewTabChangeEvent) => {
		setActiveIndex(event.index);
	};

	return (
		<TabBar activeIndex={activeIndex} onTabChange={handleOnTabChange} disableMaxParentHeight>
			<TabPanel header="Notizen">
				<NotesOverview notesFragmentRef={query} />
			</TabPanel>
			<TabPanel header="Erinnerungen">
				<ReminderOverviewComponent reminderFragmentRef={query} />
			</TabPanel>
		</TabBar>
	);
};

export const NotesTabbar = withSuspense(NotesTabbarComponent, NotesTabbarSkeleton);
