import { css } from "@styled-system/css";

export const wrapperClass = css({
	marginTop: "40",
	height: "full",
});

export const listClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "12",
});
