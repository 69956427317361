import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = css({
	display: "none",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "full",
	height: "full",
	backgroundColor: "primary.100",
	px: "64",
	"2xl": {
		display: "flex",
	},
});

export const quoteWrapperClass = flex({
	flexDirection: "column",
	gap: "16",
	borderLeft: "3px solid",
	borderColor: "[#4ACE7F]",
	pl: "20",
});

export const quoteClass = flex({
	flexDirection: "column",
	gap: "64",
});

export const quoteHeadlineClass = css({
	color: "shade.0",
	fontSize: "22",
	lineHeight: "28",
	maxWidth: "[50rem]",
	fontWeight: "bold",
});

export const quoteAuthorClass = css({
	color: "shade.0",
	fontSize: "14",
	fontWeight: "[500]",
});

export const marketingImageClass = css({
	border: "10px solid",
	borderColor: "shade.0",
	borderRadius: "[40px]",
});
