import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	flexDirection: "column",
	justifyContent: "flex-start",
	gap: "24",
	height: "full",
	width: "full",
	paddingTop: "32",
	paddingX: "40",
	flex: "1",
	md: {
		gap: "40",
	},
});

export const tabPanelContainerClass = css({
	alignItems: "flex-start",
	height: "full",
	width: "full",
	flex: "1",
});

export const tabPanelClass = css({
	height: "full",
	width: "full",
	flex: "1",
});
