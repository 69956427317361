import { css } from "@styled-system/css";

export const inlineWrapperClass = css({
	flex: "1",
	minWidth: "[min(20rem,100vw)]",
	px: "24",
	md: {
		boxSizing: "content-box",
	},
});
