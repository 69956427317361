import { graphql } from "react-relay";

export const ORDERS_QUERY = graphql`
	query invoicesTab_OrdersQuery($first: Int, $after: String) {
		...invoicesTab_QueryFragment @arguments(first: $first, after: $after)
	}
`;

export const INVOICES_TAB_QUERY_FRAGMENT = graphql`
	fragment invoicesTab_QueryFragment on Query
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String", defaultValue: null }
	)
	@refetchable(queryName: "invoicesTab_InvoicesTabQueryFragmentRefetch") {
		Viewer {
			Billing {
				ViewerOrders(first: $first, after: $after)
					@connection(key: "invoicesTab_ViewerOrders") {
					edges {
						node {
							id
							...invoiceCard_OrderFragment
						}
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;
