import { BottomSheet } from "@thekeytechnology/academies-lib-webapp/components/bottom-sheet";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useState } from "react";
import { HubspotHiddenGlobalStyle } from "@components/hubspot-style/hubspot-style.styles";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	actionWrapperClass,
	batchEditWrapperClass,
	centerSpacerClass,
	hoverOpacityClass,
	itemWrapperClass,
	optionsWrapperClass,
	titleWrapperClass,
	verticalDividerClass,
} from "./batch-edit.styles";
import { BatchEditProps, WrapperComponentProps } from "./batch-edit.types";

const WrapperComponent = (props: WrapperComponentProps) => (
	<button type="button" className={itemWrapperClass} {...props} />
);

export const BatchEdit = ({ isVisible, optionNodes, selectedCount, onClear }: BatchEditProps) => {
	const [isVisibleBottomSheet, setIsVisibleBottomSheet] = useState(false);
	const { isMediumUp } = useWindowSize();

	const handleChooseOptionOnClick = () => {
		setIsVisibleBottomSheet(true);
	};

	const handleCloseBottomSheetOnClick = () => {
		setIsVisibleBottomSheet(false);
	};

	const nodes = optionNodes(WrapperComponent);
	const options = (
		<div className={optionsWrapperClass}>
			{nodes.map((option, index) => [
				option,
				index < nodes.length - 1 && !isMediumUp && <Divider />,
			])}
		</div>
	);

	const isBottomSheetVisibleOnMobile = isVisible && isVisibleBottomSheet && !isMediumUp;

	return (
		<>
			{isVisible && (
				<div className={batchEditWrapperClass}>
					{isMediumUp ? (
						options
					) : (
						<div className={actionWrapperClass} onClick={handleChooseOptionOnClick}>
							<Icon icon="arrowDown" />
							<P2Span>Aktion auswählen</P2Span>
						</div>
					)}
					<div className={verticalDividerClass} />
					<P2Span>
						{isMediumUp ? "Ausgewählt " : ""}
						{selectedCount}
					</P2Span>
					<div className={hoverOpacityClass}>
						<Icon icon="close" onClick={onClear} />
					</div>
				</div>
			)}
			<BottomSheet
				isVisible={isBottomSheetVisibleOnMobile}
				onDismiss={handleCloseBottomSheetOnClick}
			>
				<div className={titleWrapperClass}>
					<H1Span>Aktion auswählen</H1Span>
					<P2Span>{selectedCount} Elemente ausgewählt</P2Span>
				</div>
				<div className={centerSpacerClass}>
					{options}
					<Divider />
				</div>
				<Button fillParent label="Schließen" onClick={handleCloseBottomSheetOnClick} />
			</BottomSheet>
			{!isMediumUp && isVisible && <HubspotHiddenGlobalStyle />}
		</>
	);
};
