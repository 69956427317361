import { graphql } from "react-relay";

export const QUERY = graphql`
	query notesTabbar_NotesQuery(
		$includeNoteSourceKinds: [NoteSourceKind!]
		$includeNoteColors: [NoteColor!]
		$noteColorToSortBy: NoteColor
		$filterByText: String
	) {
		...notesOverview_QueryFragment
			@arguments(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
			)
		...reminderOverview_QueryFragment
			@arguments(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
			)
	}
`;
