import styled from "styled-components";

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

export const InvitationTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 0.75rem;
`;

export const TextCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 0;
`;

export const TextRight = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: right;
	gap: 0;
`;

export const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`;
