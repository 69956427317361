import { Color } from "@thekeytechnology/epic-ui";

import styledComponents, { css, RuleSet } from "styled-components";
import { styled } from "@styled-system/jsx";
import { BreakpointInPx } from "@themes/breakpoint";
import { shade100 } from "./colors";

export type TypographyProps = {
	bold?: boolean;
	extraBold?: boolean;
	underline?: boolean;
	strikethrough?: boolean;
	italic?: boolean;
	tkaColor?: Color;
	inheritColor?: boolean;
	onClick?: (event: React.MouseEvent<any>) => void;
};

export type TkaTypography = RuleSet<object>;

/**
 * @deprecated This extension should not be used
 */
const FontExtensionCSS = css<TypographyProps>`
	${({ bold }) =>
		bold === false ? "font-weight: 400;" : bold === true ? "font-weight: 700;" : ""}
	${({ extraBold }) =>
		extraBold === false ? "font-weight: 400;" : extraBold === true ? "font-weight: 900;" : ""}
	${({ underline }) => (underline ? "text-decoration: underline;" : "")}
	${({ strikethrough }) => (strikethrough ? "text-decoration: line-through;" : "")}
	${({ italic }) => (italic ? "font-style: italic;" : "")}
	${({ tkaColor, inheritColor }) =>
		tkaColor ? `color: ${tkaColor};` : inheritColor ? "" : `color: ${shade100};`}
	${({ onClick }) => (onClick ? "cursor: pointer;" : "")}
`;

/**
 * @deprecated This typography should not be used
 */
const DefaultFontCSS = css`
	font-family: Roboto, sans-serif;
	font-weight: 400;
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaH1Typography = css`
	${DefaultFontCSS}
	line-height: 1.625rem;
	font-size: 1.25rem;
	font-weight: 700;
	@media screen and (min-width: ${BreakpointInPx.medium}px) {
		line-height: 1.75rem;
		font-size: 1.375rem;
	}
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaH1Span = styledComponents.span<TypographyProps>`
	${TkaH1Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaH2Typography = css`
	${DefaultFontCSS}
	line-height: 1.25rem;
	font-size: 1rem;
	font-weight: 700;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaH2Span = styledComponents.span<TypographyProps>`
	${TkaH2Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaH3Typography = css`
	${DefaultFontCSS}
	line-height: 1.25rem;
	font-size: 0.875rem;
	font-weight: 700;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaH3Span = styledComponents.span<TypographyProps>`
	${TkaH3Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaH4Typography = css`
	${DefaultFontCSS}
	line-height: 1rem;
	font-size: 0.75rem;
	font-weight: 700;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaH4Span = styledComponents.span<TypographyProps>`
	${TkaH4Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaP1Typography = css`
	${DefaultFontCSS}
	line-height: 1.75rem;
	font-size: 1rem;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaP1Span = styledComponents.span<TypographyProps>`
	${TkaP1Typography}
`;

export const P2_LINE_HEIGHT_REM = 1.375;
/**
 * @deprecated This typography should not be used
 */
export const TkaP2Typography = css`
	${DefaultFontCSS}
	line-height: ${P2_LINE_HEIGHT_REM}rem;
	font-size: 0.875rem;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaP2Span = styledComponents.span<TypographyProps>`
	${TkaP2Typography}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaP2Anchor = styledComponents.a<TypographyProps>`
	${TkaP2Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaP3Typography = css`
	${DefaultFontCSS}
	line-height: 1.125rem;
	font-size: 0.75rem;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaP3Span = styledComponents.span<TypographyProps>`
	${TkaP3Typography}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaP3Label = styledComponents.label<TypographyProps>`
	${TkaP3Typography}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaP3Anchor = styledComponents.a<TypographyProps>`
	${TkaP3Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaL1Typography = css`
	${DefaultFontCSS}
	line-height: 0.625rem;
	font-size: 0.625rem;
	${FontExtensionCSS}
`;
/**
 * @deprecated This font should not be used
 */
export const TkaL1Span = styledComponents.span<TypographyProps>`
	${TkaL1Typography}
`;

/**
 * @deprecated This typography should not be used
 */
export const TkaB1Typography = css`
	${DefaultFontCSS}
	line-height: 1rem;
	font-size: 0.875rem;
	${FontExtensionCSS}
`;

/**
 * @deprecated This font should not be used
 */
export const TkaB1Span = styledComponents.span<TypographyProps>`
	${TkaB1Typography}
`;

export const H1Span = styled("span", {
	base: {
		textStyle: "h1",
	},
});

export const H2Span = styled("span", {
	base: {
		textStyle: "h2",
	},
});

export const H3Span = styled("span", {
	base: {
		textStyle: "h3",
	},
});

export const H4Span = styled("span", {
	base: {
		textStyle: "h4",
	},
});

export const P1Span = styled("span", {
	base: {
		textStyle: "p1",
	},
});

export const P2Span = styled("span", {
	base: {
		textStyle: "p2",
	},
});

export const P2Anchor = styled("a", {
	base: {
		textStyle: "p2Anchor",
	},
});

export const P3Span = styled("span", {
	base: {
		textStyle: "p3",
	},
});
export const P3Label = styled("label", {
	base: {
		textStyle: "p3",
	},
});
export const P3Anchor = styled("a", {
	base: {
		textStyle: "p3Anchor",
	},
});

export const L1Span = styled("span", {
	base: {
		textStyle: "l1",
	},
});

export const B1Span = styled("span", {
	base: {
		textStyle: "b1",
	},
});
