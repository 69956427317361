import { css, cva } from "@styled-system/css";

export const labelClass = css({
	display: "block",
	marginBottom: "4",
});

export const chipsClass = css({
	width: "full",
	"& input": {
		fontFamily: "default",
		fontSize: "14",
		_placeholder: {
			fontFamily: "default",
			fontSize: "14",
			color: "shade.20",
		},
		_autofill: {
			backgroundColor: "transparent",
		},
	},
	"& > ul": {
		width: "full",
		padding: "[0.375rem 2.5rem!important]",
	},
	"& > .p-chips": {
		paddingLeft: "40",
		width: "full",
		fontFamily: "default",
		fontSize: "14",
	},
	"&.p-chips-token p-highlight": {
		margin: "2!",
	},
	"&.p-chips .p-chips-multiple-container .p-chips-token": {
		padding: "0!",
		margin: "2!",
		borderRadius: "4!",
		overflow: "hidden!",
		backgroundColor: "shade.10!",
		"& > span": {
			paddingX: "12",
			paddingY: "8",
		},
	},
});

export const chipsWrapperClass = css({
	position: "relative",
});

export const growClass = css({
	position: "relative",
	display: "flex",
	width: "full",
});

export const iconWrapperClass = css({
	position: "absolute",
	top: "50%",
	left: "8",
	color: "shade.20",
	transform: "translateY(-50%)",
});

export const closeChipWrapperClass = css({
	backgroundColor: "shade.20",
	padding: "[0.5625rem]",
});

export const smallTextBelowClass = cva({
	base: {
		display: "block",
		marginTop: "8",
		color: "primary.100",
	},
	variants: {
		hasError: {
			true: {
				color: "error.100",
			},
		},
	},
	defaultVariants: {
		hasError: false,
	},
});
