import { css, cva } from "@styled-system/css";

export const menuWrapperClass = css({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	gap: "24",
	md: {
		width: "[min(22rem, 90vw)]",
	},
});

export const messagesButtonClass = css({
	position: "absolute",
	width: "40",
	height: "40",
	top: "-10",
	right: "-10",
	zIndex: 10,
});

export const unseenMessagesCountClass = css({
	position: "absolute",
	paddingX: "8",
	top: "-2",
	right: "-2",
	zIndex: 10,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: "secondary.100",
	borderRadius: "full",
	color: "primary.100",
	fontWeight: "bold",
});

export const currentUserWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "[1.625rem]",
});

export const avatarWrapperClass = css({
	position: "relative",
});

export const userNameWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
});

export const pointsWrapperClass = cva({
	base: {
		position: "absolute",
		bottom: "0",
		left: "50%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: "4",
		transform: "translate(-50%, 50%)",
		borderRadius: "20",
		px: "8",
		py: "4",
	},
	variants: {
		background: {
			white: {
				backgroundColor: "shade.0",
			},
			gray: {
				backgroundColor: "shade.5",
			},
		},
	},
	defaultVariants: {
		background: "gray",
	},
});
