import { Carousel, CarouselPageChangeEvent } from "primereact/carousel";
import { useCallback, useContext, useEffect, useState } from "react";
import { useFragment } from "react-relay";

import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { TextWithPagesElementControls } from "@components/text-with-pages-element-controls/text-with-pages-element-controls.component";
import { TextWithPagesElementPage } from "@components/text-with-pages-element-page";
import { NUM_SCROLL } from "@components/text-with-pages-learn-element/text-with-pages-learn-element.consts";
import { TEXT_WITH_PAGES_LEARN_ELEMENT } from "@components/text-with-pages-learn-element/text-with-pages-learn-element.graphql";
import {
	carouselWrapperClass,
	pagesGroupClass,
} from "@components/text-with-pages-learn-element/text-with-pages-learn-element.styles";
import {
	TextWithPagesElementPageData,
	TextWithPagesLearnElementProps,
} from "@components/text-with-pages-learn-element/text-with-pages-learn-element.types";
import { textWithPagesLearnElement_TextWithPagesLearnElementFragment$key } from "@relay/textWithPagesLearnElement_TextWithPagesLearnElementFragment.graphql";

export const TextWithPagesLearnElement = ({
	learnElementFragmentRef,
}: TextWithPagesLearnElementProps) => {
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const [seenPages, setSeenPages] = useState(new Set<number>([0]));
	const { setCanGoNext, addGoToNextOnClickListener } = useContext(ContentSubmissionModalContext);
	const element = useFragment<textWithPagesLearnElement_TextWithPagesLearnElementFragment$key>(
		TEXT_WITH_PAGES_LEARN_ELEMENT,
		learnElementFragmentRef ?? null,
	);

	const pagesToRender = element ? [...element.pages] : [];

	const groupedPages = pagesToRender.reduce<TextWithPagesElementPageData[][]>(
		(groups, page, index) => {
			if (index % 2 === 0) {
				groups.push([page]);
			} else {
				groups[groups.length - 1].push(page);
			}
			return groups;
		},
		[],
	);

	const totalAmountOfPages = groupedPages.length;

	const hasMoreThanTwoPages = totalAmountOfPages > 1;

	const onPageChange = (event: CarouselPageChangeEvent) => {
		setCurrentPageIndex(event.page);
		setSeenPages((prevSeenPages) => new Set(prevSeenPages).add(event.page));
	};

	const showPreviousPage = () => {
		if (currentPageIndex === 0) return;
		setCurrentPageIndex(currentPageIndex - 1);
	};

	const showNextPage = () => {
		if (currentPageIndex === totalAmountOfPages) return;
		setCurrentPageIndex(currentPageIndex + 1);
	};

	const handleOnIndicatorClick = (page: number) => {
		setCurrentPageIndex(page);
		setSeenPages((prevSeenPages) => new Set(prevSeenPages).add(page));
	};

	const checkIfAllPagesSeen = useCallback(() => {
		const notAllPagesSeen = seenPages.size < totalAmountOfPages;
		setCanGoNext(!notAllPagesSeen);

		return notAllPagesSeen;
	}, [seenPages, totalAmountOfPages, setCanGoNext]);

	const handleGoToNextClicked = useCallback(() => {
		return checkIfAllPagesSeen();
	}, [checkIfAllPagesSeen]);

	useEffect(() => {
		const removeListener = addGoToNextOnClickListener(handleGoToNextClicked);
		checkIfAllPagesSeen();
		return () => {
			removeListener();
		};
	}, [handleGoToNextClicked]);

	const itemTemplate = (pageGroup: TextWithPagesElementPageData[]) => (
		<div className={pagesGroupClass}>
			{pageGroup.map((page) => (
				<TextWithPagesElementPage key={page.id} pageFragmentRef={page.data} />
			))}
		</div>
	);

	return (
		<div className={carouselWrapperClass}>
			<Carousel
				value={groupedPages}
				page={currentPageIndex}
				itemTemplate={itemTemplate}
				showIndicators={false}
				showNavigators={false}
				numVisible={1}
				numScroll={NUM_SCROLL}
				onPageChange={onPageChange}
			/>
			{hasMoreThanTwoPages && (
				<TextWithPagesElementControls
					amountOfItems={totalAmountOfPages}
					currentPageIndex={currentPageIndex}
					onIndicatorClick={handleOnIndicatorClick}
					onPreviousPageClick={showPreviousPage}
					onNextPageClick={showNextPage}
				/>
			)}
		</div>
	);
};
