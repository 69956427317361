import {
	DialogTemplate,
	DialogTemplateProps,
} from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { Carousel, type CarouselPageChangeEvent } from "primereact/carousel";
import { useFragment } from "react-relay";
import { colorShade100Class } from "@themes/color-classes";
import { H1Span, P3Span } from "@themes/font-tags";
import { PERSOLOG_CLASS_TYPE_ORDER } from "./persolog-evaluation-class-modal.consts";
import { PERSOLOG_EVALUATION_LEARN_ELEMENT_FRAGMENT } from "./persolog-evaluation-class-modal.graphql";
import {
	carouselContainerClass,
	carouselContentClass,
	carouselIndicatorButtonClass,
	carouselIndicatorClass,
	carouselIndicatorContainerClass,
	carouselItemClass,
	carouselRootClass,
	classHeaderClass,
	classImageClass,
	classTextClass,
	classTypeTextClass,
	classWrapperClass,
	modalCloseButtonClass,
} from "./persolog-evaluation-class-modal.styles";
import { PersologEvaluationClassModalProps } from "./persolog-evaluation-class-modal.types";
import { getClassTypeName } from "./persolog-evaluation-class-modal.utils";

export const PersologEvaluationClassModal = ({
	isVisible = true,
	onDismiss,
	pageClassType,
	onPageClassTypeChange,
	persologEvaluationLearnElementFragmentRef,
}: PersologEvaluationClassModalProps) => {
	const element = useFragment(
		PERSOLOG_EVALUATION_LEARN_ELEMENT_FRAGMENT,
		persologEvaluationLearnElementFragmentRef ?? null,
	);
	const { isMediumUp } = useWindowSize();

	const classes =
		element?.evaluation.classes
			.map((evaluation) => {
				const foundClass = element.classes.find(
					(c) => c.classType === evaluation.classType,
				);
				return {
					...evaluation,
					...foundClass,
				};
			})
			.sort((a, b) => {
				return (
					PERSOLOG_CLASS_TYPE_ORDER.indexOf(a.classType) -
					PERSOLOG_CLASS_TYPE_ORDER.indexOf(b.classType)
				);
			}) ?? [];

	const handleOnClose = () => {
		onDismiss?.();
	};

	const handleOnPageClassTypeChange = (event: CarouselPageChangeEvent) => {
		const newClassType = PERSOLOG_CLASS_TYPE_ORDER[event.page];
		onPageClassTypeChange?.(newClassType);
	};

	const currentIndex = PERSOLOG_CLASS_TYPE_ORDER.findIndex((c) => c === pageClassType);

	const dialogTemplateProps =
		!isMediumUp &&
		({
			primaryButtonStretch: true,
			primaryButtonLabel: "Schließen",
			primaryButtonColorVersion: "tertiary",
			onPrimaryButtonClick: handleOnClose,
		} as DialogTemplateProps);

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnClose}>
			<DialogTemplate {...dialogTemplateProps} maxWidthRem={45} hasSeperator={!isMediumUp}>
				<button type="button" onClick={handleOnClose} className={modalCloseButtonClass}>
					<Icon icon="close" />
				</button>
				<Carousel
					value={classes}
					page={currentIndex}
					onPageChange={handleOnPageClassTypeChange}
					numVisible={1}
					numScroll={1}
					showNavigators={false}
					pt={{
						root: {
							className: carouselRootClass,
						},
						content: {
							className: carouselContentClass,
						},
						container: {
							className: carouselContainerClass,
						},
						item: {
							className: carouselItemClass,
						},
						indicators: {
							className: carouselIndicatorContainerClass,
						},
						indicator: {
							className: carouselIndicatorClass,
						},
						indicatorButton: {
							className: carouselIndicatorButtonClass,
						},
					}}
					itemTemplate={({ classType, image, percentage, text, title }) => (
						<div key={classType} className={classWrapperClass}>
							<div className={classHeaderClass}>
								{image && image.url && (
									<img
										src={image.url}
										alt={image.name}
										className={classImageClass}
									/>
								)}
								<H1Span className={colorShade100Class}>
									{stripHtml(title ?? "")}
								</H1Span>
								<P3Span className={classTypeTextClass({ classType: classType })}>
									{getClassTypeName(classType)} {percentage}%
								</P3Span>
							</div>
							{text && <span className={classTextClass}>{parseHtml(text)}</span>}
						</div>
					)}
				/>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
