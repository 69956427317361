/**
 * @generated SignedSource<<e69471954aff0009de0df4b8b06d5c45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Billing_CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type UpdateBusinessCustomerDetailsInput = {
  city: string;
  clientMutationId?: string | null;
  company: string;
  companyDetails?: string | null;
  companyType: Billing_CompanyType;
  country: string;
  houseNumber: string;
  invoiceEmail: string;
  orderId: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  taxIdentificationNumber?: string | null;
};
export type shoppingCartInvoice_UpdateBusinessBillingDetailsMutation$variables = {
  input: UpdateBusinessCustomerDetailsInput;
};
export type shoppingCartInvoice_UpdateBusinessBillingDetailsMutation$data = {
  readonly Billing: {
    readonly updateBusinessCustomerDetails: {
      readonly order: {
        readonly " $fragmentSpreads": FragmentRefs<"shoppingCartInvoice_OrderFragment">;
      };
    } | null;
  };
};
export type shoppingCartInvoice_UpdateBusinessBillingDetailsMutation = {
  response: shoppingCartInvoice_UpdateBusinessBillingDetailsMutation$data;
  variables: shoppingCartInvoice_UpdateBusinessBillingDetailsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shoppingCartInvoice_UpdateBusinessBillingDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UpdateBusinessCustomerDetailsPayload",
            "kind": "LinkedField",
            "name": "updateBusinessCustomerDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "shoppingCartInvoice_OrderFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shoppingCartInvoice_UpdateBusinessBillingDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UpdateBusinessCustomerDetailsPayload",
            "kind": "LinkedField",
            "name": "updateBusinessCustomerDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Billing_CustomerDetails",
                    "kind": "LinkedField",
                    "name": "customerDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "houseNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phoneNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "salutation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "invoiceEmail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dateOfBirth",
                                "storageKey": null
                              }
                            ],
                            "type": "Billing_PrivateCustomerDetails",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "company",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyDetails",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyType",
                                "storageKey": null
                              }
                            ],
                            "type": "Billing_BusinessCustomerDetails",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Totals",
                        "kind": "LinkedField",
                        "name": "totals",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Price",
                            "kind": "LinkedField",
                            "name": "includingAllDiscounts",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grossPrice",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "selectedPaymentMethod",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MonthlyOption",
                            "kind": "LinkedField",
                            "name": "chosenOption",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rateCount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MonthlyPaymentMethod",
                        "abstractKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paymentMethodType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Purchaser",
                    "kind": "LinkedField",
                    "name": "purchaser",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "914dc1345124c07b37dbc2062c79d34a",
    "id": null,
    "metadata": {},
    "name": "shoppingCartInvoice_UpdateBusinessBillingDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation shoppingCartInvoice_UpdateBusinessBillingDetailsMutation(\n  $input: UpdateBusinessCustomerDetailsInput!\n) {\n  Billing {\n    updateBusinessCustomerDetails(input: $input) {\n      order {\n        ...shoppingCartInvoice_OrderFragment\n        id\n      }\n    }\n  }\n}\n\nfragment basketTimeline_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n    }\n  }\n  customerDetails {\n    data {\n      __typename\n      kind\n    }\n  }\n  selectedPaymentMethod {\n    __typename\n    kind\n    ... on MonthlyPaymentMethod {\n      chosenOption {\n        rateCount\n      }\n    }\n  }\n}\n\nfragment paypalButton_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n    }\n  }\n  purchaser {\n    userId\n  }\n}\n\nfragment shoppingCartInvoice_OrderFragment on Order {\n  customerDetails {\n    street\n    houseNumber\n    city\n    postalCode\n    country\n    phoneNumber\n    salutation\n    firstName\n    lastName\n    invoiceEmail\n    data {\n      __typename\n      kind\n      ... on Billing_PrivateCustomerDetails {\n        title\n        dateOfBirth\n      }\n      ... on Billing_BusinessCustomerDetails {\n        company\n        companyDetails\n        companyType\n      }\n    }\n  }\n  ...shoppingCartTemplate_OrderFragment\n}\n\nfragment shoppingCartTemplate_OrderFragment on Order {\n  ...basketTimeline_OrderFragment\n  selectedPaymentMethod {\n    __typename\n    kind\n    paymentMethodType\n  }\n  ...paypalButton_OrderFragment\n}\n"
  }
};
})();

(node as any).hash = "fadac2c6f38292e899a8437ca0661709";

export default node;
