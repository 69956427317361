import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, type Document } from "@contentful/rich-text-types";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ValidatedInputSwitch } from "@thekeytechnology/academies-lib-webapp/components/validated-input-switch";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { colorSecondary100Class, colorShade20Class } from "@themes/color-classes";
import { H1Span, P1Span, P2Span, P3Span } from "@themes/font-tags";
import {
	ActionButtonsWrapper,
	FormWrapper,
	InputsWrapper,
	SwitchTextWrapper,
	SwitchWrapper,
	TextCenter,
} from "./sign-up-agb-step.styles";
import { type SignUpAGBStepFormState, type SignUpAGBStepProps } from "./sign-up-agb-step.types";

export const SignUpAGBStep = ({
	onNext,
	onPrevious,
	currentStep,
	maxSteps,
}: SignUpAGBStepProps) => {
	const { t } = useTkaTranslation("registerForm");
	const navigate = useNavigate();

	const form = useFormik<SignUpAGBStepFormState>({
		initialValues: {
			agbAccepted: false,
			adsAccepted: false,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			agbAccepted: Yup.bool()
				.oneOf([true])
				.required(t("register_form.section4ValidationRequiredAgb")),
			adsAccepted: Yup.bool().optional(),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	const showStepInfo = maxSteps !== undefined && currentStep !== undefined;

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				{showStepInfo && (
					<TextCenter>
						<P1Span>
							{currentStep}/{maxSteps}
						</P1Span>
					</TextCenter>
				)}
				<TextCenter>
					<H1Span>{t("register_form.section4Title")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<SwitchTextWrapper>
						<SwitchWrapper>
							<ValidatedInputSwitch
								formikConfig={form}
								name={"agbAccepted"}
								required
							/>
						</SwitchWrapper>
						<P2Span>
							{documentToReactComponents(
								t("register_form.section4AgbText") as unknown as Document,
								{
									renderNode: {
										[INLINES.HYPERLINK]: (node, children) => (
											<P2Span
												className={colorSecondary100Class}
												onClick={() => {
													navigate(node.data.uri as string);
												}}
											>
												{children}
											</P2Span>
										),
										[BLOCKS.PARAGRAPH]: (_, children) => (
											<span>{children}</span>
										),
									},
									renderText: (text) => <span>{text}</span>,
								},
							)}
						</P2Span>
					</SwitchTextWrapper>
					<SwitchTextWrapper>
						<SwitchWrapper>
							<ValidatedInputSwitch
								formikConfig={form}
								name={"adsAccepted"}
								required
							/>
						</SwitchWrapper>
						<P2Span>{t("register_form.section4AdsOptInText")}</P2Span>
					</SwitchTextWrapper>
				</InputsWrapper>
				<ActionButtonsWrapper>
					<Button
						type="button"
						iconName="arrowLeft"
						colorVersion={"tertiary"}
						onClick={onPrevious}
					/>
					<Button
						fillParent
						label={t("register_form.section4ButtonText")}
						iconName="arrowRight"
					/>
				</ActionButtonsWrapper>
				<P3Span className={colorShade20Class}>{t("register_form.section4Legend")}</P3Span>
			</FormWrapper>
		</form>
	);
};
