import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useLazyLoadQuery } from "react-relay";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { messages_MessagesQuery } from "@relay/messages_MessagesQuery.graphql";
import { QUERY } from "@screens/messages/messages.graphql";
import { MessagesScreenSkeleton } from "@screens/messages/messages.skeleton";
import { MessagesOverview } from "@screens/messages/parts/messages-overview";
import { wrapperClass } from "./messages.styles";

const MessagesScreenComponent = () => {
	const query = useLazyLoadQuery<messages_MessagesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	return (
		<ScreenWithProfileMenuLayout>
			<div className={wrapperClass}>
				<MessagesOverview messagesFragmentRef={query} />
			</div>
		</ScreenWithProfileMenuLayout>
	);
};

export const MessagesScreen = withSuspense(MessagesScreenComponent, MessagesScreenSkeleton);
