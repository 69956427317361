import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { AxiosResponse } from "axios";
import { useContext, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { H1Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./invite-member-modal.graphql";
import { inviteFormWrapperClass, wrapperClass } from "./invite-member-modal.styles";
import { InviteMemberModalProps } from "./invite-member-modal.types";
import { StandardInviteForm } from "../standard-invite-form/standard-invite-form.component";
import {
	InviteMembersMutationResponse,
	StandardInviteFormRef,
} from "../standard-invite-form/standard-invite-form.types";
import { SuccessInviteComponent } from "../success-invite/success-invite.component";

export const InviteMemberModal = ({
	onDismiss,
	isVisible,
	queryFragmentRef,
}: InviteMemberModalProps) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const formRef = useRef<StandardInviteFormRef>(null);
	const [successModalIsVisible, setSuccessModalIsVisible] = useState(false);
	const [buttonLoadingState, setButtonLoadingState] = useState(false);
	const [inviteMembersMutationResponse, setInviteMembersMutationResponse] = useState<
		InviteMembersMutationResponse | AxiosResponse<any, any>
	>();

	const { refetch } = useContext(RefetchContext);

	const handleOnDismiss = () => {
		onDismiss?.();
	};
	const handleSubmit = () => {
		formRef.current?.submit();
	};

	const handleOnCloseSuccessModal = () => {
		setButtonLoadingState(false);
		setSuccessModalIsVisible(false);
	};

	const handleOnSuccess = () => {
		setButtonLoadingState(false);
		onDismiss?.();
		refetch?.();
		setSuccessModalIsVisible(true);
	};

	return (
		<>
			<ResponsiveBottomSheetModal
				isVisible={isVisible}
				dismissable={false}
				onDismiss={handleOnDismiss}
			>
				<DialogTemplate
					secondaryButtonLabel="Schließen"
					onSecondaryButtonClick={handleOnDismiss}
					primaryButtonLabel="Hinzufügen"
					primaryButtonColorVersion="strong"
					onPrimaryButtonClick={handleSubmit}
					primaryButtonLoading={buttonLoadingState}
				>
					<div className={wrapperClass}>
						<H1Span>Benutzer hinzufügen</H1Span>
						<div className={inviteFormWrapperClass}>
							<StandardInviteForm
								ref={formRef}
								onSuccess={handleOnSuccess}
								queryFragmentRef={queryFragment}
								setInviteMembersMutationResponse={setInviteMembersMutationResponse}
							/>
						</div>
					</div>
				</DialogTemplate>
			</ResponsiveBottomSheetModal>

			<SuccessInviteComponent
				isVisible={successModalIsVisible}
				inviteMembersResponse={inviteMembersMutationResponse}
				onDismiss={handleOnCloseSuccessModal}
			/>
		</>
	);
};
