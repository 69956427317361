import { contentClass, childrenWidthClass } from "./auth-template-step.styles";
import { AuthTemplateStepProps } from "./auth-template-step.types";

export const AuthTemplateStep = ({ children }: AuthTemplateStepProps) => {
	return (
		<div className={contentClass}>
			<div className={childrenWidthClass}>{children}</div>
		</div>
	);
};
