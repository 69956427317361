import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { gtmTrackPurchase } from "@thekeytechnology/academies-lib-webapp/analytics";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { selectIsLoggedIn } from "@thekeytechnology/academies-lib-webapp/slices";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { PathParams } from "@thekeytechnology/epic-ui";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyLoadQuery, useFragment, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { CreditcardForm, type CreditcardFormState } from "@components/creditcard-form";
import { DirectDebitForm, type DirectDebitFormState } from "@components/direct-debit-form";
import { PaymentMethodRadioButtonTemplate } from "@components/payment-method-radio-button-template";
import { RadioButtonPanel } from "@components/radio-button-panel";
import { RootCard } from "@components/root-card";
import { ShoppingCartLegalForm } from "@components/shopping-cart-legal-form";
import {
	ShoppingCartTemplate,
	ShoppingCartTemplateSkeleton,
} from "@components/shopping-cart-template";
import { useToast } from "@hooks/useToast";
import {
	PaymentMethodType,
	shoppingCartOverview_ChangePaymentMethodMutation,
} from "@relay/shoppingCartOverview_ChangePaymentMethodMutation.graphql";
import { shoppingCartOverview_OrderFragment$key } from "@relay/shoppingCartOverview_OrderFragment.graphql";
import { shoppingCartOverview_PayOrderMutation } from "@relay/shoppingCartOverview_PayOrderMutation.graphql";
import { shoppingCartOverview_Query } from "@relay/shoppingCartOverview_Query.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { H1Span, H2Span, P1Span, P3Span } from "@themes/font-tags";
import { formatDateTime } from "@utils/date-utils";
import {
	QUERY,
	ORDER_FRAGMENT,
	CHANGE_PAYMENT_METHOD_MUTATION,
	PAY_ORDER_MUTATION,
} from "./shopping-cart-overview.graphql";
import {
	discountPercentWrapperClass,
	discountPriceClass,
	discountTitleTagWrapperClass,
	discountTitleWrapperClass,
	discountWrapperClass,
	licenseCountClass,
	licenseGroupClass,
	licenseWrapperClass,
	panelContentWrapperClass,
	paymentWrapperClass,
	priceRowClass,
	priceWrapperClass,
	screenWrapperClass,
	dividerClass,
	sofortLogoWrapperClass,
} from "./shopping-cart-overview.styles";
import { getDiscountItems, getProductItems } from "./shopping-cart-overview.utils";
import { ReactComponent as SofortLogo } from "@assets/sofortueberweisung_logo.svg";

//TODO: add-translations
export const ShoppingCartOverviewScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();

	const {
		node,
		Viewer: { Auth },
	} = useLazyLoadQuery<shoppingCartOverview_Query>(
		QUERY,
		{
			orderId: shoppingCartId ?? "",
			skip: !shoppingCartId,
		},
		{ fetchPolicy: "network-only" },
	);

	const order = useFragment<shoppingCartOverview_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const [changePaymentMethod] = useMutation<shoppingCartOverview_ChangePaymentMethodMutation>(
		CHANGE_PAYMENT_METHOD_MUTATION,
	);

	const [payOrder, isPayingOrder] =
		useMutation<shoppingCartOverview_PayOrderMutation>(PAY_ORDER_MUTATION);

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const stripe = useStripe();
	const elements = useElements();

	const navigate = useNavigate();

	const creditcardFormRef = useRef<CreditcardForm>(null);
	const directDebitFormRef = useRef<DirectDebitForm>(null);
	const sepaFormRef = useRef<DirectDebitForm>(null);
	const legalFormRef = useRef<ShoppingCartLegalForm>(null);
	const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType | undefined>(
		order.selectedPaymentMethod?.paymentMethodType,
	);
	const [legalIsValid, setLegalIsValid] = useState<boolean>(false);
	const [stripeLoading, setStripeLoading] = useState<boolean>(false);

	const { showError } = useToast();

	const cart = order?.cart;
	const products = getProductItems(cart);
	const discounts = getDiscountItems(cart) ?? [];

	const hasDiscounts = discounts.length > 0;

	const handleUpdatePaymentMethod = (methodType: PaymentMethodType) => {
		if (!shoppingCartId) return;
		const paymentType = order.selectedPaymentMethod?.kind;
		if (!paymentType || !methodType) return;

		const rateCount = order.selectedPaymentMethod?.chosenOption?.rateCount;

		changePaymentMethod({
			variables: {
				input: {
					orderId: shoppingCartId,
					selectedPaymentMethodKind: paymentType,
					paymentMethodType: methodType,
					rateCount: rateCount,
				},
			},
		});
	};

	const createPaymentMethodOnChangeHandler = (paymentMethod: PaymentMethodType) => () => {
		setPaymentMethodType(paymentMethod);
		handleUpdatePaymentMethod(paymentMethod);
	};

	const handlePayOrder = (iban?: string) => {
		if (!shoppingCartId) return;

		payOrder({
			variables: {
				input: {
					orderId: shoppingCartId,
					iban,
				},
			},
			onCompleted: () => {
				gtmTrackPurchase(
					products?.map((product) => {
						return {
							base64EncodedProductId: product.product?.id!,
							quantity: product.amount!,
							productName: product.product?.title ?? "",
						};
					}) ?? [],
					cart?.totals.includingAllDiscounts.netPrice!,
					shoppingCartId,
				);
				navigate(Path.shoppingCart.withId(shoppingCartId).success.path);
			},
		});
	};

	const handleCreditcardOnSubmit = (values: CreditcardFormState) => {
		legalFormRef.current?.submit().then((isValid) => {
			if (isValid) {
				const intentSecret = order.selectedPaymentMethod?.stripeData?.paymentIntentSecret;
				const cardElement = elements?.getElement(CardElement);
				if (!stripe || !cardElement || !intentSecret) return;
				setStripeLoading(true);
				stripe
					.confirmCardPayment(intentSecret, {
						payment_method: {
							card: cardElement,
							billing_details: {
								name: values.name,
							},
						},
					})
					.then((response) => {
						setStripeLoading(false);
						if (response.paymentIntent?.status === "succeeded" && !response.error) {
							handlePayOrder();
						} else {
							showError({
								summary: "Zahlung Fehlgeschlagen",
								detail: "Leider ist die Zahlung mit der Kreditkarte fehlgeschlagen",
							});
						}
					})
					.catch(() => {
						setStripeLoading(false);
						showError({
							summary: "Zahlung Fehlgeschlagen",
							detail: "Leider ist die Zahlung mit der Kreditkarte fehlgeschlagen",
						});
					});
			}
		});
	};

	const handleSofortOnSubmit = () => {
		const intentSecret = order.selectedPaymentMethod?.stripeData?.paymentIntentSecret;
		if (!stripe || !intentSecret) return;

		setStripeLoading(true);
		stripe
			.confirmSofortPayment(intentSecret, {
				payment_method: {
					billing_details: {
						email: order.customerDetails?.invoiceEmail ?? "",
						name:
							order.customerDetails?.data.kind === "Business"
								? order.customerDetails?.data?.company ?? ""
								: `${order.customerDetails?.firstName} ${order.customerDetails?.lastName}`,
						address: {
							city: order.customerDetails?.city ?? "",
							country: order.customerDetails?.country ?? "DE",
							line1:
								(order.customerDetails?.street ?? "") +
								(order.customerDetails?.houseNumber ?? ""),
							postal_code: order.customerDetails?.postalCode ?? "",
						},
					},
					sofort: {
						country: order.customerDetails?.country ?? "DE",
					},
				},
			})
			.then((response) => {
				setStripeLoading(false);
				if (response.paymentIntent?.status === "succeeded" && !response.error) {
					handlePayOrder();
				} else {
					showError({
						summary: "Zahlung Fehlgeschlagen",
						detail: "Leider ist die Zahlung mit der Sofort-Überweisung fehlgeschlagen",
					});
				}
			})
			.catch(() => {
				setStripeLoading(false);
				showError({
					summary: "Zahlung Fehlgeschlagen",
					detail: "Leider ist die Zahlung mit der Sofort-Überweisung fehlgeschlagen",
				});
			});
	};

	const handlePaypalOnPaymentCompleted = () => {
		handlePayOrder();
	};

	const handleDirectDebitOnSubmit = (values: DirectDebitFormState) => {
		legalFormRef.current?.submit().then((isValid) => {
			if (isValid) {
				handlePayOrder(values.iban);
			}
		});
	};

	const handleSepaOnSubmit = (values: DirectDebitFormState) => {
		legalFormRef.current?.submit().then((isValid) => {
			if (isValid) {
				handlePayOrder(values.iban);
			}
		});
	};

	const handleNextOnClick = () => {
		if (paymentMethodType === "Sepa" && isMonthlyPayment) {
			directDebitFormRef.current?.submit();
		} else if (paymentMethodType === "Sepa" && !isMonthlyPayment) {
			sepaFormRef.current?.submit();
		} else if (paymentMethodType === "Card") {
			creditcardFormRef.current?.submit();
		} else if (paymentMethodType === "Sofort") {
			handleSofortOnSubmit();
		}
	};

	const handlePreviousOnClick = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (!isLoggedIn) {
			navigate(Path.shoppingCart.withId(order.id).invoice.path);
		}
	}, [isLoggedIn]);

	const getLicenseCountLabel = (productAmount = 1, licensesPerProduct = 1) => {
		const count = productAmount * licensesPerProduct;
		if (count === 1) return "1 Lizenz";
		return `${count} Lizenzen`;
	};

	const isPaypalAllowed = order.allowedPaymentMethods?.paymentMethods?.includes("Paypal");
	const isCardAllowed = order.allowedPaymentMethods?.paymentMethods?.includes("Card");
	const isSepaAllowed = order.allowedPaymentMethods?.paymentMethods?.includes("Sepa");
	const isSofortAllowed = order.allowedPaymentMethods?.paymentMethods?.includes("Sofort");

	const rateCount = order.selectedPaymentMethod?.chosenOption?.rateCount ?? 6;
	const isMonthlyPayment = order.selectedPaymentMethod?.kind === "Monthly";

	const priceTitle = isMonthlyPayment ? `In ${rateCount} monatlichen Zahlungen` : "Gesamtpreis";

	const hasSurcharge = rateCount > 24;
	const formattedGrossPrice = formatCurrency(
		order.selectedPaymentMethod?.chosenOption?.totalAmount.grossPrice,
	);

	const monthlySurchargeInfo = hasSurcharge
		? `Mit dem Aufschlag für monatliche Zahlweise ergibt sich eine Gesamtzahlsumme von ${formattedGrossPrice}`
		: `Dadurch ergibt sich eine Gesamtzahlsumme von ${formattedGrossPrice}`;

	const priceInfo = isMonthlyPayment
		? monthlySurchargeInfo
		: "Dieses Weiterbildungsangebot ist im Sinne des § 4 Nr. 21 UStG von der Umsatzsteuer befreit.";
	const grossPriceWithDiscountsFormated = formatCurrency(
		cart?.totals.includingAllDiscounts.grossPrice,
	);
	const monthlyRateFormated = formatCurrency(
		order.selectedPaymentMethod?.chosenOption?.monthlyRate.grossPrice,
	);
	const price = isMonthlyPayment
		? `${monthlyRateFormated} / Monat`
		: grossPriceWithDiscountsFormated;

	const paymentMethodTitle = isMonthlyPayment
		? "Zahlung per Monatliche Lastschrift"
		: "Zahlungsart";

	const disableNext =
		isPayingOrder || stripeLoading || (paymentMethodType === "Paypal" && !legalIsValid);

	return (
		<ShoppingCartTemplate
			disableNext={disableNext}
			onNext={handleNextOnClick}
			onPrevious={handlePreviousOnClick}
			onPaymentCompleted={handlePaypalOnPaymentCompleted}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<div data-no-selection-menu className={screenWrapperClass}>
				<H1Span>Übersicht & Zahlung</H1Span>
				{products?.map((item) => {
					return (
						<div key={item.product?.id} className={licenseGroupClass}>
							<div className={licenseWrapperClass}>
								<RootCard
									imageUrl={
										item.product?.data?.licenseDefinition?.data.rootInfo?.image
											?.url
									}
									title={item.product?.title ?? ""}
								/>
							</div>
							<div className={licenseCountClass}>
								<H2Span>
									{getLicenseCountLabel(
										item.amount,
										item.product?.data?.licenseDefinitionAmount ?? 1,
									)}
								</H2Span>
							</div>
						</div>
					);
				})}
				{hasDiscounts && <div className={dividerClass} />}
				{discounts?.map((discount) => (
					<div key={discount.code} className={discountWrapperClass}>
						<div className={discountTitleTagWrapperClass}>
							{discount.validUntil && (
								<Label
									label={`Noch bis ${formatDateTime(discount.validUntil)}`}
									severity="info"
								/>
							)}
							<div className={discountTitleWrapperClass}>
								<H2Span>{discount.title ?? "Rabatt"}</H2Span>
								<P1Span>{discount.code ?? "Rabatt Aktion"}</P1Span>
							</div>
						</div>
						<div className={discountPercentWrapperClass}>
							<H2Span>-{discount.value}%</H2Span>
						</div>
					</div>
				))}
				<div className={dividerClass} />
				<div className={priceWrapperClass}>
					<div className={priceRowClass}>
						<H2Span>{priceTitle}</H2Span>
						<P3Span>{priceInfo}</P3Span>
					</div>
					<div className={discountPriceClass}>
						{!isMonthlyPayment && hasDiscounts && (
							<H2Span>
								<s>{formatCurrency(cart?.totals.withoutDiscounts.grossPrice)}</s>
							</H2Span>
						)}
						<H2Span>{price}</H2Span>
					</div>
				</div>
				<div className={dividerClass} />
				<div className={paymentWrapperClass}>
					<H2Span>{paymentMethodTitle}</H2Span>
					{isMonthlyPayment && (
						<RadioButtonPanel
							checked={paymentMethodType === "Sepa"}
							header={
								<PaymentMethodRadioButtonTemplate
									icon="moneyWallet"
									title="Lastschrift"
								/>
							}
							onChange={createPaymentMethodOnChangeHandler("Sepa")}
						>
							<div className={panelContentWrapperClass}>
								<DirectDebitForm
									ref={directDebitFormRef}
									onSubmit={handleDirectDebitOnSubmit}
								/>
							</div>
						</RadioButtonPanel>
					)}
					{!isMonthlyPayment && (
						<>
							{isCardAllowed && (
								<RadioButtonPanel
									checked={paymentMethodType === "Card"}
									header={
										<PaymentMethodRadioButtonTemplate
											icon="creditCardMastercard1"
											title="Kreditkarte"
										/>
									}
									onChange={createPaymentMethodOnChangeHandler("Card")}
								>
									<div className={panelContentWrapperClass}>
										<CreditcardForm
											ref={creditcardFormRef}
											onSubmit={handleCreditcardOnSubmit}
										/>
									</div>
								</RadioButtonPanel>
							)}
							{isSofortAllowed && (
								<RadioButtonPanel
									checked={paymentMethodType === "Sofort"}
									header={
										<PaymentMethodRadioButtonTemplate
											iconRenderer={
												<div className={sofortLogoWrapperClass}>
													<SofortLogo />
												</div>
											}
											title="Sofort"
										/>
									}
									onChange={createPaymentMethodOnChangeHandler("Sofort")}
								/>
							)}
							{isPaypalAllowed && (
								<RadioButtonPanel
									checked={paymentMethodType === "Paypal"}
									header={
										<PaymentMethodRadioButtonTemplate
											icon="paypal"
											title="Paypal"
										/>
									}
									onChange={createPaymentMethodOnChangeHandler("Paypal")}
								/>
							)}
							{isSepaAllowed && (
								<RadioButtonPanel
									checked={paymentMethodType === "Sepa"}
									header={
										<PaymentMethodRadioButtonTemplate
											icon="moneyWallet"
											title="Kauf auf Rechnung"
										/>
									}
									onChange={createPaymentMethodOnChangeHandler("Sepa")}
								>
									<div className={panelContentWrapperClass}>
										<DirectDebitForm
											ref={sepaFormRef}
											onSubmit={handleSepaOnSubmit}
										/>
									</div>
								</RadioButtonPanel>
							)}
						</>
					)}
				</div>
				<div className={dividerClass} />
				<ShoppingCartLegalForm
					ref={legalFormRef}
					orderFragmentRef={order}
					onValidationChanged={setLegalIsValid}
				/>
			</div>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartProductsScreen = withSuspense(
	ShoppingCartOverviewScreenComponent,
	ShoppingCartTemplateSkeleton,
);

const paypalOptions = {
	clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
	currency: "EUR",
	disableFunding: "sofort,sepa,giropay,card",
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

export const ShoppingCartOverviewScreenWithPaymentContext = () => {
	return (
		<PayPalScriptProvider options={paypalOptions}>
			<Elements stripe={stripePromise}>
				<ShoppingCartProductsScreen />
			</Elements>
		</PayPalScriptProvider>
	);
};
