import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { FC, useContext, useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { NoteCard } from "@components/note-card";
import { notesOverview_QueryFragment$key } from "@relay/notesOverview_QueryFragment.graphql";
import { notesOverview_RefetchQuery } from "@relay/notesOverview_RefetchQuery.graphql";
import { NotesScreenContext } from "@screens/notes/notes.context";
import { warning100 } from "@themes/colors";
import { QUERY_FRAGMENT } from "./notes-overview.graphql";
import { NotesOverviewSkeleton } from "./notes-overview.skeleton";
import { grid } from "./notes-overview.styles";
import { NotesOverviewProps } from "./notes-overview.types";

export const NotesOverviewComponent: FC<NotesOverviewProps> = ({ notesFragmentRef }) => {
	const [query, refetch] = useRefetchableFragment<
		notesOverview_RefetchQuery,
		notesOverview_QueryFragment$key
	>(QUERY_FRAGMENT, notesFragmentRef);
	const {
		filters,
		helpers: { registerRefetch, unregisterRefetch },
	} = useContext(NotesScreenContext);

	const handleDeleteNote = () => {
		refetch(
			{
				filterByText: filters.text,
				includeNoteColors: filters.colors,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	useEffect(() => {
		const refetchId = registerRefetch(() => {
			refetch(
				{
					...filters,
					filterByText: filters.text,
					includeNoteColors: filters.colors,
				},
				{
					fetchPolicy: "store-and-network",
				},
			);
		});

		return () => {
			unregisterRefetch(refetchId);
		};
	}, []);

	useEffect(() => {
		refetch(
			{
				...filters,
				filterByText: filters.text,
				includeNoteColors: filters.colors,
			},
			{
				fetchPolicy: "store-and-network",
			},
		);
	}, [filters]);

	const edges = query?.Note?.Note.edges;
	const notes = edges?.filter((edge) => edge?.node.reminder === null) || [];

	return (
		<>
			<EmptyPlaceholder
				isVisible={notes?.length === 0}
				iconName="note"
				iconColor={warning100}
				iconBackgroundColor="warning20"
				title="Hier findest du deine Notizen"
				subtitle="Du kannst jederzeit Notizen erstellen und einsehen. Indem du einen Textabschnitt markierst, hast du auch die Möglichkeit, deine Notizen direkt zu erstellen."
			>
				<div className={grid}>
					{notes.map((note) => (
						<NoteCard
							key={note?.node.id}
							onDeleteNote={handleDeleteNote}
							noteFragmentRef={note?.node}
						/>
					))}
				</div>
			</EmptyPlaceholder>
		</>
	);
};

export const NotesOverview = withSuspense(NotesOverviewComponent, NotesOverviewSkeleton);
