import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp/components/validated-input-text";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { Button, ButtonType, InputTextType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import { colorSecondary100Class, colorSecondary120Class } from "@themes/color-classes";
import { H1Span, P2Span, P3Span } from "@themes/font-tags";
import {
	FormWrapper,
	InputsWrapper,
	InvitationTitleWrapper,
	TextCenter,
	TextRight,
} from "./login-email-password.styles";
import {
	type LoginEmailPasswordFormState,
	type LoginEmailPasswordProps,
} from "./login-email-password.types";

export const LoginEmailPassword = ({
	headerText,
	onSubmit,
	onGoToSignUp,
	onGoToForgotPassword,
}: LoginEmailPasswordProps) => {
	const { t } = useTkaTranslation("loginForm");
	const theme = useTheme();

	const form = useFormik<LoginEmailPasswordFormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("login_form.validationInvalidEmail") ?? "")
				.required(t("login_form.validationRequiredEmail") ?? ""),
			password: Yup.string()
				.min(8, t("login_form.validationInvalidPassword") ?? "")
				.required(t("login_form.validationRequiredPassword") ?? ""),
		}),
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				<TextCenter>
					<InvitationTitleWrapper>
						{headerText && <P2Span>{headerText}</P2Span>}
						<H1Span>{t("login_form.title")}</H1Span>
					</InvitationTitleWrapper>
					<H1Span>{t("login_form.title2")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						icon="email"
						name="email"
						placeholder={t("login_form.placeholderEmail")}
					/>
					<ValidatedInputText
						formikConfig={form}
						bottomLabel={t("login_form.helperTextPassword")}
						icon="lock"
						name="password"
						placeholder={t("login_form.placeholderPassword")}
						type={InputTextType.Password}
					/>
					<TextRight>
						<P3Span className={colorSecondary100Class} onClick={onGoToForgotPassword}>
							{t("login_form.forgotPassword")}
						</P3Span>
					</TextRight>
				</InputsWrapper>

				<Button
					type={ButtonType.Submit}
					borderRadius={theme.borderRadius?.borderRadiusFull}
					label={t("login_form.buttonText")}
				/>

				<TextCenter>
					<P3Span>
						{t("login_form.newHere")}&nbsp;
						<P3Span className={colorSecondary120Class} onClick={onGoToSignUp}>
							{t("login_form.createAccount")}
						</P3Span>
					</P3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
