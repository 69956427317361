import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { useEffect, useRef, useState } from "react";
import { HeaderSkeleton } from "@components/header/header.skeleton";
import { HubspotGlobalStyle } from "@components/hubspot-style";
import { NavbarSkeleton } from "@components/navbar/navbar.skeleton";
import {
	bottomContentWrapperClass,
	childrenWrapperClass,
	navbarWrapperClass,
	screenWrapperClass,
	scrollWrapperClass,
	wrapperClass,
} from "./screen-with-navbar-layout.styles";
import { ScreenWithNavbarLayoutProps } from "./screen-with-navbar-layout.types";

export const ScreenWithNavbarLayoutSkeleton = ({
	headerLeftIcon,
	canGoBack,
	noPadding = false,
	bottomContent,
	children,
}: ScreenWithNavbarLayoutProps) => {
	const bottomContentRef = useRef<HTMLDivElement>(null);
	const hasBottomContent = !!bottomContent;

	const { isXLargeUp } = useWindowSize();
	const hideNavBar = !isXLargeUp && bottomContent;

	const [bottomPadding, setBottomPadding] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!hasBottomContent || !bottomContentRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setBottomPadding((padding) => {
				const clientHeight = bottomContentRef.current?.clientHeight;
				if (clientHeight !== padding) {
					if (clientHeight === 0) {
						const firstChild = bottomContentRef.current?.firstElementChild;
						if (firstChild) {
							return firstChild.clientHeight;
						}
					}

					return clientHeight;
				}
				return padding;
			});
		});
		resizeObserver.observe(bottomContentRef.current);
		return () => resizeObserver.disconnect();
	}, [hasBottomContent]);

	const hubspotBottomMargin = bottomContent ? (bottomPadding ?? 0) / 16 : 4.7;

	return (
		<div className={wrapperClass}>
			{!hideNavBar && (
				<div className={navbarWrapperClass}>
					<NavbarSkeleton />
				</div>
			)}
			<div className={screenWrapperClass}>
				<div className={scrollWrapperClass}>
					<HeaderSkeleton canGoBack={canGoBack} leftIcon={headerLeftIcon} />
					<div className={childrenWrapperClass({ noPadding })}>{children}</div>
				</div>
				{bottomContent && (
					<div className={bottomContentWrapperClass} ref={bottomContentRef}>
						{bottomContent}
					</div>
				)}
				{(!isXLargeUp || bottomContent) && (
					<HubspotGlobalStyle bottomMarginRem={hubspotBottomMargin} />
				)}
			</div>
		</div>
	);
};
