import { graphql } from "react-relay";

export const NOTE_CONNECTION_FRAGMENT = graphql`
	fragment reminderOverview_QueryFragment on Query
	@argumentDefinitions(
		includeNoteSourceKinds: { type: "[NoteSourceKind!]", defaultValue: null }
		includeNoteColors: { type: "[NoteColor!]", defaultValue: null }
		noteColorToSortBy: { type: "NoteColor" }
		filterByText: { type: "String" }
	)
	@refetchable(queryName: "reminderOverview_RefetchQuery") {
		Note {
			Note(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
			) {
				edges {
					node {
						id
						...reminderCard_ReminderNoteFragment
						...reminderOverview_ReminderCardInlineFragment
					}
				}
			}
		}
	}
`;

export const NOTE_INLINE_FRAGMENT = graphql`
	fragment reminderOverview_ReminderCardInlineFragment on Note @inline {
		id
		content
		reminder {
			kind
			isCompleted
			remindAt
		}
	}
`;
