import { css, cva } from "@styled-system/css";

export const elementWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "32",
});

export const hrClass = css({
	display: "block",
	border: "1px solid",
	borderColor: "shade.10",
	width: "full",
});

export const resultListClass = css({
	display: "grid",
	gridTemplateColumns: "4",
	gap: "10",
	width: "full",
	pr: "24",
	paddingY: "20",
	marginX: "auto",
	md: {
		pr: "0",
		maxWidth: "[530px]",
	},
});

export const resultItemClass = css({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "[5px]",
});

export const resultBarWrapperClass = cva({
	base: {
		position: "relative",
		width: "full",
		aspectRatio: "24 / 43",
		borderRadius: "12",
	},
	variants: {
		classType: {
			D: {
				backgroundColor: "error.0",
			},
			I: {
				backgroundColor: "warning.0",
			},
			G: {
				backgroundColor: "[rgb(244, 247, 251)]",
			},
			S: {
				backgroundColor: "success.0",
			},
		},
	},
});

export const resultBarClass = cva({
	base: {
		position: "absolute",
		left: "0",
		right: "0",
		bottom: "0",
		height: "[var(--percentage)]",
		borderRadius: "12",
		backgroundColor: "shade.0",
	},
	variants: {
		classType: {
			D: {
				backgroundColor: "error.100",
			},
			I: {
				backgroundColor: "warning.100",
			},
			G: {
				backgroundColor: "primary.40",
			},
			S: {
				backgroundColor: "secondary.80",
			},
		},
	},
});

export const resultTextClass = cva({
	base: {
		textAlign: "center",
	},
	variants: {
		classType: {
			D: {
				color: "error.100",
			},
			I: {
				color: "warning.100",
			},
			G: {
				color: "primary.80",
			},
			S: {
				color: "success.100",
			},
		},
	},
});
