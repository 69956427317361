import { css } from "@styled-system/css";

export const overlayPanelClass = css({
	maxHeight: "[80vh]",
	margin: "0",
	overflowY: "auto",
	backgroundColor: "shade.0",
	padding: "var(--padding)!",
	borderRadius: "var(--borderRadius)!",
	xl: {
		maxHeight: "[90vh]",
	},
});
