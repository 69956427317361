import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { TabPanel } from "primereact/tabview";
import { NotesOverviewSkeleton } from "../notes-overview/notes-overview.skeleton";

export const NotesTabbarSkeleton = () => {
	return (
		<TabBar disableMaxParentHeight>
			<TabPanel header="Notizen">
				<NotesOverviewSkeleton />
			</TabPanel>
			<TabPanel header="Erinnerungen">
				<NotesOverviewSkeleton />
			</TabPanel>
		</TabBar>
	);
};
