import { UserCardSkeleton } from "@components/user-card/user-card.skeleton";
import { H2Span } from "@themes/font-tags";
import { wrapperClass } from "./members-tab.styles";

export const MembersTabSkeleton = () => {
	return (
		<div className={wrapperClass}>
			<H2Span>Mitglieder</H2Span>
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
		</div>
	);
};
