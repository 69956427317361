import { cx } from "@styled-system/css";
import { sideMenuContentWrapperClass } from "./side-menu-content.styles";
import { type SideMenuContentProps } from "./side-menu-content.types";

export const SideMenuContent = ({
	showInSideMenu,
	sideMenuOnly,
	className,
	...props
}: SideMenuContentProps) => {
	return (
		<div
			className={cx(
				sideMenuContentWrapperClass({
					showInSideMenu,
					sideMenuOnly,
					isSideMenu: showInSideMenu || sideMenuOnly,
				}),
				className,
			)}
			{...props}
		/>
	);
};
