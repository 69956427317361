import { TabPanel } from "primereact/tabview";
import { Children } from "react";
import { StyledTabView } from "./tab-bar.styles";
import { type TabBarProps } from "./tab-bar.types";

export const TabBar = ({
	sticky = false,
	disableMaxParentHeight,
	hideHeader,
	...props
}: TabBarProps) => {
	const renderedChildren = Array.isArray(props.children)
		? Children.map(props.children, (child: any) => {
				const header = child?.props?.header;
				const newHeader = <span data-label={header}>{header}</span>;
				return <TabPanel {...child?.props} header={newHeader} />;
		  })
		: props.children;

	return (
		<StyledTabView
			sticky={sticky}
			disableMaxParentHeight={disableMaxParentHeight}
			hideHeader={hideHeader}
			{...props}
		>
			{renderedChildren}
		</StyledTabView>
	);
};
