import { z } from "zod";
import type { reminderForm_QueryFragment$key } from "@relay/reminderForm_QueryFragment.graphql";
import { zDiscriminatedUnion } from "./reminder-form.utils";

export type ReminderFormProps = {
	onSuccess?: () => void;
	noteFragmentRef?: reminderForm_QueryFragment$key | null;
};

export type ReminderFormRef = {
	submit: () => Promise<any>;
	validate: () => Promise<boolean>;
};

const reminderSchemaNonRecurring = z.object({
	isRecurring: z.literal(false),
	content: z.string({
		required_error: "Bitte gib einen Text ein.",
	}),
	remindAt: z
		.date({
			required_error: "Bitte gib ein Datum ein.",
		})
		.min(new Date(Date.now() + 60 * 1000), {
			message: "Bitte gib ein Datum in der Zukunft ein.",
		}),
});

const reminderSchemaRecurring = z
	.object({
		isRecurring: z.literal(true),
		content: z.string({
			required_error: "Bitte gib einen Text ein.",
		}),
		remindAt: z
			.date({
				required_error: "Bitte gib ein Datum ein.",
			})
			.min(new Date(Date.now() + 60 * 1000), {
				message: "Bitte gib ein Datum in der Zukunft ein.",
			}),
		intervalType: z.enum(["Daily", "Weekly", "Monthly"]),
		interval: z.string({
			required_error: "Bitte gib eine Intervallgröße ein.",
		}),
	})
	.superRefine(({ intervalType, interval }, ctx) => {
		const numberInterval = parseInt(interval, 10) - 1;

		if (numberInterval < 0) {
			return ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Intervall muss größer als 0 sein.",
				path: ["interval"],
			});
		}

		if (intervalType === "Daily" && numberInterval > 6) {
			return ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Ein Täglicher Intervall darf nicht größer als 7 sein.",
				path: ["interval"],
			});
		}

		if (intervalType === "Weekly" && numberInterval > 3) {
			return ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Ein Wöchentlicher Intervall darf nicht größer als 4 sein.",
				path: ["interval"],
			});
		}

		if (intervalType === "Monthly" && numberInterval > 11) {
			return ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Ein Monatlicher Intervall darf nicht größer als 12 sein.",
				path: ["interval"],
			});
		}
	});

export const reminderSchema = zDiscriminatedUnion("isRecurring", [
	reminderSchemaNonRecurring,
	reminderSchemaRecurring,
]);

export type ReminderFormState = {
	content: string;
	isRecurring: boolean;
	interval?: string;
	intervalType?: "Daily" | "Weekly" | "Monthly";
	remindAt: Date;
};
