import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { type FC } from "react";
import { shade0 } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";
import {
	contentWrapperClass,
	iconCircleClass,
	iconWrapperClass,
	wrapperClass,
} from "./delete-account-modal.styles";
import { type DeleteAccountModalProps } from "./delete-account-modal.types";

export const DeleteAccountModal: FC<DeleteAccountModalProps> = ({
	isVisible,
	onDismiss,
	onNext,
	accountName,
}) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				secondaryButtonStretch={false}
				primaryButtonLabel="Konto löschen"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={onNext}
				primaryButtonStretch={true}
			>
				<div className={wrapperClass}>
					<H1Span>Willst du das Konto “{accountName}” wirklich löschen?</H1Span>
					<P2Span>
						Durch das Löschen deines Kontos gehen dir die folgenden Daten für immer
						verloren und können nicht wiederhergestellt werden:
					</P2Span>
					<div className={contentWrapperClass}>
						<div className={iconWrapperClass}>
							<div className={iconCircleClass}>
								<Icon icon="close" sizeRem={0.75} color={shade0} />
							</div>
							<P2Span>Benutzerdaten</P2Span>
						</div>
						<div className={iconWrapperClass}>
							<div className={iconCircleClass}>
								<Icon icon="close" sizeRem={0.75} color={shade0} />
							</div>
							<P2Span>Lizenzen & Guthaben</P2Span>
						</div>
						<div className={iconWrapperClass}>
							<div className={iconCircleClass}>
								<Icon icon="close" sizeRem={0.75} color={shade0} />
							</div>
							<P2Span>Alle Mitglieder verlieren Zugriff</P2Span>
						</div>
					</div>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
