import { cx } from "@styled-system/css";
import { wrapperClass } from "./side-menu.styles";
import { type SideMenuProps } from "./side-menu.types";

export const SideMenu = ({ children, className, ...props }: SideMenuProps) => {
	return (
		<div className={cx(wrapperClass, className)} {...props}>
			{children}
		</div>
	);
};
