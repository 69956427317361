/**
 * @generated SignedSource<<bc2cfeae27122e45a06b65d58a016013>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TextWithPagesPageDataKind = "ImagePageData" | "TextPageData";
import { FragmentRefs } from "relay-runtime";
export type textWithPagesElementPage_TextWithPagesPageFragment$data = {
  readonly image?: {
    readonly url: string | null;
  };
  readonly kind: TextWithPagesPageDataKind;
  readonly text?: string;
  readonly " $fragmentType": "textWithPagesElementPage_TextWithPagesPageFragment";
};
export type textWithPagesElementPage_TextWithPagesPageFragment$key = {
  readonly " $data"?: textWithPagesElementPage_TextWithPagesPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textWithPagesElementPage_TextWithPagesPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textWithPagesElementPage_TextWithPagesPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "type": "TextWithPagesTextPageData",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "TextWithPagesImagePageData",
      "abstractKey": null
    }
  ],
  "type": "TextWithPagesPageData",
  "abstractKey": "__isTextWithPagesPageData"
};

(node as any).hash = "04e7ba34895158bc80530a4c9575657b";

export default node;
