import * as Sentry from "@sentry/react";
import { gtmTrackAddToCart } from "@thekeytechnology/academies-lib-webapp/analytics";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { InputText, InputTextStatus, PathParams } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { ControlButton } from "@components/control-button";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { RootCard } from "@components/root-card";
import {
	ShoppingCartTemplate,
	ShoppingCartTemplateSkeleton,
} from "@components/shopping-cart-template";
import { shoppingCartProducts_OrderFragment$key } from "@relay/shoppingCartProducts_OrderFragment.graphql";
import { shoppingCartProducts_Query } from "@relay/shoppingCartProducts_Query.graphql";
import {
	SelectionInput,
	shoppingCartProducts_UpdateCartMutation,
	shoppingCartProducts_UpdateCartMutation$data,
} from "@relay/shoppingCartProducts_UpdateCartMutation.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { colorSuccess100Class } from "@themes/color-classes";
import { shade20 } from "@themes/colors";
import { H1Span, H2Span, P1Span, P2Span } from "@themes/font-tags";
import { formatDateTime } from "@utils/date-utils";
import { ORDER_FRAGMENT, QUERY, UPDATE_CART_MUTATION } from "./shopping-cart-products.graphql";
import {
	controlsPriceWrapperClass,
	countControlsClass,
	discountCodeWrapperClass,
	discountPercentWrapperClass,
	discountTitleTagWrapperClass,
	discountTitleWrapperClass,
	discountWrapperClass,
	dividerClass,
	headerWrapperClass,
	itemPriceWrapperClass,
	moreProductsWrapperClass,
	priceLeftGroupWrapperClass,
	priceMonthlyWrapperClass,
	priceRightGroupWrapperClass,
	priceRowsWrapperClass,
	priceWrapperClass,
	productCardWrapperClass,
	productItemClass,
	productsListClass,
	screenWrapperClass,
} from "./shopping-cart-products.styles";
import {
	cloneCartSelection,
	getCartDiscountItems,
	getCartLimitedDiscountItems,
	getProductItems,
} from "./shopping-cart-products.utils";

//TODO: add-translations
export const ShoppingCartProductsScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();

	const {
		node,
		Viewer: { Auth },
	} = useLazyLoadQuery<shoppingCartProducts_Query>(QUERY, {
		orderId: shoppingCartId ?? "",
		skip: !shoppingCartId,
	});

	const order = useFragment<shoppingCartProducts_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const [updateCart] = useMutation<shoppingCartProducts_UpdateCartMutation>(UPDATE_CART_MUTATION);
	const navigate = useNavigate();

	const [discountCodeInput, setDiscountCodeInput] = useState("");
	const [hasDiscountCodeError, setHasDiscountCodeError] = useState(false);

	const cart = order?.cart;
	const products = getProductItems(cart);
	const cartDiscounts = getCartDiscountItems(cart) ?? [];
	const cartLimitedDiscounts = getCartLimitedDiscountItems(cart) ?? [];
	const discounts = [...cartLimitedDiscounts, ...cartDiscounts];
	const moreProducts = cart?.otherAvailableProducts.edges ?? [];

	const hasDiscountCode = cartDiscounts.length > 0;
	const hasDiscounts = discounts.length > 0;
	const hasProducts = products?.length != 0;
	const hasMoreProducts = moreProducts.length > 0;
	const hasProductWithTaxes = products && products.some((product) => !product.product?.isTaxFree);

	const taxes = order?.cart?.totals?.withoutDiscounts
		? order.cart.totals.withoutDiscounts.grossPrice -
		  order.cart.totals.withoutDiscounts.netPrice
		: undefined;

	const handleGoToOffers = () => {
		navigate(Path.progression.path);
	};

	const handleUpdateCart = (selection: SelectionInput, productId: string | undefined) => {
		const amountBefore = getProductItems(order?.cart)?.find(
			(p) => p.product?.id === productId,
		)?.amount;
		updateCart({
			variables: {
				input: {
					orderId: order.id,
					selection,
				},
			},
			onCompleted: (response) => {
				const hasAllDiscountCodes = selection.selectedDiscountCodes.every(
					(code) =>
						response.Billing.updateCart?.order.cart?.items.find(
							(item) => item?.kind === "Discount" && item.code === code,
						) !== undefined,
				);
				setHasDiscountCodeError(!hasAllDiscountCodes);
				trackAddToCart(response, amountBefore ?? 0, productId);
			},
		});
	};

	const trackAddToCart = (
		response: shoppingCartProducts_UpdateCartMutation$data,
		amountBefore: number,
		productId?: string,
	) => {
		try {
			const newAmount =
				response.Billing.updateCart?.order.cart?.items.find(
					(p) => p.product?.id === productId,
				)?.amount ?? 0;
			if (amountBefore < newAmount) {
				const cartAfterUpdateMutation = response.Billing.updateCart?.order.cart;
				const cartTotalBefore = cart?.totals?.includingAllDiscounts?.netPrice || 0;
				const cartTotalAfter =
					cartAfterUpdateMutation?.totals.includingAllDiscounts?.netPrice;
				const productName = cartAfterUpdateMutation?.items.find(
					(x) => x.product?.id === productId,
				)?.product?.title;
				const selectedProducts = cartAfterUpdateMutation?.selection.selectedProducts.map(
					(product) => ({
						base64EncodedProductId: product.product?.id ?? "",
						quantity: product.amount,
					}),
				);
				if (productName && cartTotalAfter && selectedProducts) {
					gtmTrackAddToCart(
						{
							base64EncodedProductId: productId ?? "",
							quantity: newAmount - amountBefore,
							productName: productName,
						},
						selectedProducts,
						cartTotalAfter - cartTotalBefore,
						cartAfterUpdateMutation?.totals.includingAllDiscounts.netPrice || 0,
					);
				}
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	};

	const createIncreaseProductHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.forEach((item) => {
			if (item.productId === productId) {
				item.amount++;
			}
		});
		handleUpdateCart(cart, productId);
	};

	const createDecreaseProductHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.forEach((item) => {
			if (item.productId === productId) {
				item.amount--;
			}
		});
		handleUpdateCart(cart, productId);
	};

	const handleDiscountCodeOnChange = (text?: string) => {
		setDiscountCodeInput(text ?? "");
		setHasDiscountCodeError(false);
	};

	const handleSubmitDiscountCodeOnClick = () => {
		const cart = cloneCartSelection(order?.cart);
		cart.selectedDiscountCodes = [discountCodeInput];
		handleUpdateCart(cart, undefined);
		setDiscountCodeInput("");
	};

	const createAddProductOnClickHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts.push({
			amount: 1,
			productId,
		});
		handleUpdateCart(cart, productId);
	};

	const createRemoveProductOnClickHandler = (productId?: string) => () => {
		if (!productId) return;
		const cart = cloneCartSelection(order?.cart);
		cart.selectedProducts = cart.selectedProducts.filter(
			(item) => item.productId !== productId,
		);
		handleUpdateCart(cart, productId);
	};

	const createDeleteDiscountCodeOnClickHandler = (discountCode: string) => () => {
		const cart = cloneCartSelection(order?.cart);
		cart.selectedDiscountCodes = cart.selectedDiscountCodes.filter(
			(item) => item !== discountCode,
		);
		handleUpdateCart(cart, undefined);
	};

	const handleNextOnClick = () => {
		navigate(Path.shoppingCart.withId(order.id).invoice.path);
	};

	const getRemoveControlDisabled = (amount?: number) => (amount ?? 1) <= 1;

	const discountGross = order.cart?.totals.appliedDiscount?.grossPrice;
	const smallestMonthlyRate = order.cart?.totals.monthlyOptions.length
		? order.cart?.totals.monthlyOptions
				.slice()
				.sort((a, b) => a.monthlyRate.grossPrice - b.monthlyRate.grossPrice)[0].monthlyRate
				.grossPrice
		: undefined;

	const discountCodeInputStatus = hasDiscountCodeError ? InputTextStatus.Error : undefined;
	const discountCodeError = hasDiscountCodeError ? "Rabattcode nicht gültig" : undefined;

	return (
		<ShoppingCartTemplate
			onNext={handleNextOnClick}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<div data-no-selection-menu className={screenWrapperClass}>
				<div className={headerWrapperClass}>
					<H1Span>Warenkorb</H1Span>
					{hasProducts && (
						<P2Span>
							Wähle weitere Produkte aus oder füge einen Rabatt-Code hinzu.
						</P2Span>
					)}
				</div>
				<EmptyPlaceholder
					isVisible={!hasProducts}
					iconName="shoppingBasketSad1"
					title="Hier sieht es aber leer aus ..."
					subtitle="Schau dich doch bei unseren Kursen um und finde das passende Angebot!"
					buttonLabel={"Zu den Kursen"}
					onClick={handleGoToOffers}
				>
					{products?.map(({ product, amount }) => {
						return (
							<div key={product?.id} className={productItemClass}>
								<div className={productCardWrapperClass}>
									<RootCard
										title={product?.title ?? ""}
										imageUrl={
											product?.data?.licenseDefinition?.data.rootInfo?.image
												?.url
										}
									/>
								</div>
								<div className={controlsPriceWrapperClass}>
									<div className={countControlsClass}>
										<ControlButton
											iconName="remove"
											disabled={getRemoveControlDisabled(amount)}
											onClick={createDecreaseProductHandler(product?.id)}
										/>
										<H2Span>{amount}</H2Span>
										<ControlButton
											iconName="add"
											onClick={createIncreaseProductHandler(product?.id)}
										/>
									</div>
									<div className={itemPriceWrapperClass}>
										<Icon
											color={shade20}
											icon="trash"
											onClick={createRemoveProductOnClickHandler(product?.id)}
										/>
										<H2Span>{formatCurrency(product?.netPrice)}</H2Span>
									</div>
								</div>
							</div>
						);
					})}
					{hasDiscounts && <div className={dividerClass} />}
					{discounts?.map((discount) => (
						<div key={discount.code} className={discountWrapperClass}>
							<div className={discountTitleTagWrapperClass}>
								{discount.validUntil && (
									<Label
										label={`Noch bis ${formatDateTime(discount.validUntil)}`}
										severity="info"
									/>
								)}
								<div className={discountTitleWrapperClass}>
									<H2Span>{discount.title ?? "Rabatt"}</H2Span>
									<P1Span>{discount.code ?? "Rabatt Aktion"}</P1Span>
								</div>
							</div>
							<div className={discountPercentWrapperClass}>
								{discount.kind === "Discount" && discount.code && (
									<Icon
										color={shade20}
										icon="trash"
										onClick={createDeleteDiscountCodeOnClickHandler(
											discount.code,
										)}
									/>
								)}
								<H2Span>-{discount.value}%</H2Span>
							</div>
						</div>
					))}
					<div className={dividerClass} />
					<div className={priceWrapperClass}>
						<div className={priceRowsWrapperClass}>
							<div className={priceLeftGroupWrapperClass}>
								<P1Span>Zwischensumme</P1Span>
								{hasProductWithTaxes && (
									<P1Span>
										Mehrwertsteuer{" "}
										{order.cart?.totals?.withoutDiscounts.taxRatePercentage}%
									</P1Span>
								)}
								{discountGross !== undefined && discountGross > 0 && (
									<P1Span>
										Totaler Rabatt -
										{order.cart?.totals.appliedDiscountPercentage}%
									</P1Span>
								)}
								<H2Span>Gesamtpreis</H2Span>
							</div>
							<div className={priceRightGroupWrapperClass}>
								<P1Span>
									{formatCurrency(order.cart?.totals.withoutDiscounts.netPrice)}
								</P1Span>
								{hasProductWithTaxes && <P1Span>{formatCurrency(taxes)}</P1Span>}
								{discountGross !== undefined && discountGross > 0 && (
									<P1Span className={colorSuccess100Class}>
										-&nbsp;
										{formatCurrency(discountGross)}
									</P1Span>
								)}
								<H2Span>
									{formatCurrency(
										order.cart?.totals.includingAllDiscounts.grossPrice,
									)}
								</H2Span>
							</div>
						</div>
						<div className={priceMonthlyWrapperClass}>
							<P1Span>
								Oder monatlich <wbr />
								<strong>ab {formatCurrency(smallestMonthlyRate)}</strong>
							</P1Span>
						</div>
					</div>
					{(!hasDiscountCode || hasMoreProducts) && <div className={dividerClass} />}
					{!hasDiscountCode && (
						<div className={discountCodeWrapperClass}>
							<InputText
								icon="discountBubble1"
								placeholder="Rabattcode"
								value={discountCodeInput}
								onChange={handleDiscountCodeOnChange}
								status={discountCodeInputStatus}
								bottomLabel={discountCodeError}
							/>
							<Button
								disabled={discountCodeInput.length === 0}
								label="Bestätigen"
								colorVersion="tertiary"
								onClick={handleSubmitDiscountCodeOnClick}
							/>
						</div>
					)}
				</EmptyPlaceholder>
				{hasMoreProducts && (
					<div className={moreProductsWrapperClass}>
						<H2Span>Weitere Produkte</H2Span>
						<div className={productsListClass}>
							{moreProducts.map((item, index) => {
								return (
									<RootCard
										key={`${item?.node.id}_${index}`}
										title={item?.node.title ?? ""}
										imageUrl={
											item?.node?.data?.licenseDefinition?.data.rootInfo
												?.image?.url
										}
										onClick={createAddProductOnClickHandler(item?.node.id)}
									/>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartProductsScreen = withSuspense(
	ShoppingCartProductsScreenComponent,
	ShoppingCartTemplateSkeleton,
);
