import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { MouseEvent, useRef } from "react";
import { useFragment } from "react-relay";
import { OverlayPanel } from "@components/overlay-panel";
import { avatarWithProfileMenu_AuthViewerSchemaFragment$key } from "@relay/avatarWithProfileMenu_AuthViewerSchemaFragment.graphql";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./avatar-with-profile-menu.graphql";
import { AvatarWrapper, Wrapper } from "./avatar-with-profile-menu.styles";
import { AvatarWithProfileMenuProps } from "./avatar-with-profile-menu.types";
import { ProfileMenu } from "../profile-menu";

export const AvatarWithProfileMenu = ({
	authViewerSchemaFragmentRef,
}: AvatarWithProfileMenuProps) => {
	const auth = useFragment<avatarWithProfileMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const overlayPanelRef = useRef<OverlayPanel>(null);

	const handleAvatarOnClick = (event: MouseEvent<HTMLElement>) => {
		overlayPanelRef.current?.toggle(event);
	};

	const handleOnNavigated = (event?: MouseEvent<HTMLElement>) => {
		overlayPanelRef.current?.toggle(event);
	};

	const handleOnClose = () => {
		overlayPanelRef.current?.hide();
	};

	if (!auth.currentUser) return null;
	return (
		<Wrapper>
			<OverlayPanel ref={overlayPanelRef}>
				<ProfileMenu onNavigated={handleOnNavigated} onClose={handleOnClose} />
			</OverlayPanel>

			<AvatarWrapper onClick={handleAvatarOnClick}>
				<UserAvatar userFragmentRef={auth.currentUser.user} />
			</AvatarWrapper>
		</Wrapper>
	);
};
