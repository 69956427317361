import { css } from "@styled-system/css";

export const groupWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const inputSwitchWrapperClass = css({
	display: "flex",
	width: "full",
	alignItems: "center",
	justifyContent: "space-between",
});

export const wrapperClass = css({
	gap: "24",
	display: "flex",
	flexDirection: "column",
	zIndex: "1000",
	md: {
		width: "[18.5rem]",
	},
});

export const buttonWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	md: {
		display: "none",
	},
});

export const filterHeadlineClass = css({
	md: {
		display: "none",
	},
});
