import { css } from "@styled-system/css";
import { flex, vstack } from "@styled-system/patterns";

export const wrapperClass = flex({
	direction: "column",
	gap: "20",
	width: "full",
	marginX: "auto",
	padding: "16",
	paddingBottom: "32",
	backgroundColor: "secondary.20",
	borderTopRadius: "24",
	lg: {
		width: "[calc(100% - 4rem)]",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
});

export const messageContainerClass = vstack({
	textAlign: "center",
	md: {
		alignItems: "start",
		textAlign: "left",
	},
});

export const iconAndBadgeWrapperClass = css({
	padding: "4",
});

export const contentWrapperClass = flex({
	flexDirection: "column",
	alignItems: "center",
	gap: "20",
	md: {
		flexDirection: "row",
	},
});

export const badgeWrapperClass = css({
	position: "absolute",
	top: "0",
	right: "[-10%]",
	transform: "translateY(25%)",
});
