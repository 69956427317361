import styled from "styled-components";

export const InlineWrapper = styled.div`
	flex: 1 1 0%;
	min-width: min(20rem, 100vw);
	padding-inline: 24px;

	@media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		box-sizing: border-box;
	}
`;
