import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { useFragment } from "react-relay";
import { basketTimeline_OrderFragment$key } from "@relay/basketTimeline_OrderFragment.graphql";
import { H3Span, P2Span } from "@themes/font-tags";
import { ORDER_FRAGMENT } from "./basket-timeline.graphql";
import { iconWrapperClass, itemWrapperClass, textWrapperClass } from "./basket-timeline.styles";
import { BasketEvent, TimelineProps } from "./basket-timeline.types";
import { Timeline } from "../timeline";

export const BasketTimeline = ({
	currentStep,
	orientation = "horizontal",
	orderFragmentRef,
}: TimelineProps) => {
	const order = useFragment<basketTimeline_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	const { t } = useTkaTranslation("basketScreen");

	const customerTypeTitle =
		order.customerDetails?.data?.kind === "Business"
			? "Geschäftliche Bestellung"
			: "Private Bestelllung";
	const rateCount = order.selectedPaymentMethod?.chosenOption?.rateCount;

	const paymentMethodTitle =
		order.selectedPaymentMethod?.kind === "Monthly"
			? `In ${rateCount ?? 48} monatlichen Zahlungen`
			: "Einmal Zahlung";

	const events: BasketEvent[] = [
		{
			label: t("basket_screen.basketTimelineBasketLabel"),
			icon: "shoppingBasketThin",
			subtitle:
				currentStep > 1
					? formatCurrency(order.cart?.totals.includingAllDiscounts.grossPrice)
					: undefined,
		},
		{
			label: t("basket_screen.basketTimelineContractLabel"),
			icon: "paragraphTwoColumn",
			subtitle: currentStep > 2 ? customerTypeTitle : undefined,
		},
		{
			label: t("basket_screen.basketTimelinePaymentLabel"),
			icon: "creditCardMastercard1",
			subtitle: currentStep > 3 ? paymentMethodTitle : undefined,
		},
		{
			label: t("basket_screen.basketTimelineOverviewLabel"),
			icon: "shoppingBasketCheck1",
		},
	];

	const renderContent = (event: BasketEvent, index: number) => {
		const finished = index < currentStep - 1;
		const icon = finished ? "checkCircle" : event.icon;
		return (
			<div className={itemWrapperClass}>
				<div className={iconWrapperClass({ finished })}>
					<Icon icon={icon} sizeRem={1.5} />
				</div>
				<div className={textWrapperClass}>
					<H3Span>{event.label}</H3Span>
					{event.subtitle && <P2Span>{event.subtitle}</P2Span>}
				</div>
			</div>
		);
	};

	return (
		<Timeline
			events={events}
			orientation={orientation}
			currentStep={currentStep}
			progressBarOffestRem={0.875}
			hideContent={orientation === "horizontal"}
			content={renderContent}
		/>
	);
};
