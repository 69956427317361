import { EpicIcons, epicPrimary100, Icon } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { ReactComponent as IHKLogoSvg } from "@assets/ihk-logo.svg";
import { CircularProgressbar } from "@components/circular-progressbar";
import { colorShade80Class } from "@themes/color-classes";
import { H3Span, P3Span } from "@themes/font-tags";
import { OfferCategory, TITLE_CUTOFF_LENGTH } from "./offer-card.consts";
import { PUBLISHED_LEARNABLE_FRAGMENT } from "./offer-card.graphql";
import {
	descriptionClass,
	dividerClass,
	footerClass,
	footerStatusTextClass,
	headerClass,
	headerContentClass,
	imageClass,
	imageDiplomaWrapperClass,
	imageOverlayClass,
	imageWrapperClass,
	progressbarWrapperClass,
	progressOverlayWrapperClass,
	progressWrapperClass,
	tagTitleClass,
	tagWrapperClass,
	textWrapperClass,
	titleClass,
	wrapperClass,
} from "./offer-card.styles";
import { OfferCardProps } from "./offer-card.types";

export const OfferCard = ({
	showDescription = false,
	onClick,
	publishedLearnableFragmentRef,
}: OfferCardProps) => {
	const publishedLearnable = useFragment(
		PUBLISHED_LEARNABLE_FRAGMENT,
		publishedLearnableFragmentRef ?? null,
	);

	if (!publishedLearnable) return null;

	const rootNode = publishedLearnable.root;
	const structureDefinition = rootNode?.structureDefinition;

	const imageUrl = rootNode?.image?.url;
	const title = rootNode?.structureDefinition.title ?? "";
	const shortDescription = rootNode?.shortDescription;
	const progress = structureDefinition?.progressPercentage ?? 0;
	const tags = structureDefinition?.extension?.tags ?? [];
	const tag = tags.find((t) => t.data.isTopic);
	const category = tag?.data.name as OfferCategory;

	const isInDemo = structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const isLocked =
		structureDefinition?.viewerTreeState?.headContentId ===
			structureDefinition?.extension?.unlockInfo?.firstContentId && isInDemo;

	const handleOnClick = () => {
		publishedLearnable?.root?.id && onClick?.(publishedLearnable.root.id);
	};

	return (
		<button type="button" onClick={handleOnClick} className={wrapperClass}>
			<div className={headerClass}>
				<div className={imageWrapperClass}>
					{imageUrl && (
						<>
							<img src={imageUrl} alt="" className={imageClass} />
							<div
								className={imageOverlayClass({
									category,
								})}
							/>
							{structureDefinition?.extension?.reducedData?.isIHK && (
								<div className={imageDiplomaWrapperClass}>
									<IHKLogoSvg />
								</div>
							)}
						</>
					)}
				</div>
				<div className={headerContentClass}>
					{tag && (
						<div
							className={tagWrapperClass({
								category,
							})}
						>
							<span
								className={tagTitleClass({
									category,
								})}
							>
								{tag.data.name}
							</span>
						</div>
					)}
					<div className={textWrapperClass}>
						<H3Span className={titleClass}>
							{title.substring(0, TITLE_CUTOFF_LENGTH)}
							{title.length > TITLE_CUTOFF_LENGTH ? "..." : ""}
						</H3Span>
						{showDescription && shortDescription && (
							<P3Span className={descriptionClass}>{shortDescription}</P3Span>
						)}
					</div>
				</div>
			</div>
			<hr className={dividerClass} />
			<div className={footerClass}>
				<div>
					{progress === 0 && (
						<span className={footerStatusTextClass}>Kostenlos testen</span>
					)}
					{progress > 0 && <span className={footerStatusTextClass}>Fortsetzen</span>}
				</div>
				<div className={progressWrapperClass}>
					{progress > 0 && <P3Span className={colorShade80Class}>{progress}%</P3Span>}
					{isLocked && (
						<P3Span className={colorShade80Class}>|&nbsp;&nbsp;Lizenz benötigt</P3Span>
					)}
					{progress > 0 && (
						<div className={progressbarWrapperClass}>
							{isLocked && (
								<div className={progressOverlayWrapperClass}>
									<Icon
										icon={EpicIcons.LOCK}
										sizeRem={0.625}
										color={epicPrimary100}
									/>
								</div>
							)}
							<CircularProgressbar
								value={progress}
								strokeWidth={20}
								width="1rem"
								height="1rem"
							/>
						</div>
					)}
				</div>
			</div>
		</button>
	);
};
