import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { EpicIcons } from "@thekeytechnology/epic-ui";
import { motion } from "framer-motion";
import { Carousel } from "primereact/carousel";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyMedalSvg } from "@assets/key-medal-points.svg";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { OfferCard } from "@components/offer-card";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { dashboard_Query } from "@relay/dashboard_Query.graphql";
import { dashboard_QueryFragment$key } from "@relay/dashboard_QueryFragment.graphql";
import { Path } from "@router/paths";
import { colorShade0Class, colorShade80Class, colorWarning100Class } from "@themes/color-classes";
import { brandStrong120 } from "@themes/colors";
import { H1Span, H2Span, H3Span, L1Span, P2Span, P3Span } from "@themes/font-tags";
import {
	WEEKDAY_CIRCLE_DIAMETER,
	WEEKDAY_CIRCLE_NUMBER_OF_DASHES,
	WEEKDAY_CIRCLE_RADIUS,
	WEEKDAY_CIRCLE_STROKE_WIDTH,
	WEEKDAYS,
} from "./dashboard.consts";
import { QUERY, QUERY_FRAGMENT } from "./dashboard.graphql";
import {
	carouselContainerClass,
	carouselContentClass,
	carouselIndicatorButtonClass,
	carouselIndicatorClass,
	carouselIndicatorContainerClass,
	carouselItemClass,
	dayClass,
	daysWrapperClass,
	dayTextClass,
	greenGradientClass,
	gridClass,
	gridItemClass,
	headerClass,
	headerPointsClass,
	purpleGradientClass,
	sectionClass,
	sectionHeaderClass,
	userDataClass,
	userDataWrapperClass,
	userEditButtonClass,
	userLevelAndButtonWrapperClass,
	userLevelProgressBarClass,
	userLevelProgressClass,
	userLevelProgressWrapperClass,
	userLevelTitleClass,
	userLevelWrapperClass,
} from "./dashboard.styles";
import { getCircleDasharray } from "./dashboard.utils";

export const DashboardComponent = () => {
	const navigate = useNavigate();
	const { isMediumUp, isXLargeUp } = useWindowSize();

	const query = useLazyLoadQuery<dashboard_Query>(
		QUERY,
		{ kinds: [], tagIds: [] },
		{ fetchPolicy: "store-and-network" },
	);

	const data = useFragment<dashboard_QueryFragment$key>(QUERY_FRAGMENT, query);
	const learnables =
		data?.Learnable.PublishedLearnables.edges?.map((edge) => edge?.node).filter(Boolean) ?? [];

	const academiesUserExtension = query?.Viewer?.Auth?.currentUser?.user?.extensions?.find(
		(e) => e.kind === "Academies",
	);
	const tkaUserExtension = query?.Viewer?.Auth?.currentUser?.user?.extensions?.find(
		(e) => e.kind === "Tka",
	);

	const userLevelProgress = academiesUserExtension?.progressInPercent ?? 0;

	const handleCardOnClick = (learnableId: string) => {
		navigate(Path.root.withId(learnableId).overview.path);
	};

	return (
		<ScreenWithNavbarLayout>
			<div className={headerClass}>
				<H1Span>Mein Bereich</H1Span>
				<div className={headerPointsClass}>
					<H3Span className={colorWarning100Class}>
						{academiesUserExtension?.sumGamificationPoints}
					</H3Span>
					<KeyMedalSvg />
				</div>
			</div>
			<div className={purpleGradientClass}>
				<div className={userDataWrapperClass}>
					{query && query?.Viewer?.Auth?.currentUser && (
						<UserAvatar
							userFragmentRef={query.Viewer.Auth.currentUser.user}
							sizeInRem={5}
						/>
					)}
					<div className={userDataClass}>
						<P3Span>Guten Abend</P3Span>
						<H1Span>
							{academiesUserExtension?.firstName} {academiesUserExtension?.lastName}
						</H1Span>
						<P2Span>
							Schön, dass Du wieder da bist. Schau Dich doch mal bei
							<br />
							den Kursen um. Viel Spaß mit der thekey.academy
						</P2Span>
					</div>
				</div>
				<div className={userLevelAndButtonWrapperClass}>
					<div className={userEditButtonClass}>
						<Icon icon={EpicIcons.PENCIL} sizeRem={0.75} />
						<L1Span className={colorShade80Class}>Edit</L1Span>
					</div>
					<div className={userLevelWrapperClass}>
						<div className={userLevelTitleClass}>
							<H3Span className={colorShade0Class}>
								{tkaUserExtension?.levelDefinition?.title}
							</H3Span>
							<P3Span className={colorShade0Class}>Level</P3Span>
						</div>
						<div className={userLevelProgressWrapperClass}>
							<div className={userLevelProgressClass}>
								<motion.div
									animate={{
										width: userLevelProgress,
									}}
									transition={{
										type: "spring",
										stiffness: 210,
										damping: 16,
									}}
									className={userLevelProgressBarClass}
								/>
							</div>
							<P3Span className={colorShade0Class}>{userLevelProgress}%</P3Span>
						</div>
					</div>
				</div>
			</div>
			<div className={greenGradientClass}>
				<div className={userDataClass}>
					<H2Span className={colorShade0Class}>
						Du hast deinen ersten zwei Tage gelernt!
					</H2Span>
					<P2Span className={colorShade0Class}>
						Noch 5 Tage bis zur Wochen-Auszeichnung
					</P2Span>
				</div>
				<div className={daysWrapperClass}>
					{WEEKDAYS.map((weekday, idx) => {
						// TODO: real implementation
						const isCompleted = idx === 0 || idx === 1;

						return (
							<div key={weekday} className={dayClass}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.1"
									width={WEEKDAY_CIRCLE_DIAMETER}
									height={WEEKDAY_CIRCLE_DIAMETER}
									viewBox={`${WEEKDAY_CIRCLE_DIAMETER}, ${WEEKDAY_CIRCLE_DIAMETER}`}
									style={{
										borderRadius: "50%",
									}}
								>
									<circle
										width={WEEKDAY_CIRCLE_DIAMETER}
										height={WEEKDAY_CIRCLE_DIAMETER}
										cx={WEEKDAY_CIRCLE_RADIUS}
										cy={WEEKDAY_CIRCLE_RADIUS}
										r={WEEKDAY_CIRCLE_RADIUS}
										fill="none"
										stroke="white"
										strokeDasharray={
											isCompleted
												? "0"
												: getCircleDasharray(
														WEEKDAY_CIRCLE_RADIUS,
														WEEKDAY_CIRCLE_NUMBER_OF_DASHES,
												  )
										}
										strokeDashoffset="20"
										strokeWidth={
											isCompleted ? "5px" : `${WEEKDAY_CIRCLE_STROKE_WIDTH}px`
										}
									/>
								</svg>
								<H2Span className={dayTextClass}>{weekday}</H2Span>
							</div>
						);
					})}
				</div>
			</div>
			<section className={sectionClass}>
				<div className={sectionHeaderClass}>
					<H2Span>Fortsetzen</H2Span>
				</div>
				<EmptyPlaceholder
					isVisible={learnables.length === 0}
					title="Du hast noch keine Kurse gestartet"
					subtitle="Du hast aktuell keine Kurse in deinem Konto gestartet."
				>
					<Carousel
						value={learnables}
						page={0}
						numVisible={isMediumUp ? 3 : isXLargeUp ? 2 : 1}
						numScroll={1}
						showNavigators={false}
						pt={{
							content: {
								className: carouselContentClass,
							},
							container: {
								className: carouselContainerClass,
							},
							itemsContainer: {
								className: carouselContainerClass,
							},
							item: {
								className: carouselItemClass,
							},
							indicators: {
								className: carouselIndicatorContainerClass,
							},
							indicator: {
								className: carouselIndicatorClass,
							},
							indicatorButton: {
								className: carouselIndicatorButtonClass,
							},
						}}
						itemTemplate={(learnable: typeof learnables[number]) => (
							<div key={learnable.id} className={gridItemClass}>
								{learnable.kind === "Course" && (
									<OfferCard
										onClick={handleCardOnClick}
										publishedLearnableFragmentRef={learnable}
									/>
								)}
							</div>
						)}
					/>
				</EmptyPlaceholder>
			</section>
			<section className={sectionClass}>
				<div className={sectionHeaderClass}>
					<H2Span>Deine Weiterentwicklung</H2Span>
					<Button
						label="Deine Angebote"
						colorVersion="textlink"
						textLinkColor={brandStrong120}
					/>
				</div>
				<EmptyPlaceholder
					isVisible={learnables.length === 0}
					title="Keine Weiterbildungen gefunden"
					subtitle="Du hast aktuell keine Weiterbildungen in deinem Konto."
					buttonLabel="Deine Angebote"
					onClick={() => {
						navigate(Path.progression.path);
					}}
				>
					<div className={gridClass}>
						{learnables.map((learnable) => (
							<div key={learnable.id} className={gridItemClass}>
								{learnable.kind === "Course" && (
									<OfferCard
										onClick={handleCardOnClick}
										publishedLearnableFragmentRef={learnable}
									/>
								)}
							</div>
						))}
					</div>
				</EmptyPlaceholder>
			</section>
		</ScreenWithNavbarLayout>
	);
};

export const DashboardScreen = withSuspense(DashboardComponent, null);
