import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const pageClass = css({
	display: "flex",
	md: { flexBasis: "50%" },
	mdDown: { flexBasis: "100%" },
	py: "16",
});

export const imageClass = flex({
	borderRadius: "8",
	alignItems: "center",
});
