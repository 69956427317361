import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	max-w-[24rem]
	items-center
	p-16
	gap-16
	bg-error-20
	rounded-12
`;
