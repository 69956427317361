import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import React from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { TEXT_WITH_PAGES_PAGE_FRAGMENT } from "@components/text-with-pages-element-page/text-with-pages-element-page.graphql";
import {
	imageClass,
	pageClass,
} from "@components/text-with-pages-element-page/text-with-pages-element-page.styles";
import { TextWithPagesElementPageProps } from "@components/text-with-pages-element-page/text-with-pages-element-page.types";
import { textWithPagesElementPage_TextWithPagesPageFragment$key } from "@relay/textWithPagesElementPage_TextWithPagesPageFragment.graphql";

export const TextWithPagesElementPage = ({ pageFragmentRef }: TextWithPagesElementPageProps) => {
	const pageData = useFragment<textWithPagesElementPage_TextWithPagesPageFragment$key>(
		TEXT_WITH_PAGES_PAGE_FRAGMENT,
		pageFragmentRef,
	);

	const renderPage = () =>
		match(pageData.kind)
			.with("TextPageData", () => {
				if (!pageData.text) return null;
				return <div>{parseHtml(pageData.text)}</div>;
			})
			.with("ImagePageData", () => {
				if (!pageData.image || !pageData.image.url) return null;
				return <img className={imageClass} src={pageData.image.url} alt="" />;
			})
			.exhaustive();

	return <div className={pageClass}>{renderPage()}</div>;
};
