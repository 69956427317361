import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Skeleton } from "@thekeytechnology/epic-ui";
import tw from "twin.macro";
import { CardWrapper, Content, TagsRow, Wrapper } from "./root-card.styles";

export const RootCardSkeleton = ({ maxWidth }: { maxWidth?: number }) => {
	const imageWidth = "4.375rem";

	return (
		<CardWrapper maxWidth={maxWidth}>
			<Card fillParent bodyPaddingInRem={0.75} hasOverflowHidden>
				<Wrapper>
					<AbsoluteWrapper>
						<Skeleton width={imageWidth} height="100%" />
					</AbsoluteWrapper>
					<Content>
						<TagsRow>
							<Skeleton width="5rem" height="1rem" />
							<Skeleton width="5rem" height="1rem" />
						</TagsRow>
						<Skeleton width="80%" />
					</Content>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};

const AbsoluteWrapper = tw.div`absolute h-full`;
