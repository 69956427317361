import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { type FC } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { LicensesGroupCard } from "@components/licenses-group-card";
import { licensesTab_MyLicensesQuery } from "@relay/licensesTab_MyLicensesQuery.graphql";
import { Path } from "@router/paths";
import { QUERY } from "./licenses-tab.graphql";
import { LicensesTabSkeleton } from "./licenses-tab.skeleton";
import { listWrapperClass } from "./licenses-tab.styles";
import { LicenseTabProps } from "./licenses-tab.types";

const LicensesTabComponent: FC<LicenseTabProps> = () => {
	const data = useLazyLoadQuery<licensesTab_MyLicensesQuery>(
		QUERY,
		{
			kinds: ["Course"],
		},
		{
			fetchPolicy: "store-and-network",
		},
	);
	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(Path.progression.path);
	};

	return (
		<div className={listWrapperClass}>
			<EmptyPlaceholder
				title="Keine Lizenzen gefunden"
				subtitle="Schau dich gerne in unserem Angebotskatalog um, um die für dich passenden Angebote zu finden."
				iconName="loginKey"
				buttonLabel="Zu den Angeboten"
				onClick={handleOnClick}
			>
				{data?.LicenseGrouping?.MyLicenses.edges?.map((edge) => (
					<LicensesGroupCard
						key={edge?.node.id}
						licensesGroupCardFragmentRef={edge?.node}
					/>
				))}
			</EmptyPlaceholder>
		</div>
	);
};

export const LicensesTab = withSuspense(LicensesTabComponent, LicensesTabSkeleton);
