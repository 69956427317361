import { graphql } from "react-relay";

export const QUERY = graphql`
	query shoppingCartInvoice_Query($orderId: ID!, $skip: Boolean!, $isNotLoggedIn: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartInvoice_OrderFragment
			}
		}
		Viewer {
			Auth {
				...inlineAuthGuard_AuthViewerSchemaFragment
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
		AccountBaseData @skip(if: $isNotLoggedIn) {
			AccountBaseData {
				__typename
				...businessClientForm_BaseDataFragment
				...privateClientForm_BaseDataFragment
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartInvoice_OrderFragment on Order {
		customerDetails {
			street
			houseNumber
			city
			postalCode
			country
			phoneNumber
			salutation
			firstName
			lastName
			invoiceEmail
			country
			data {
				kind
				... on Billing_PrivateCustomerDetails {
					title
					dateOfBirth
				}
				... on Billing_BusinessCustomerDetails {
					company
					companyDetails
					companyType
				}
			}
		}
		...shoppingCartTemplate_OrderFragment
	}
`;

export const UPDATE_PRIVATE_BILLING_DETAILS_MUTATION = graphql`
	mutation shoppingCartInvoice_UpdatePrivateBillingDetailsMutation(
		$input: UpdatePrivateCustomerDetailsInput!
	) {
		Billing {
			updatePrivateCustomerDetails(input: $input) {
				order {
					...shoppingCartInvoice_OrderFragment
				}
			}
		}
	}
`;

export const UPDATE_BUSINESS_BILLING_DETAILS_MUTATION = graphql`
	mutation shoppingCartInvoice_UpdateBusinessBillingDetailsMutation(
		$input: UpdateBusinessCustomerDetailsInput!
	) {
		Billing {
			updateBusinessCustomerDetails(input: $input) {
				order {
					...shoppingCartInvoice_OrderFragment
				}
			}
		}
	}
`;
