import { css } from "@styled-system/css";

export const wrapperClass = css({
	width: "full",
	maxWidth: "[57rem]",
	marginX: "auto",
	p: "24",
	md: {
		p: "40",
	},
	xl: {
		maxWidth: "[75rem]",
	},
});
