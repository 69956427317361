import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = css({
	position: "relative",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "32",
	boxShadow: "card",
	width: "full",
	borderRadius: "12",
	_before: {
		content: "''",
		position: "absolute",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		zIndex: "1",
		borderRadius: "12",
		background: "[radial-gradient(150% 417.55% at 5.45% 43.49%, #557ED6 0%, #5327E6 60%)]",
	},
});

export const contentClass = flex({
	flexDirection: "column",
	justifyContent: "space-between",
	width: "full",
	zIndex: "10",
	md: {
		flexDirection: "row",
		alignItems: "center",
	},
});

export const groupDataWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "16",
});

export const groupDataContentClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "4",
});

export const statisticsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "4",
	ml: "[6rem]",
	md: {
		alignItems: "flex-end",
	},
});

export const statisticsLabelClass = css({
	textStyle: "p3",
	color: "shade.0",
});

export const statisticsTitleClass = css({
	textStyle: "h1",
	color: "shade.0",
});
