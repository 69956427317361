/**
 * @generated SignedSource<<b404739beb00725aef203c86be05fba6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type SelectedPaymentMethodKind = "Monthly" | "OneTime";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartPaymentMethod_OrderFragment$data = {
  readonly allowedPaymentMethods: {
    readonly paymentMethods: ReadonlyArray<PaymentMethodType>;
  };
  readonly cart: {
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
      };
      readonly monthlyOptions: ReadonlyArray<{
        readonly downPayment: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly financedAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly monthlyRate: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly rateCount: number;
        readonly totalAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
      }>;
    };
  } | null;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly rateCount: number;
    };
    readonly kind: SelectedPaymentMethodKind;
    readonly paymentMethodType: PaymentMethodType;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartPaymentMethod_OrderFragment";
};
export type shoppingCartPaymentMethod_OrderFragment$key = {
  readonly " $data"?: shoppingCartPaymentMethod_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartPaymentMethod_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taxRatePercentage",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rateCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartPaymentMethod_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Totals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "downPayment",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "financedAmount",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "totalAmount",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AllowedPaymentMethods",
      "kind": "LinkedField",
      "name": "allowedPaymentMethods",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethods",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlyPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "81dd56a9803dafc86a10f2207516e8a7";

export default node;
