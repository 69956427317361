import { css } from "@styled-system/css";

export const buttonWrapperClass = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	md: {
		display: "none",
	},
});

export const filterModalWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	zIndex: "1000",
	md: {
		width: "[18.5rem]",
	},
});

export const filterHeadlineClass = css({
	textStyle: "h1",
	md: {
		display: "none",
	},
});
