import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Skeleton } from "@thekeytechnology/academies-lib-webapp/components/skeleton";
import { shade40 } from "@themes/colors";
import { nameWrapperClass, wrapperClass } from "./user-card.styles";

export const UserCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={0.75} borderColor={shade40}>
			<div className={wrapperClass}>
				<Skeleton width="2.5rem" height="2.5rem" borderRadius="2.5rem" />
				<div className={nameWrapperClass}>
					<Skeleton width="4rem" />
					<Skeleton width="8rem" className="mt-8" />
				</div>
			</div>
		</Card>
	);
};
