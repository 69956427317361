/**
 * @generated SignedSource<<0af2d57feff9ca9052d615c65146c931>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type upsertReminderModal_ReminderNoteFragmentRefetch$variables = {
  id: string;
};
export type upsertReminderModal_ReminderNoteFragmentRefetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"upsertReminderModal_ReminderNoteFragment">;
  } | null;
};
export type upsertReminderModal_ReminderNoteFragmentRefetch = {
  response: upsertReminderModal_ReminderNoteFragmentRefetch$data;
  variables: upsertReminderModal_ReminderNoteFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "upsertReminderModal_ReminderNoteFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "upsertReminderModal_ReminderNoteFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "upsertReminderModal_ReminderNoteFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "reminder",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remindAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCompleted",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "interval",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "DailyInterval",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "WeeklyInterval",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "MonthlyInterval",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "RecurringNoteReminder",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Note",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c109dd3297cb03e82d2fd75221a3dbf6",
    "id": null,
    "metadata": {},
    "name": "upsertReminderModal_ReminderNoteFragmentRefetch",
    "operationKind": "query",
    "text": "query upsertReminderModal_ReminderNoteFragmentRefetch(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...upsertReminderModal_ReminderNoteFragment\n    id\n  }\n}\n\nfragment reminderForm_QueryFragment on Note {\n  id\n  content\n  reminder {\n    __typename\n    kind\n    remindAt\n    isCompleted\n    ... on RecurringNoteReminder {\n      kind\n      interval {\n        __typename\n        kind\n        ... on DailyInterval {\n          value\n        }\n        ... on WeeklyInterval {\n          value\n        }\n        ... on MonthlyInterval {\n          value\n        }\n      }\n    }\n    ... on NonRecurringNoteReminder {\n      kind\n    }\n  }\n}\n\nfragment upsertReminderModal_ReminderNoteFragment on Note {\n  id\n  ...reminderForm_QueryFragment\n}\n"
  }
};
})();

(node as any).hash = "07b8b632212d89369d12dd25b368d0c8";

export default node;
