import { flex } from "@styled-system/patterns";

export const centerClass = flex({
	direction: "column",
	alignItems: "center",
	flex: "1",
});

export const minWidthClass = flex({
	direction: "column",
	alignItems: "center",
	minWidth: "[min(20rem,100%)]",
	md: {
		minWidth: "[20rem]",
	},
});
