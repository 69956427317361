import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const screenWrapperClass = flex({
	direction: "column",
	height: "full",
	gap: "32",
	p: "24",
	overflowY: "auto",
	md: {
		p: "40",
	},
});

export const licenseGroupClass = flex({
	flexDirection: "column",
	gap: "16",
	md: {
		flexDirection: "row",
		gap: "32",
		justifyContent: "space-between",
		alignItems: "center",
	},
});

export const licenseWrapperClass = css({
	flex: "1",
	md: {
		width: "24",
	},
});

export const licenseCountClass = flex({
	minWidth: "8",
	whiteSpace: "nowrap",
	justifyContent: "end",
});

export const dividerClass = css({
	minHeight: "[1px]",
	alignSelf: "stretch",
	bg: "shade.10",
});

export const discountWrapperClass = flex({
	flexDirection: "column",
	md: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "16",
	},
});

export const discountTitleTagWrapperClass = flex({
	flexDirection: "column",
	gap: "8",
});

export const discountTitleWrapperClass = flex({
	flexDirection: "row",
	gap: "8",
	alignItems: "center",
});

export const discountPercentWrapperClass = css({
	alignSelf: "end",
});

export const priceWrapperClass = flex({
	flexDirection: "column",
	justifyContent: "space-between",
	gap: "16",
	md: {
		flexDirection: "row",
		gap: "32",
	},
});

export const priceRowClass = flex({
	flexDirection: "column",
	gap: "8",
});

export const discountPriceClass = flex({
	flexDirection: "row",
	gap: "8",
	whiteSpace: "nowrap",
	alignSelf: "end",
	md: {
		alignSelf: "start",
	},
});

export const paymentWrapperClass = flex({
	flexDirection: "column",
	gap: "16",
});

export const panelContentWrapperClass = css({
	bg: "shade.5",
	borderRadius: "8",
	px: "16",
	py: "24",
});

export const sofortLogoWrapperClass = css({
	maxWidth: "24",
});
