import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Dropdown } from "@thekeytechnology/epic-ui";
import { type FC, useContext, useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import { AccountContext } from "@screens/account";
import { QUERY_FRAGMENT } from "./filter-modal.graphql";
import {
	buttonWrapperClass,
	filterHeadlineClass,
	filterModalWrapperClass,
} from "./filter-modal.styles";
import { FilterModalProps } from "./filter-modal.types";
import { useMembersFilterContext } from "../members-filter/members-filter.context";

export const FilterModal: FC<FilterModalProps> = ({ overlayRef, queryFragmentRef }) => {
	const [data] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const { selectedStatus, setSelectedStatus } = useMembersFilterContext();

	const { addGroupsConnectionId } = useContext(AccountContext);

	const groupsConnectionId =
		data?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers.__id;

	useEffect(() => {
		groupsConnectionId && addGroupsConnectionId(groupsConnectionId);
	}, [groupsConnectionId]);

	const handleOnClose = () => {
		overlayRef?.current?.hide();
	};

	const handleOnStatusChange = (value: string) => {
		if (value === "all") {
			setSelectedStatus("");
			return;
		}
		setSelectedStatus(value);
	};

	return (
		<div className={filterModalWrapperClass}>
			<span className={filterHeadlineClass}>Benutzer anzeigen</span>
			<Dropdown
				label="Status"
				options={[
					{ label: "Alle", value: "all" },
					{ label: "Zugesagt", value: "confirmed" },
					{ label: "Unbestätigt", value: "unconfirmed" },
				]}
				id="status"
				value={selectedStatus || "all"}
				onChange={handleOnStatusChange}
			/>
			<div className={buttonWrapperClass}>
				<Button onClick={handleOnClose} colorVersion={"tertiary"} label="Schließen" />
			</div>
		</div>
	);
};
